import React from 'react'
import {Box, Button, Typography} from '@mui/material'
import { POPPINS } from '../../../constants/fontConstantsPoppins';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


function SaveButton({type,handleSave,isLoading,imgUrl}) {


  return (
   <Box sx={{
    display:"flex",
    justifyContent: "flex-end",
    padding:"20px 35px"
  }}>
    <Button
        
        type={type?type:"button"}
        onClick={type === "button"  ? handleSave : undefined}
        variant="contained"
        disabled={!imgUrl? true : false}    
        sx={{
        backgroundColor: "#03FF84",
        cursor:"pointer",
        boxShadow:"none",
        opacity:isLoading || !imgUrl ? "0.5":"1",
        "&:hover": {
         backgroundColor: "#03FF84",
         boxShadow:"none",
        },
        "&:disabled": { // Custom styles for disabled state
          backgroundColor: "#03FF84",
          
        }   
    }}>
     {isLoading?(  <Stack sx={{ color: '#fff' }} spacing={2} direction="row">
      <CircularProgress size={20} color="inherit" />
    </Stack>):( <Typography 
        fontFamily={POPPINS}
        fontSize={13}
        fontWeight={600}
        color={"#000"}
        textTransform={"none"}> 
              Save 
      </Typography>)}
  </Button>
   </Box>
  )
}

export default SaveButton