import axiosInstance from '../../../services/axiosservice';
 
const baseURL = process.env.REACT_APP_API_BASE_URL
 

const getEditData = async (id)=>{
    console.log(id)
    try {
        let getData = await axiosInstance.get(`${baseURL}/api/getQuestionDetailsForAdmin/${id}`)
        console.log(getData)
         
        return getData

    } catch (err) {
        console.log(err)
    }
}
 
const getqtype = async () => {
  try {
    let getqtype = await axiosInstance.get(
      `${baseURL}/api/getAllQuestionTypes`,
      {}
    );

    return getqtype;
  } catch (err) {
    console.log(err);
  }
};

const subjectlist = async () => {
  try {
    let subjectlist = await axiosInstance.get(
      `${baseURL}/api/getAllQuestionSubjects`,
      {}
    );

    return subjectlist;
  } catch (err) {
    console.log(err);
  }
};

const addsubjectlist = async (data) => {
  try {
    let addsubjectlist = await axiosInstance.post(
      `${baseURL}/api/addSubject`,
      data
    );

    return addsubjectlist;
  } catch (err) {
    console.log(err);
  }
};

const topiclist = async () => {
  try {
    let topiclist = await axiosInstance.get(
      `${baseURL}/api/getAllQuestionTopics`,
      {}
    );

    return topiclist;
  } catch (err) {
    console.log(err);
  }
};

const addtopiclist = async (data) => {
  try {
    let addtopiclist = await axiosInstance.post(
      `${baseURL}/api/addTopic`,
      data
    );

    return addtopiclist;
  } catch (err) {
    console.log(err);
  }
};

 

const saveQuestion = async (data) => {
  try {
    let saveQuestion = await axiosInstance.post(
      `${baseURL}/api/saveQuestion`,
      data
    );

    return saveQuestion;
  } catch (err) {
    console.log(err);
  }
};

const getfilterquestion = async (data) => {
  try {
    let getfilterquestion = await axiosInstance.post(
      `${baseURL}/api/questionBankfilter`,
      data
    );

    return getfilterquestion;
  } catch (err) {
    console.log(err);
  }
};

const deleteqstnrow = async (data) => {
  try {
    let deleteqstnrow = await axiosInstance.post(
      `${baseURL}/api/deleteQuestion`,
      data
    );

    return deleteqstnrow;
  } catch (err) {
    console.log(err);
  }
};

const editQuestion = async (data) => {
  try {
    let editQuestion = await axiosInstance.post(
      `${baseURL}/api/editQuestion`,
      data
    );

    return editQuestion;
  } catch (err) {
    console.log(err);
  }
};

const uploadquestionxlsx = async (data) => {
  try {
    let uploadquestionxlsx = await axiosInstance.post(`${baseURL}/api/importQuestion`,data,{headers: {
      'Content-Type': 'multipart/form-data',
    },});
    if (uploadquestionxlsx) {
      return {
        data: uploadquestionxlsx.data
      };
    } else {
      return false;
    }
  } catch (err) {
    console.log(err, "error");
  }
};


const CertificateDetailService = {
  getqtype,
  subjectlist,
  topiclist,
  addsubjectlist,
  addtopiclist,
  saveQuestion,
  getfilterquestion,
  getEditData,
  deleteqstnrow,
  editQuestion,
  uploadquestionxlsx
};

export default CertificateDetailService;
