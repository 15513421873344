import React, { useEffect, useState } from "react";
import {
  Grid,
  Autocomplete,
  TextField,
  Chip,
  OutlinedInput,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Skeleton,
} from "@mui/material";
import Appbar from "../../components/atoms/header/header";
import { POPPINS } from "../../constants/fontConstantsPoppins";
import AssetLibraryViewTable from "./components/assetlibrarytableview";
import AssetViewHooks from "./hooks/AssetViewHooks";
import SkeletonRows from "../../components/atoms/skeleton/skeletontable";
import SearchInput from "../../components/atoms/searchbar/SearchBar";

function Index() {
  const [title, setTitle] = useState("");
  const [status, setStatus] = React.useState("");
  const [type, setType] = React.useState("");
  const [selectOpen, setSelectOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [coursefilter,setCoursefilter]=useState([])
  const { getcourseandstatus,courseloading,tableloading, courselist,getchaptercontenttype,contenttype,getTabledata,tabledata } = AssetViewHooks();

  const statuslist = [
    { Id: 1, Status: "Text" },
    { Id: 2, Status: "Link" },
    { Id: 3, Status: "pdf" },
  ];

  const courselists = [
    { Id: 1, Status: "Text" },
    { Id: 2, Status: "Link" },
    { Id: 3, Status: "pdf" },
  ];

  const TableHeaderandRowdetailes = [
    {
      id: 1,
      name: "Created Date",
      rowDetailes: "Created_at",
    },
    {
      id: 2,
      name: "Modified Date",
      rowDetailes: "Updated_at",
    },
    {
      id: 3,
      name: "Title",
      rowDetailes: "Title",
    },
    {
      id: 4,
      name: "Created By",
      rowDetailes: "Created_by",
    },
    //   {
    //     id: 5,
    //     name: 'Used In',
    //     rowDetailes:"uin"
    // },
    {
      id: 6,
      name: "Actions",
      rowDetailes: null,
    },
  ];

  const data = [
    {
      Id: 1,
      cdate: "2023-05-01",
      mdate: "2023-05-20",
      title: "Sample Title 1",
      cby: "john",
      uin: 1,
      Type: 1,
    },
    {
      Id: 2,
      cdate: "2023-04-15",
      mdate: "2023-05-10",
      title: "Sample Title 2",
      cby: "hello",
      uin: 3,
      Type: 2,
    },
  ];
  const [selectedValues, setSelectedValues] = useState([]);
console.log(coursefilter,type,"fghgjh");
  const handleAutocompleteChange = (event, newValue) => {
    const updatedData = newValue
      ?.map((item) => {
        if (item) {
          const { Id } = item;
          return { id: Id };
        } else {
          return null;
        }
      })
      .filter(Boolean); // Remove any null values from the array
    console.log(updatedData, "newww");
    setCoursefilter(updatedData)
    setSelectedValues(newValue);
  };

  const handleChangestatusassetType = (event) => {
    setType(event.target.value)
    setStatus(event.target.value);
  };

  const titlechange = (e) => {
    setTitle(e);
  };

  const handlereset = () => {
    setStatus("");
    setType("")
    setTitle("");
    setCoursefilter("")
    setSelectedValues([])
  };

  useEffect(() => {
    getcourseandstatus().then((res) => {
      console.log(res, "res");
    });
  }, []);

  useEffect(() => {
    getchaptercontenttype().then((res) => {
      console.log(res, "res");
    });
  }, []);
  console.log(status,"fi");

  useEffect(()=>{
    getTabledata({
      course: coursefilter,
      type: type?[{"id":type}]:[],
      searchname: title
    })
  },[coursefilter,type,title,refresh])

  return (
    <Grid container direction="column">
      <Appbar title="Asset Library" />
      <Grid item p={4}>
        <Grid container xs={12} spacing={2} alignItems="center">
          <Grid  container spacing={2} xs={10} alignItems={"center"}>
          <Grid item display="flex" alignItems="center">
          <Typography pl={4} fontFamily={POPPINS} fontWeight={500} fontSize={13}>
            Filter by:
          </Typography>
        </Grid>
          {!courseloading ? <Grid item xs={12} sm={6} md={2}>
          <Autocomplete
            multiple
            limitTags={2}
            options={courselist}
            value={selectedValues}
            onChange={handleAutocompleteChange}
            isOptionEqualToValue={(option, val) => option.Id === val.Id}
            getOptionLabel={(option) => option.Title}
            renderInput={(params) => (
              <TextField
                {...params}
                id="autocomplete-input"
                placeholder="Courses"
                sx={{
                  fontFamily: POPPINS,
                  borderRadius: 2,
                  fontSize: 12,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "1px solid #757575",
                    borderRadius: 2,
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "1px solid #757575",
                    },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "1px solid #757575",
                    },
                  "& input::placeholder": {
                    // Apply styles to the placeholder text
                    fontSize: 12, // Adjust the font size here
                    fontFamily: POPPINS,
                  },
                }}
                InputLabelProps={{
                  style: { fontFamily: POPPINS, fontSize: 12 },
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.Title}
                  {...getTagProps({ index })}
                  sx={{
                    fontFamily: POPPINS,
                    fontSize: 12,
                    maxHeight: "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginRight: "5px",
                    width: "200px",
                  }}
                />
              ))
            }
            sx={{
              width: "95%",
              borderRadius: 2,
              maxHeight: "auto",
              backgroundColor: "#fff",
              ml: 1,
              fontFamily: POPPINS,
            }}
          />
        </Grid>:<Skeleton sx={{marginLeft:"10px"}} variant="rectangular" width={180} height={10} animation="wave" />}
         {!courseloading? <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            {/* <InputLabel
          sx={{ fontFamily: POPPINS, fontSize: 12 }}
          id="demo-simple-select-label"
        >
          Status
        </InputLabel> */}
            <Select
              id="demo-simple-select"
              value={status}
              //  placeholder="Status"
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <Typography
                      style={{
                        opacity: "0.4",
                        fontFamily: POPPINS,
                        fontSize: 12,
                      }}
                    >
                      Asset Type
                    </Typography>
                  );
                }
                const selectedItem = contenttype.find(
                  (item) => item.Id === selected
                );
                return selectedItem ? selectedItem.DisplayName : "";
              }}
              displayEmpty
              getOptionLabel={(option) => option.DisplayName}
              open={selectOpen} // Set the open state of the select
              onOpen={() => setSelectOpen(true)} // Open the select
              onClose={() => setSelectOpen(false)} // Close the select
              onChange={handleChangestatusassetType}
              sx={{
                fontFamily: POPPINS,
                height: "56px",
                borderRadius: 2,
                fontSize: 12,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "1px solid #757575",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "1px solid #757575",
                },

                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "1px solid #757575",
                },
                backgroundColor: "#fff",
              }}
            >
              {contenttype?.filter(
                (item) => item.DisplayName !== "Heading"
              ).map((itm) => (
                <MenuItem value={itm?.Id}>{itm?.DisplayName}</MenuItem>
              ))}
              {type?  <Grid display={"flex"} justifyContent={"center"}>

              <Button
              onClick={()=>{setType("")
              setStatus("")
                setSelectOpen(false)
              }}
              sx={{ backgroundColor: "white", border: 1,alignSelf:"center" }}
            >
              <Typography textTransform={"none"}>
                Reset Filter
              </Typography>
            </Button>
              </Grid>:""}
            </Select>
          </FormControl>
        </Grid>:<Skeleton sx={{marginLeft:"10px"}} variant="rectangular" width={180} height={10} animation="wave" />}

       {!courseloading? <Grid item xs={12} sm={6} md={3} mb={1} alignItems={"center"}>
        <SearchInput reset={title} label={"Search by title"} onSearch={titlechange}/>
        </Grid>:<Skeleton sx={{marginLeft:"10px"}} variant="rectangular" width={180} height={10} animation="wave" />}

      
          </Grid>
          <Grid container xs={2} pr={4} justifyContent={"end"}>
          {coursefilter.length > 0 || type || title.length > 0 ? (
            <Grid item xs={12} sm={6} md={2} sx={{width:"100px"}}>
              <Button onClick={handlereset} sx={{ textTransform: "none",width:"100px" }}>
                Reset filter
              </Button>
            </Grid>
          ) : (
            ""
          )}
          </Grid>
    
        </Grid>
       
        <Grid item mt={4}>
          {tableloading? <SkeletonRows count={30}/>:<AssetLibraryViewTable
            data={tabledata??[]}
            tableHeadrowval={TableHeaderandRowdetailes}
            setRefresh={setRefresh}
          />}
          
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Index;
