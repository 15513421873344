import CertificateDetailService from "../api"

const QuestionBankservice = ()=>{

const getquestiontype=()=>{
    return CertificateDetailService.getqtype()
}

const subjectlistdatas=()=>{
    return CertificateDetailService.subjectlist()
}

const topiclistdatas=()=>{
    return CertificateDetailService.topiclist()
}

const addsubjectdatas=(data)=>{
    return CertificateDetailService.addsubjectlist(data)
}

const addtopicdatas=(data)=>{
    return CertificateDetailService.addtopiclist(data)
}

const saveallquestion=(data)=>{
    return CertificateDetailService.saveQuestion(data)
}
const getEditDataHook =(id)=>{
    return CertificateDetailService.getEditData(id)
}

const filterquestion=(data)=>{
    return CertificateDetailService.getfilterquestion(data)
}

const deleteqstnrow=(data)=>{
    return CertificateDetailService.deleteqstnrow(data)
}

const editallqstn=(data)=>{
    return CertificateDetailService.editQuestion(data)
}

const uploadquestion=(data)=>{
    return CertificateDetailService.uploadquestionxlsx(data)
}

return{
    getquestiontype,
    subjectlistdatas,
    topiclistdatas,
    addsubjectdatas,
    addtopicdatas,
    saveallquestion,
    getEditDataHook,
    filterquestion,
    deleteqstnrow,
    editallqstn,
    uploadquestion,
}
}

export default QuestionBankservice