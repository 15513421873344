import React from 'react'
import Sidebar from './components/Sidebar'
import {
  Box,
  Button,
  Collapse,
  CssBaseline,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { POPPINS } from '../../constants/fontConstantsPoppins';
import Appbar from './components/Appbar';
import DetailNavbar from './components/DetailNavbar';
import CoursesDetailForm from './components/CoursesDetailForm'





function CourseDetailsAdmin() {
  return (
    <Box width={"100%"}  sx={{backgroundColor: "#F7F8F9"}}  >
      <CssBaseline />
      <Grid sx={{ display: "flex" , width:"100%", height: "100%", }} >
        <Sidebar/>
     {/* <Grid>
       </Grid> */}
        <Grid width={"100%"} sx={{overflowX:"hidden"}}  >
            <Box sx={{
            
              //  '@media (max-width: 1150px)': {
              //   paddingLeft:"50px",
               
              // }, '@media (max-width: 930px)': {
              //   paddingLeft:"70px",
               
              // },'@media (max-width: 740px)': {
              //   paddingLeft:"100px",
               
              // },'@media (max-width: 599px)': {
              //   paddingLeft:"0px",
               
              // },
            }} backgroundColor="#F7F8F9" height="100%">
                <Appbar/>
                {/* <DetailNavbar/> */}
              
                  <CoursesDetailForm/>


            </Box>
      </Grid>
      </Grid>
    </Box>
  )
}

export default CourseDetailsAdmin