import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';

function SkeletonLoader({ count }) {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <Grid flexDirection={"column"} ml={4} item key={index}>
      <Skeleton width={250} height={20} />
      <Skeleton width={250} height={20} />
      <Skeleton sx={{mt:10}} width={80} height={40} />
      <Skeleton sx={{mt:8}} width={250} height={20} />
      <Skeleton  sx={{mt:4}} width={100} height={20} />

    </Grid>
  ));

  return <>{skeletons}</>;
}

export default SkeletonLoader;
