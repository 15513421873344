import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateImage, updateName } from '../../dashboards/userDetailSlice/userDetailSlice';

function ProfileDetailHook() {
    const dispatch = useDispatch()

    const updateProfilePicHook = (image_url)=>{
        dispatch(updateImage(image_url))
    }
    const updateNameHook =(name)=>{
        dispatch(updateName(name))
    }


    const {image_url,name,email} = useSelector((state)=>state.userDetailSlice.user)
    const isLoading = useSelector((state) => state.userDetailSlice.isLoading);

   
  return {name,email,image_url,isLoading,updateProfilePicHook,updateNameHook}
}

export default ProfileDetailHook