import axiosInstance from '../../../services/axiosservice';
 
const baseURL = process.env.REACT_APP_API_BASE_URL
 
 
const getCourseDetail = async (id)=>{
        try {
            let res = await axiosInstance.get(`${baseURL}/api/getDetailsPageInformation/${id}`)
             
            return res
 
        } catch (err) {
            console.log(err)
        }
}
const postcourseimage = async (data) => {
  try {
    let postImage = await axiosInstance.post(`${baseURL}/api/updateCoverPhoto`,data,{headers: {
      'Content-Type': 'multipart/form-data',
    },});
    if (postImage) {
      return {
        data: postImage.data
      };
    } else {
      return false;
    }
  } catch (err) {
    console.log(err, "error");
  }
};

const getcourseimage = async (id) => {
  try {
    let getImage = await axiosInstance.get(`${baseURL}/api/getCoverPhotoUrl/${id}`,{course_id:id});
    if (getImage) {
      return {
        data: getImage.data
      };
    } else {
      return false;
    }
  } catch (err) {
    console.log(err, "error");
  }
};

const getChaptercontenttype = async () => {
  try {
    let getChaptercontenttype = await axiosInstance.post(`${baseURL}/api/getChapterContentType`,{});
    if (getChaptercontenttype) {
      return {
        data: getChaptercontenttype.data
      };
    } else {
      return false;
    }
  } catch (err) {
    console.log(err, "error");
  }
};


const postDetail =async (data)=>{
  console.log(data)
  try {
    let postData = await axiosInstance.post(`${baseURL}/api/updateCourse`,data,{headers: {
      'Content-Type': 'multipart/form-data',
    },});
    if (postData) {
      return {
        data: postData?.data
      };
    } else {
      return false;
    }
  } catch (err) {
    console.log(err, "error");
  }
}



 
const CourseDetailService ={getCourseDetail,getcourseimage,postcourseimage,getChaptercontenttype,postDetail}
 
export default CourseDetailService