import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import CourseDetailService from '../Api/index';

const initialState = {
  courselistimgUrl: "",
  loading: true,
  error: null,
}

export const fetchcourseimg = createAsyncThunk("courseimg/fetchcourseimg", (postData) => {
  try {
    const response = CourseDetailService.postcourseimage(postData);
    const data =  response
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
});

export const getcourseimg = createAsyncThunk("courseimg/getcourseimg", (id) => {
  try {
    const response =  CourseDetailService.getcourseimage(id);
    const data =  response
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
});


export const createCourseslice = createSlice({
  name: 'courseimg',
  initialState,
  reducers: {
    setcourselisturl: (state,action) => {

      state.courselistimgUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchcourseimg.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchcourseimg.fulfilled, (state, action) => {
        state.loading = false;
        state.courselistimgUrl = action?.payload?.data?.imageUrl;
      })
      .addCase(fetchcourseimg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getcourseimg.pending, (state) => {
        state.loading = true;
      })
      .addCase(getcourseimg.fulfilled, (state, action) => {
        state.loading = false;
        state.courselistimgUrl = action.payload?.data?.data?.courseCoverPhoto[0]?.ImageUrl;
      })
      .addCase(getcourseimg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
})

export const {setcourselisturl } = createCourseslice.actions

export default createCourseslice.reducer