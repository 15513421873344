import {
  Grid,
  TextField,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  Popover,
  Button,
  FormControl,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Header from "./components/header";
import { debounce } from "lodash";
import Questiontable from "./components/questionbanktable";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { POPPINS } from "../../constants/fontConstantsPoppins";
import QuestionBankservice from "./hooks/questionbankservice";
import SkeletonRows from "../../components/atoms/skeleton/skeletontable";

function Index() {
  const [tabledata, setTabledata] = useState([]);
  const [storedatecreatedto, setStoredatecreatedto] = useState("");
  const [storedatemodifiedto, setStoredatemodifiedto] = useState("");
  const [storedatecreatedby, setStoredatecreatedby] = useState("");
  const [storedatemodifiedby, setStoredatemodifiedby] = useState("");
  const [anchorElCreated, setAnchorElCreated] = useState(null);
  const [anchorElModified, setAnchorElModified] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [mdate, setMdate] = useState(null);
  const [subject, setSubject] = useState("");
  const [topic, setTopic] = useState("");
  const [qtype, setQtype] = useState("");
  const [allqtype, setAllqtype] = useState([]);
  const [loader, setLoader] = useState(false);
  const [typeloader, setTypeloader] = useState(false);
  const [pushload, setPushload] = useState(false);
  const [subjectdebounce, setSubjectdebounce] = useState("");
  const [topicdebounce, setTopicdebounce] = useState("");
  const { filterquestion, getquestiontype } = QuestionBankservice();

  useEffect(() => {
    filterquestiontable();
  }, [selectedDate, mdate, subjectdebounce, topicdebounce, qtype, pushload]);
  useEffect(() => {
    questype();
  }, []);

  const questype = () => {
    setTypeloader(true);
    getquestiontype().then((res) => {
      if (res?.data?.status === 200) {
        setAllqtype(res?.data?.questionTypes);
        setTypeloader(false);
      } else {
        setTypeloader(false);
      }
    });
  };

  const filterquestiontable = () => {
    setLoader(true);
    const data = {
      createdDate: {
        from: selectedDate?.from ?? "",
        to: selectedDate?.to ?? "",
      },
      modifiedDate: {
        from: mdate?.from ?? "",
        to: mdate?.to ?? "",
      },
      subject: subjectdebounce ?? "",
      topic: topicdebounce ?? "",
      questionType: qtype ?? "",
    };

    filterquestion(data).then((res) => {
      if (res?.data?.status === 200) {
        setLoader(false);
        setTabledata(res?.data?.data?.searchData);
      } else {
        setLoader(false);
      }
    });
  };
  const TableHeaderandRowdetailes = [
    { id: 1, name: "Created Date", rowDetailes: "Created_at" },
    { id: 2, name: "Question Text", rowDetailes: "qtext" },
    { id: 3, name: "Subject", rowDetailes: "title" },
    { id: 4, name: "Topic", rowDetailes: "sub" },
    { id: 6, name: "Actions", rowDetailes: null },
  ];

  const handlechangeqtype = (e) => {
    setQtype(e.target.value);
  };

  const handledatetoapply = () => {
    const dateformat = {
      from: storedatecreatedby,
      to: storedatecreatedto,
    };
    setSelectedDate(dateformat);
    setAnchorElCreated(null);
  };
  const handledatemapply = () => {
    const dateformat = {
      from: storedatemodifiedby,
      to: storedatemodifiedto,
    };
    setMdate(dateformat);
    setAnchorElModified(null);
  };

  const handleresetcreated = () => {
    setAnchorElCreated(null);
    setStoredatecreatedby("");
    setSelectedDate(null);
  };

  const handleresetmodified = () => {
    setAnchorElModified(null);
    setStoredatemodifiedby("");
    setMdate(null);
  };

  const handleresetstatus = () => {
    setQtype("");
    setSelectOpen(false);
  };

  const resetall = () => {
    setStoredatemodifiedby("");
    setMdate(null);
    setStoredatecreatedby("");
    setSelectedDate(null);
    setQtype("");
    setSubject("");
    setTopic("");
    setSubjectdebounce("");
    setTopicdebounce("");
  };

  let formattedRangecreated = selectedDate
    ? `${selectedDate?.to} - ${selectedDate?.from}`
    : null;
  let formattedRangemodified = mdate ? `${mdate?.to} - ${mdate?.from}` : null;

  const handleChangeCreatedByDate = (e) =>
    setStoredatecreatedby(e.target.value);
  const handleChangeModifiedByDate = (e) =>
    setStoredatemodifiedby(e.target.value);

  const handleClickCreated = (event) => {
    setAnchorElCreated(event.currentTarget);
    setStoredatecreatedby("");
    setStoredatemodifiedby("");
    setStoredatemodifiedto("");
    setStoredatecreatedto("");
  };
  const handleClickModified = (event) => {
    setAnchorElModified(event.currentTarget);
    setStoredatecreatedby("");
    setStoredatemodifiedby("");
    setStoredatemodifiedto("");
    setStoredatecreatedto("");
  };
  const handleCloseCreated = () => {
    setAnchorElCreated(null);
  };
  const handleCloseModified = () => setAnchorElModified(null);
  const [selectOpen, setSelectOpen] = useState(false);

  const debouncedSubjectSearch = useMemo(
    () => debounce((value) => setSubjectdebounce(value), 1000),
    []
  );
  const debouncedTopicSearch = useMemo(
    () => debounce((value) => setTopicdebounce(value), 1000),
    []
  );

  const handlesubjectonchange = (e) => {
    setSubject(e.target.value);
    debouncedSubjectSearch(e.target.value);
  };

  const handletopiconchange = (e) => {
    setTopic(e.target.value);
    debouncedTopicSearch(e.target.value);
  };

  const handlechangetodate = (e) => {
    setStoredatemodifiedto(e.target.value);
  };

  const handlechangejoinedondate = (e) => {
    setStoredatecreatedto(e.target.value);
  };

  const openCreated = Boolean(anchorElCreated);
  const openModified = Boolean(anchorElModified);
  const idCreated = openCreated ? "simple-popover" : undefined;
  const idModified = openModified ? "simple-popover" : undefined;

  const dateField = () => {
    return (
      <>
        <label
          style={{
            fontFamily: POPPINS,
            fontSize: 12,
            fontWeight: 600,
            marginRight: 1,
          }}
        >
          From: &nbsp;
        </label>
        <TextField
          InputProps={{
            style: { fontFamily: POPPINS, fontSize: 12 },
            inputProps: { min: storedatecreatedto },
          }}
          sx={{ width: 190, mr: 1 }}
          onChange={(e) => handleChangeCreatedByDate(e)}
          type="date"
        />
        <label
          style={{
            fontFamily: POPPINS,
            fontSize: 12,
            fontWeight: 600,
            marginLeft: 1,
          }}
        >
          To: &nbsp;
        </label>
        <TextField
          InputProps={{
            style: { fontFamily: POPPINS, fontSize: 12 },
            inputProps: { min: storedatecreatedby },
          }}
          sx={{ width: 190, mr: 1 }}
          onChange={(e) => {
            handlechangejoinedondate(e);
          }}
          type="date"
        />
      </>
    );
  };

  const dateFieldmodified = () => {
    return (
      <>
        <label
          style={{
            fontFamily: POPPINS,
            fontSize: 12,
            fontWeight: 600,
            marginRight: 1,
          }}
        >
          From: &nbsp;
        </label>
        <TextField
          InputProps={{
            style: { fontFamily: POPPINS, fontSize: 12 },
            inputProps: { min: storedatemodifiedto },
          }}
          sx={{ width: 190, mr: 1 }}
          onChange={(e) => handleChangeModifiedByDate(e)}
          type="date"
        />
        <label
          style={{
            fontFamily: POPPINS,
            fontSize: 12,
            fontWeight: 600,
            marginLeft: 1,
          }}
        >
          To: &nbsp;
        </label>
        <TextField
          InputProps={{
            style: { fontFamily: POPPINS, fontSize: 12 },
            inputProps: { min: storedatemodifiedby },
          }}
          sx={{ width: 190, mr: 1 }}
          onChange={(e) => {
            handlechangetodate(e);
          }}
          type="date"
        />
      </>
    );
  };

  return (
    <Grid container direction="column">
      <Header setPushload={setPushload} />
      <Grid container xs={12} pl={4} pr={4} mt={5} alignItems="center">
        <Grid container alignItems="center" xs={10} spacing={1}>
          <Grid item xs={12} sm={6} md={0.8}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                width={120}
                fontFamily="POPPINS"
                fontWeight={500}
                fontSize={13}
              >
                Filter by: {""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ minWidth: "auto" }}
            >
              {!typeloader ? (
                <TextField
                  sx={{
                    backgroundColor: "#fff",
                    height: "56px",
                    borderRadius: 2,
                    width: "auto",
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "1px solid #757575",
                        borderRadius: 2,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "1px solid #757575",
                        borderRadius: 2,
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px solid #757575",
                      borderRadius: 2,
                    },
                    "& .MuiInputBase-root.Mui-disabled": {
                      backgroundColor: "1px solid #757575",
                      borderRadius: 2,
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickCreated(e);
                  }}
                  value={formattedRangecreated || ""}
                  placeholder="Created date"
                  InputLabelProps={{
                    style: { fontFamily: "POPPINS", fontSize: 12 },
                  }}
                  InputProps={{
                    style: {
                      height: 56,
                      fontFamily: "POPPINS",
                      fontSize: 12,
                      borderRadius: 2,
                    },
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleClickCreated}>
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={180}
                  height={10}
                  animation="wave"
                />
              )}
              <Popover
                id={idCreated}
                open={openCreated}
                anchorEl={anchorElCreated}
                onClose={handleCloseCreated}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Box
                  width={"auto"}
                  p={2}
                  height={"24vh"}
                  flexDirection={"column"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    mt={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                  >
                    {dateField()}
                  </Box>

                  <Box mt={3} pl={1} pr={1} display={"flex"} gap={2}>
                    {selectedDate && (
                      <Button
                        onClick={handleresetcreated}
                        sx={{ backgroundColor: "white", border: 1 }}
                      >
                        <Typography textTransform={"none"}>
                          Reset Filter
                        </Typography>
                      </Button>
                    )}

                    <Button
                      disabled={
                        storedatecreatedby && storedatecreatedto ? false : true
                      }
                      onClick={handledatetoapply}
                      variant="contained"
                    >
                      <Typography textTransform={"none"}>Apply</Typography>
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ minWidth: "auto" }}
            >
              {!typeloader ? (
                <TextField
                  sx={{
                    backgroundColor: "#fff",
                    height: "56px",
                    borderRadius: 2,
                    width: "auto",
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "1px solid #757575",
                        borderRadius: 2,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "1px solid #757575",
                        borderRadius: 2,
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px solid #757575",
                      borderRadius: 2,
                    },
                    "& .MuiInputBase-root.Mui-disabled": {
                      backgroundColor: "1px solid #757575",
                      borderRadius: 2,
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickModified(e);
                  }}
                  value={formattedRangemodified || ""}
                  placeholder="Modified date"
                  InputLabelProps={{
                    style: { fontFamily: "POPPINS", fontSize: 12 },
                  }}
                  InputProps={{
                    style: {
                      height: 56,
                      fontFamily: "POPPINS",
                      fontSize: 12,
                      borderRadius: 2,
                    },
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleClickModified}>
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={180}
                  height={10}
                  animation="wave"
                />
              )}
              <Popover
                id={idModified}
                open={openModified}
                anchorEl={anchorElModified}
                onClose={handleCloseModified}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Box
                  width={"auto"}
                  p={2}
                  height={"24vh"}
                  flexDirection={"column"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box
                    mt={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                  >
                    {dateFieldmodified()}
                  </Box>

                  <Box mt={3} pl={1} pr={1} display={"flex"} gap={2}>
                    {mdate && (
                      <Button
                        onClick={handleresetmodified}
                        sx={{ backgroundColor: "white", border: 1 }}
                      >
                        <Typography textTransform={"none"}>
                          Reset Filter
                        </Typography>
                      </Button>
                    )}

                    <Button
                      disabled={
                        storedatemodifiedto && storedatemodifiedby
                          ? false
                          : true
                      }
                      onClick={handledatemapply}
                      variant="contained"
                    >
                      <Typography textTransform={"none"}>Apply</Typography>
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Grid>
          <Grid item xs={8} sm={6} md={2.5}>
            {!typeloader ? (
              <TextField
                placeholder={"Subject"}
                value={subject}
                InputLabelProps={{
                  style: { fontFamily: POPPINS, fontSize: 12 },
                }}
                InputProps={{
                  style: {
                    fontSize: 12,
                    fontFamily: POPPINS,
                    height: 55,
                    borderColor: "red",
                  },
                }}
                sx={{
                  backgroundColor: "#fff",
                  width: "100%",
                  borderRadius: 2,
                  height: "55px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "1px solid #757575",

                    borderRadius: 2,
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "1px solid #757575",
                      borderRadius: 2,
                    },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "1px solid #757575",
                      borderRadius: 2,
                    },
                }}
                onChange={handlesubjectonchange}
              />
            ) : (
              <Skeleton
                variant="rectangular"
                width={180}
                height={10}
                animation="wave"
              />
            )}
          </Grid>
          <Grid item xs={8} sm={6} md={2.5}>
            {!typeloader ? (
              <TextField
                placeholder={"Topic"}
                value={topic}
                InputLabelProps={{
                  style: { fontFamily: POPPINS, fontSize: 12 },
                }}
                InputProps={{
                  style: {
                    fontSize: 12,
                    fontFamily: POPPINS,
                    height: 55,
                    borderColor: "red",
                  },
                }}
                sx={{
                  backgroundColor: "#fff",
                  width: "100%",
                  borderRadius: 2,
                  height: "55px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "1px solid #757575",

                    borderRadius: 2,
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "1px solid #757575",
                      borderRadius: 2,
                    },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "1px solid #757575",
                      borderRadius: 2,
                    },
                }}
                onChange={handletopiconchange}
              />
            ) : (
              <Skeleton
                variant="rectangular"
                width={180}
                height={10}
                animation="wave"
              />
            )}
          </Grid>
          <Grid item xs={8} sm={6} md={2}>
            {!typeloader ? (
              <FormControl fullWidth>
                {/* <InputLabel
            sx={{ fontFamily: POPPINS, fontSize: 12 }}
            id="demo-simple-select-label"
          >
            Status
          </InputLabel> */}
                <Select
                  id="demo-simple-select"
                  value={qtype}
                  //  placeholder="Status"
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <Typography
                          style={{
                            opacity: "0.4",
                            fontFamily: POPPINS,
                            fontSize: 12,
                          }}
                        >
                          Asset Type
                        </Typography>
                      );
                    }
                    const selectedItem = allqtype?.find(
                      (item) => item.Id === selected
                    );
                    return selectedItem ? selectedItem.Type : "";
                  }}
                  displayEmpty
                  getOptionLabel={(option) => option.name}
                  open={selectOpen} // Set the open state of the select
                  onOpen={() => setSelectOpen(true)} // Open the select
                  onClose={() => setSelectOpen(false)} // Close the select
                  onChange={handlechangeqtype}
                  sx={{
                    fontFamily: POPPINS,
                    height: "56px",
                    borderRadius: 2,
                    fontSize: 12,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px solid #757575",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px solid #757575",
                    },

                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px solid #757575",
                    },
                    backgroundColor: "#fff",
                  }}
                >
                  {allqtype?.map((itm) => (
                    <MenuItem value={itm?.Id}>{itm?.Type}</MenuItem>
                  ))}
                  {qtype ? (
                    <Grid display={"flex"} justifyContent={"center"}>
                      <Button
                        onClick={handleresetstatus}
                        sx={{
                          backgroundColor: "white",
                          border: 1,
                          alignSelf: "center",
                        }}
                      >
                        <Typography textTransform={"none"}>
                          Reset Filter
                        </Typography>
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Select>
              </FormControl>
            ) : (
              <Skeleton
                variant="rectangular"
                width={180}
                height={10}
                animation="wave"
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={2} sm={6} md={2}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Button
              onClick={resetall}
              sx={{
                textTransform: "none",
                display:
                  selectedDate || mdate || topic || subject || qtype
                    ? "flex"
                    : "none",
              }}
            >
              <Typography fontFamily="POPPINS" fontWeight={600} fontSize={13}>
                Reset Filter
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid p={4}>
        {!loader ? (
          <Questiontable
            setPushload={setPushload}
            data={tabledata}
            tableHeadrowval={TableHeaderandRowdetailes}
          />
        ) : (
          <SkeletonRows count={6} />
        )}
      </Grid>
    </Grid>
  );
}

export default Index;
