import DashboardlearnersService from "../api"


const DashboardLearnersService=()=>{

    const postdashboard=()=>{
        return DashboardlearnersService.getdashboarddetail()
        }

    return {
     postdashboard
    
    }
}

export default DashboardLearnersService