import { useDispatch, useSelector } from "react-redux"
import { fetchcourseimg,getcourseimg, setcourselisturl } from "../createCourseDetailSlice/createCourseImgSlice"
import { getCourseDetails , postCourseDetails, setInitialState} from "../createCourseDetailSlice/createCourseDetailSlice"
import { enqueueSnackbar } from 'notistack';
import SuccessSnackbar from "../../../components/molecules/customsnackbar/successSnackbar";

const CourseDetailHooks = ()=>{
const dispatch = useDispatch()


    const getCourseDetailHook = (id)=>{
        dispatch(getCourseDetails(id))
        
    }
    const postcourseimage = async (data,setProfilemodal) => {

        try{
           
           return dispatch(fetchcourseimg(data)).then(res=>{
                if(res?.payload?.data?.status===200){
                    enqueueSnackbar('Image uploaded', {
                        variant: 'error', 
                        content: (key, message) => (
                          <SuccessSnackbar id={key} message={message} allowDownload={true} />
                        ),
                      });
                    setProfilemodal(false)
                }
            });
        }
        catch(err){
            console.error("Error dispatching fetchcourseimg:", err);
        }
        
      }
      const setcourselistUrl =()=>{
        dispatch(setcourselisturl(""))
      }

      const setInitialStateForm =()=>{
        dispatch(setInitialState())
      }


      const getCourseimgHook = async (id) => {
        try{
            dispatch(getcourseimg(id)).then(res=>console.log(res,"res"));
        }
        catch(err){
            console.error("Error dispatching fetchcourseimg:", err);
        }
        
      }

      const setPostDetail = async (data)=>{
       
         try {
            return dispatch(postCourseDetails(data)).then(res=>{
                return res?.payload
            })
         } catch (err) {
          console.error("Error dispatching fetchcourseimg:", err)
         }
      }
  
      const courseimgUrl = useSelector((state) => state.createCourseReducer.courselistimgUrl);
      const loading= useSelector((state)=>state.createCourseReducer.loading)
      const loadingDetail = useSelector((state) => state.createCourseDetailSlice.loading);
      const CourseDetailFormHook = useSelector((state) => state.createCourseDetailSlice.courseDetails)
      console.log(CourseDetailFormHook)
return{
    getCourseDetailHook,
    getCourseimgHook,
    setcourselistUrl:setcourselistUrl,
    setInitialStateForm,
    load:loading,
    loadingDetail,
    courseimgUrl:courseimgUrl,
    CourseDetailFormHook,
    postcourseimage:postcourseimage,
    setPostDetail
}
}

export default CourseDetailHooks