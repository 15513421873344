import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import { getRoutes } from "./index";
import CourseDetailsAdmin from "../features/coursedetails";
import Createcourse from "../pages/createcourse";
import { useSelector } from "react-redux";

const SignIn = lazy(() => import("../pages/Signin"));
const ForgotPassword = lazy(() => import("../pages/forgotpassword"));
const ResetPassword = lazy(() => import("../pages/resetpassword"));
const PrivateRoute = lazy(() => import("./privateroute"));
const PublicRoute = lazy(() => import("./publicroute"));
const ErrorPage = lazy(() => import("../error-page"));
const CourseDetail = lazy(() => import("../features/courses/components/courseDetail"));
const UserDetail = lazy(() => import("../pages/userdetail"));

const RoutesComponent = () => {
  const [state,setState]=useState([{component:"djjd",id:"3",path:"report"}])
  const isAuthenticated = useSelector((state) => state.signinReducer.token);
  useEffect(()=>{
    const routes=getRoutes()
    setState(routes)
  },[isAuthenticated])
  const usercookielevelroutes=getRoutes()
  const routecomp=usercookielevelroutes??state
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          {
            routecomp?.map((route) => (
              <Route key={route.id} path={route.path} element={route.component} />
            ))}
        </Route>
        <Route path="*" element={<ErrorPage />} />
        <Route path="/coursedetails/:id" element={<CourseDetailsAdmin />} />
        <Route path="/courses/:id" element={<CourseDetail />} />
        <Route path="/createcourse/:id" element={<Createcourse />} />
        <Route path="/userdetails/:id" element={<UserDetail />} />
        <Route path="/signin" element={<PublicRoute component={SignIn} restricted={true} />} />
        <Route path="/forgotpassword" element={<PublicRoute component={ForgotPassword} restricted={true} />} />
        <Route path="/resetpassword" element={<PublicRoute component={ResetPassword} restricted={true} />} />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
