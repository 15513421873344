import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import LayoutModal from "../../../components/atoms/layoutModal/LayoutModal";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import JoditEditor, { Jodit } from "jodit-react";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import QuestionBankservice from "../hooks/questionbankservice";
import { SuspenseLoader } from "../../../components/loader/susenseloader";
import { useSnackbar } from "notistack";
import ErrorSnackbar from "../../../components/molecules/customsnackbar/errorSnackbar";

function Table({ questionmodal, setQuestionmodal, setPushload }) {
  const [typeSubjectTopic, settypeSubjectTopic] = useState({
    type: 1,
    Subject: "",
    Topic: "",
    Question: "",
    Explanation: "",
  });
  const [answer, setAnswer] = useState({
    ans: "",
    isAnswer: false,
    min: 0,
    max: 0,
  });
  const [advOptionMarks, setAdvOptionMarks] = useState({
    mark: 0,
    penalty: 0,
    eachCorrectOption: 0,
  });
  const [createSubject, setCreateSubject] = useState("");
  const [createTopic, setCreateTopic] = useState("");
  const [subjectModal, setSubjectModal] = useState(false);
  const [topicModal, setTopicModal] = useState(false);
  const [isAdvOption, setIsAdvOption] = useState(false);
  const [options, setOptions] = useState([
    { id: 1, isChecked: false, inputValue: "" },
    { id: 2, isChecked: false, inputValue: "" },
    { id: 3, isChecked: false, inputValue: "" },
    { id: 4, isChecked: false, inputValue: "" },
  ]);
  const [qtype, setQtype] = useState([]);
  const [slist, setSlist] = useState([]);
  const [tlist, setTlist] = useState([]);
  const [loading, setLoading] = useState({
    subjectaddload: false,
    topicaddload: false,
    submit: false,
    // getsubjectload:false,topicload:false,gettypeload:false
  });
  const [validationErrors, setValidationErrors] = useState({
    subject: true,
    topic: true,
    question: true,
    options: true,
  });
  const [fetchchange, setFetchchange] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    getquestiontype,
    subjectlistdatas,
    topiclistdatas,
    addsubjectdatas,
    addtopicdatas,
    saveallquestion,
  } = QuestionBankservice();
  const questionEditor = useRef(null);
  const explanationEditor = useRef(null);

  const validateForm = useCallback(() => {
    const errors = {
      subject: typeSubjectTopic.Subject !== "",
      topic: typeSubjectTopic.Topic !== "",
      question: typeSubjectTopic.Question !== "",
      options:
        typeSubjectTopic?.type === 2
          ? options.every((option) => option.inputValue !== "")
          : typeSubjectTopic?.type === 1
          ? options.every(
              (option) => option.inputValue !== "" || option.isChecked
            )
          : true,
    };
    setValidationErrors(errors);
    return Object.values(errors).every((val) => val);
  }, [typeSubjectTopic, options]);

  const handleEditorBlur = useCallback((name, newContent) => {
    settypeSubjectTopic((prev) => {
      const trimmedContent = newContent.replace(/\s+/g, " ").trim();
      return { ...prev, [name]: trimmedContent };
    });
  }, []);
  //  question text editer configuration
  const configDescription = useMemo(() => {
    return (placeholderText) => ({
      placeholder: `${placeholderText}`,
      height: "350px",
      toolbarSticky: false,
      pasteHTMLActionList: Jodit.atom([
        { value: Jodit.constants.INSERT_ONLY_TEXT, text: "Insert only Text" },
      ]),
    });
  }, [typeSubjectTopic.Explanation]);

  // option jodit editer configuration
  const getConfigDescription = useMemo(() => {
    return (id) => ({
      placeholder: `Correct option ${id}`,
      height: "350px",
      toolbarSticky: false,
      pasteHTMLActionList: Jodit.atom([
        { value: Jodit.constants.INSERT_ONLY_TEXT, text: "Insert only Text" },
      ]),
    });
  }, []);

  const optionsHandleChange = useCallback(
    (id, isChecked) => {
      if (typeSubjectTopic?.type == 1) {
        setOptions((prev) =>
          prev.map((option) =>
            option.id === id
              ? { ...option, isChecked: !option.isChecked }
              : { ...option, isChecked: false }
          )
        );
      } else {
        setOptions((prev) =>
          prev.map((option) =>
            option.id === id ? { ...option, isChecked } : option
          )
        );
      }
    },
    [typeSubjectTopic?.type]
  );

  const handleChangeAnswer = (e) => {
    const { name, value } = e.target;
    setAnswer((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlenewsubjectchange = useCallback((e) => {
    setCreateSubject(e.target.value);
  }, []);

  const handleEditorChange = (id, newContent) => {
    setOptions((prev) =>
      prev.map((option) =>
        option.id === id ? { ...option, inputValue: newContent } : option
      )
    );
  };

  const handleChangeAdvMark = useCallback((e) => {
    const { name, value } = e.target;
    setAdvOptionMarks((prev) => {
      return { ...prev, [name]: value };
    });
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    settypeSubjectTopic((prev) => {
      return { ...prev, [name]: value };
    });
    if (name === "type") {
      setOptions([
        { id: 1, isChecked: false, inputValue: "" },
        { id: 2, isChecked: false, inputValue: "" },
        { id: 3, isChecked: false, inputValue: "" },
        { id: 4, isChecked: false, inputValue: "" },
      ]);
      settypeSubjectTopic((prev) => {
        return {
          ...prev,
          Subject: "",
          Topic: "",
          Question: "",
          Explanation: "",
        };
      });

      setAnswer({ ans: "", isAnswer: false, min: 0, max: 0 });
      setAdvOptionMarks({ mark: 0, penalty: 0, eachCorrectOption: 0 });
    }
  }, []);

  const removeHandler = (removeId) => {
    const filterOption = options.filter(({ id }) => id !== removeId);
    setOptions(filterOption);
  };

  const handleAddOption = () => {
    setOptions((prev) => {
      return [
        ...prev,
        { id: options.length + 1, isChecked: false, inputValue: "" },
      ];
    });
  };

  const handleCreateSubject = (e) => {
    setLoading((prev) => ({ ...prev, subjectaddload: true }));
    e.preventDefault();
    const data = { subject: createSubject };
    addsubjectdatas(data).then((res) => {
      if (res?.data?.status === 200) {
        setSubjectModal(false);
        setLoading((prev) => ({ ...prev, subjectaddload: false }));
        setFetchchange((prev) => !prev);
      } else if (res?.data?.status === 550) {
        enqueueSnackbar("Same subject exist", {
          variant: "success",
          content: (key, message) => (
            <ErrorSnackbar id={key} message={message} allowDownload={true} />
          ),
        });
        setLoading((prev) => ({ ...prev, subjectaddload: false }));
      } else {
        setLoading((prev) => ({ ...prev, subjectaddload: false }));
      }
    });
  };

  const handleCreateTopic = (e) => {
    setLoading((prev) => ({ ...prev, topicaddload: true }));
    e.preventDefault();
    const data = { topic: createTopic };
    addtopicdatas(data).then((res) => {
      if (res?.data?.status === 200) {
        setFetchchange((prev) => !prev);
        setTopicModal(false);
        setLoading((prev) => ({ ...prev, topicaddload: false }));
      } else if (res?.data?.status === 551) {
        enqueueSnackbar("Same topic exist", {
          variant: "success",
          content: (key, message) => (
            <ErrorSnackbar id={key} message={message} allowDownload={true} />
          ),
        });
        setLoading((prev) => ({ ...prev, topicaddload: false }));
      } else {
        setLoading((prev) => ({ ...prev, topicaddload: false }));
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoading((prev) => ({ ...prev, submit: true }));
      const optiondata = options?.map((itm, k) => {
        return {
          optionText: itm?.inputValue,
          is_correct: itm?.isChecked ? 1 : 0,
          orderNumber: itm?.id,
        };
      });

      const data = {
        type: typeSubjectTopic?.type,
        subject: typeSubjectTopic?.Subject,
        topic: typeSubjectTopic?.Topic,
        questionText: typeSubjectTopic?.Question,
        answer: answer?.ans,
        explanation: typeSubjectTopic?.Explanation,
        markEachOption: advOptionMarks?.eachCorrectOption,
        mark: advOptionMarks?.mark,
        penalty: advOptionMarks?.penalty,
        option:
          typeSubjectTopic?.type === 3 || typeSubjectTopic?.type === 4
            ? []
            : optiondata,
      };

      saveallquestion(data).then((res) => {
        if (res?.data?.status === 200) {
          setLoading((prev) => ({ ...prev, submit: false }));
          setQuestionmodal(false);
          settypeSubjectTopic({
            type: 1,
            Subject: "",
            Topic: "",
            Question: "",
            Explanation: "",
          });
          setOptions([
            { id: 1, isChecked: false, inputValue: "" },
            { id: 2, isChecked: false, inputValue: "" },
            { id: 3, isChecked: false, inputValue: "" },
            { id: 4, isChecked: false, inputValue: "" },
          ]);
          setAnswer({
            ans: "",
            isAnswer: false,
            min: 0,
            max: 0,
          });
          setAdvOptionMarks({
            mark: 0,
            penalty: 0,
            eachCorrectOption: 0,
          });
          setPushload((prev) => !prev);
        } else {
          setLoading((prev) => ({ ...prev, submit: false }));
        }
      });
    } else {
      console.error("Failed to save question");
    }
  };

  const getquestiontypeval = () => {
    // setLoading((prev) => ({ ...prev, gettypeload: true }));
    getquestiontype().then((res) => {
      if (res?.data?.status === 200) {
        setQtype(res?.data?.questionTypes);
        // setLoading((prev) => ({ ...prev, gettypeload: false }));
      } else {
        // setLoading((prev) => ({ ...prev, gettypeload: false }));
      }
    });
  };

  const subjectgetfn = () => {
    // setLoading((prev) => ({ ...prev, getsubjectload: true }));
    subjectlistdatas().then((res) => {
      if (res?.data?.status === 200) {
        setSlist(res?.data?.subjectText);
        // setLoading((prev) => ({ ...prev, getsubjectload: false }));
      } else {
        // setLoading((prev) => ({ ...prev, getsubjectload: false }));
      }
    });
  };

  const topiclistfn = () => {
    // setLoading((prev) => ({ ...prev, topicload: true }));
    topiclistdatas().then((res) => {
      if (res?.data?.status === 200) {
        setTlist(res?.data?.questionTopics);
        // setLoading((prev) => ({ ...prev, topicload: false }));
      } else {
        // setLoading((prev) => ({ ...prev, topicload: false }));
      }
    });
  };

  useEffect(() => {
    getquestiontypeval();
    subjectgetfn();
    topiclistfn();
  }, [fetchchange]);

  const renderValidationMessage = (field) => {
    if (!validationErrors[field]) {
      return (
        <Typography variant="caption" color="error">
          {`${field.charAt(0).toUpperCase() + field.slice(1)} is required`}
        </Typography>
      );
    }
    return null;
  };

  return (
    <>
      <LayoutModal
        newfil={"reset"}
        resetall={() => {
          settypeSubjectTopic({
            type: 1,
            Subject: "",
            Topic: "",
            Question: "",
            Explanation: "",
          });
          setOptions([
            { id: 1, isChecked: false, inputValue: "" },
            { id: 2, isChecked: false, inputValue: "" },
            { id: 3, isChecked: false, inputValue: "" },
            { id: 4, isChecked: false, inputValue: "" },
          ]);
          setAnswer({
            ans: "",
            isAnswer: false,
            min: 0,
            max: 0,
          });
          setAdvOptionMarks({
            mark: 0,
            penalty: 0,
            eachCorrectOption: 0,
          });
        }}
        handleModal={questionmodal}
        setHandleModal={setQuestionmodal}
        w={"80%"}
        h={"90%"}
      >
        <Grid
          pl={4}
          pt={4}
          pr={4}
          item
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <Typography fontFamily={POPPINS} fontSize={17}>
              New Question
            </Typography>
          </Box>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setQuestionmodal(false);
              settypeSubjectTopic({
                type: 1,
                Subject: "",
                Topic: "",
                Question: "",
                Explanation: "",
              });
              setOptions([
                { id: 1, isChecked: false, inputValue: "" },
                { id: 2, isChecked: false, inputValue: "" },
                { id: 3, isChecked: false, inputValue: "" },
                { id: 4, isChecked: false, inputValue: "" },
              ]);
              setAnswer({
                ans: "",
                isAnswer: false,
                min: 0,
                max: 0,
              });
              setAdvOptionMarks({
                mark: 0,
                penalty: 0,
                eachCorrectOption: 0,
              });
            }}
          >
            <CloseIcon />
          </Box>
        </Grid>
        <Grid sx={{ overflowY: "scroll" }} height={"85%"} p={3}>
          <Box
            p={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontFamily={POPPINS} fontSize={13}>
              Type:
            </Typography>
            <Select
              sx={{
                width: "70%",
                height: "40px",
                fontFamily: POPPINS,
                fontSize: 13,
              }}
              name="type"
              value={typeSubjectTopic.type}
              onChange={handleChange}
            >
              {qtype?.map((type, index) => {
                return (
                  <MenuItem key={index} value={type?.Id}>
                    {type?.Type}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box
            p={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography width={"43%"} fontFamily={POPPINS} fontSize={13}>
              Subject:*
            </Typography>
            <Grid
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"5px"}
            >
              <FormControl sx={{width:"100%"}} >
                {!typeSubjectTopic?.Subject?
              <InputLabel  htmlFor="subject-select"
    sx={{
      position: "absolute",
      top: "50%",
      left: "10px",
      transform: "translateY(-50%)",
      pointerEvents: "none",
      transition: "none",
      background: "#fff",
      padding: "0 4px",
    }}
    shrink={false}
  >
    Subject
  </InputLabel>:""}
              <Select
                sx={{ width: "100%", height: "40px" }}
                name="Subject"
                inputProps={{
                  name: "Subject",
                  id: "subject-select",
                }}
                value={typeSubjectTopic?.Subject}
                onChange={handleChange}
              >
                {slist?.map((type, index) => {
                  return (
                    <MenuItem key={index} value={type?.Id}>
                      {type?.Subject_Text}
                    </MenuItem>
                  );
                })}
              </Select>
              </FormControl>
              <Grid p={1}>
                <Tooltip title="Add Option" placement="right">
                  <ControlPointOutlinedIcon
                    onClick={() => setSubjectModal(true)}
                    sx={{
                      backgroundColor: "#3F51B5",
                      color: "#fff",
                      borderRadius: "100%",
                      cursor: "pointer",
                      fontSize: "30px",
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            {renderValidationMessage("subject")}
          </Box>

          <Box
            p={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography width={"43%"} fontFamily={POPPINS} fontSize={13}>
              Topic:*
            </Typography>
            <Grid
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"5px"}
            >
                   <FormControl sx={{width:"100%"}} >
                {!typeSubjectTopic?.Topic?
              <InputLabel  htmlFor="subject-select"
    sx={{
      position: "absolute",
      top: "50%",
      left: "10px",
      transform: "translateY(-50%)",
      pointerEvents: "none",
      transition: "none",
      background: "#fff",
      padding: "0 4px",
    }}
    shrink={false}
  >
    Topic
  </InputLabel>:""}
              <Select
                sx={{ width: "100%", height: "40px" }}
                name="Topic"
                value={typeSubjectTopic.Topic}
                onChange={handleChange}
              >
                {tlist?.map((type, index) => {
                  return (
                    <MenuItem key={index} value={type?.Id}>
                      {type?.Topic_Text}
                    </MenuItem>
                  );
                })}
              </Select>
              </FormControl>
              <Grid p={1}>
                <Tooltip title="Add Option" placement="right">
                  <ControlPointOutlinedIcon
                    onClick={() => setTopicModal(true)}
                    sx={{
                      backgroundColor: "#3F51B5",
                      color: "#fff",
                      borderRadius: "100%",
                      cursor: "pointer",
                      fontSize: "30px",
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            {renderValidationMessage("topic")}
          </Box>
          <Box
            p={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"start"}
          >
            <Typography fontFamily={POPPINS} fontSize={13}>
              Question Text:*
            </Typography>

            <Box width={"70%"}>
              <JoditEditor
                name="Question"
                value={typeSubjectTopic.Question}
                config={configDescription("Question text")}
                ref={questionEditor}
                onBlur={(newContent) =>
                  handleEditorBlur("Question", newContent)
                }
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            {renderValidationMessage("question")}
          </Box>
          <Grid>
            {typeSubjectTopic.type !== 3 && typeSubjectTopic.type !== 4 && (
              <Grid>
                <Grid sx={{ backgroundColor: "#F8F9F9" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontFamily: POPPINS,
                      fontSize: "1.1rem",
                      paddingLeft: "10px",
                    }}
                    variant="h6"
                  >
                    Options
                  </Typography>
                </Grid>
                <Grid>
                  {options.map(({ id, isChecked, inputValue }, index) => {
                    return (
                      <Grid
                        width={"100%"}
                        padding={1}
                        display={"flex"}
                        justifyContent="space-between"
                        alignItems={"center"}
                        key={id}
                      >
                        <Grid
                          width={"42%"}
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            checked={isChecked}
                            onChange={(e) =>
                              optionsHandleChange(id, e.target.checked)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <Typography fontFamily={POPPINS} fontSize={13}>
                            Correct Option {id}
                          </Typography>
                        </Grid>
                        <Grid
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          width={"100%"}
                        >
                          <Box width={"90%"}>
                            <JoditEditor
                              config={getConfigDescription(id)}
                              value={inputValue}
                              onBlur={(newContent) =>
                                handleEditorChange(id, newContent)
                              }
                            />
                          </Box>

                          <Grid>
                            {index != 0 ? (
                              <Grid
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeHandler(id)}
                              >
                                <DoDisturbOnOutlinedIcon
                                  sx={{
                                    color: "#fff",
                                    backgroundColor: "red",
                                    borderRadius: "100%",
                                  }}
                                />
                              </Grid>
                            ) : (
                              <Box width={"22px"}></Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>

          {typeSubjectTopic.type !== 3 && typeSubjectTopic.type !== 4 && (
            <Grid p={1}>
              <Tooltip title="Add Option" placement="right">
                <ControlPointOutlinedIcon
                  onClick={handleAddOption}
                  sx={{
                    backgroundColor: "#3F51B5",
                    color: "#fff",
                    borderRadius: "100%",
                    cursor: "pointer",
                    fontSize: "30px",
                  }}
                />
              </Tooltip>
            </Grid>
          )}
          <Box display={"flex"} justifyContent={"center"}>
            {renderValidationMessage("options")}
          </Box>
          <Grid>
            {typeSubjectTopic.type === 3 ? (
              <>
                <Box
                  p={1}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontFamily={POPPINS} fontSize={13}>
                    Answer
                  </Typography>
                  <Box width={"70%"}>
                    <TextField
                      value={answer.ans}
                      onChange={handleChangeAnswer}
                      inputProps={{
                        style: { fontSize: 13, fontFamily: POPPINS },
                      }}
                      style={{ backgroundColor: "#fff" }}
                      name="ans"
                      fullWidth
                      placeholder="Answer"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              ""
            )}
            <Grid>
              {/* <Grid
                  width={"100%"}
                  sx={{
                    display:typeSubjectTopic.type === 4? "flex":"none",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Box width={"42%"}></Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    width={"100%"}
                  >
                    <Checkbox
                      checked={answer.isAnswer}
                      onChange={(e) =>
                        setAnswer((prev) => {
                          return {
                            ...prev,
                            isAnswer: e.target.checked,
                            max: e.target.checked ? prev.max : 0,
                            min: e.target.checked ? prev.min : 0,
                          };
                        })
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography fontFamily={POPPINS} fontSize={13}>
                      Answer Range
                    </Typography>
                  </Box>
                </Grid> */}
              {/* {answer.isAnswer && (
                  <Grid>
                    <Box
                      p={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"start"}
                    >
                      <Typography
                        fontFamily={POPPINS}
                        fontSize={13}
                        sx={{ width: "42%", height: "40px" }}
                      >
                        Max:
                      </Typography>
                      <TextField
                        value={answer.max}
                        onChange={handleChangeAnswer}
                        name="max"
                        fullWidth
                        sx={{
                          "& input": {
                            height: "5px",
                          },
                        }}
                        type="number"
                        inputProps={{
                          inputMode: "numeric",
                        }}
                      />
                    </Box>
                    <Box
                      p={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"start"}
                    >
                      <Typography
                        fontFamily={POPPINS}
                        fontSize={13}
                        sx={{ width: "42%", height: "40px" }}
                      >
                        Min:
                      </Typography>
                      <TextField
                        value={answer.min}
                        name="min"
                        onChange={handleChangeAnswer}
                        fullWidth
                        sx={{
                          "& input": {
                            height: "5px",
                          },
                        }}
                        type="number"
                        inputProps={{
                          inputMode: "numeric",
                        }}
                      />
                    </Box>
                  </Grid>
                )} */}
            </Grid>
          </Grid>
          <Box
            p={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"start"}
          >
            <Typography fontFamily={POPPINS} fontSize={13}>
              Explanation:
            </Typography>

            <Box width={"70%"}>
              <JoditEditor
                name="Explanation"
                value={typeSubjectTopic.Explanation}
                config={configDescription("Explanation")}
                ref={explanationEditor}
                onBlur={(newContent) =>
                  handleEditorBlur("Explanation", newContent)
                }
              />
            </Box>
          </Box>

          <Grid p={1} sx={{ border: "1px solid #EBEDEF" }}>
            <Grid>
              <Button
                onClick={() => setIsAdvOption(!isAdvOption)}
                sx={{
                  textTransform: "none",
                  fontFamily: POPPINS,
                  fontSize: 13,
                }}
                variant="text"
              >
                Show Advance Option
              </Button>
            </Grid>
            {isAdvOption && (
              <Grid>
                <Box
                  p={1}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                >
                  <Typography
                    fontFamily={POPPINS}
                    fontSize={13}
                    sx={{ width: "42%", height: "40px" }}
                  >
                    Mark:
                  </Typography>
                  <TextField
                    name="mark"
                    onChange={handleChangeAdvMark}
                    value={advOptionMarks.mark}
                    fullWidth
                    sx={{
                      "& input": {
                        height: "5px",
                      },
                    }}
                    type="number"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                  />
                </Box>
                <Box
                  p={1}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                >
                  <Typography
                    fontFamily={POPPINS}
                    fontSize={13}
                    sx={{ width: "42%", height: "40px" }}
                  >
                    Penalty:
                  </Typography>
                  <TextField
                    fullWidth
                    name="penalty"
                    onChange={handleChangeAdvMark}
                    value={advOptionMarks.penalty}
                    sx={{
                      "& input": {
                        height: "5px",
                      },
                    }}
                    type="number"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                  />
                </Box>
                {typeSubjectTopic.type === 2 && (
                  <Box
                    p={1}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"start"}
                  >
                    <Typography
                      fontFamily={POPPINS}
                      fontSize={13}
                      sx={{ width: "42%", height: "40px" }}
                    >
                      Mark for each correct option , provided NO incorrect
                      option is selected
                    </Typography>
                    <TextField
                      name="eachCorrectOption"
                      value={advOptionMarks.eachCorrectOption}
                      onChange={handleChangeAdvMark}
                      fullWidth
                      sx={{
                        "& input": {
                          height: "5px",
                        },
                      }}
                      type="number"
                      inputProps={{
                        inputMode: "numeric",
                      }}
                    />
                  </Box>
                )}
              </Grid>
            )}
          </Grid>

          <Grid
            p={1}
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <Button
              sx={{ fontFamily: POPPINS, fontSize: 13 }}
              onClick={handleSubmit}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <LayoutModal
          w={"50%"}
          handleModal={subjectModal}
          setHandleModal={setSubjectModal}
        >
          <Grid p={2}>
            <Grid display={"flex"} justifyContent={"end"} alignItems={"center"}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => setSubjectModal(false)}
              >
                <CloseIcon />
              </Box>
            </Grid>
            <Grid>
              <Typography
                p={1}
                fontSize={13}
                fontFamily={POPPINS}
                fontWeight={600}
              >
                Create New Subject:
              </Typography>
              <OutlinedInput
                value={createSubject}
                onChange={handlenewsubjectchange}
                type="text"
                placeholder="Subject"
                sx={{ width: "100%", height: "50px" }}
              />
            </Grid>
            <Grid
              gap={2}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              p={1}
            >
              <Button
                sx={{
                  textTransform: "none",
                  fontFamily: POPPINS,
                  fontSize: 13,
                }}
                onClick={() => setSubjectModal(false)}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                disabled={createSubject.length <= 0}
                sx={{
                  textTransform: "none",
                  fontFamily: POPPINS,
                  fontSize: 13,
                }}
                onClick={handleCreateSubject}
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </LayoutModal>
        {/* this layoutModal for topic */}
        <LayoutModal
          w={"50%"}
          handleModal={topicModal}
          setHandleModal={setTopicModal}
        >
          <Grid p={2}>
            <Grid display={"flex"} justifyContent={"end"} alignItems={"center"}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => setTopicModal(false)}
              >
                <CloseIcon />
              </Box>
            </Grid>
            <Grid>
              <Typography
                p={1}
                fontSize={13}
                fontFamily={POPPINS}
                fontWeight={600}
              >
                Create New Topic:
              </Typography>
              <OutlinedInput
                value={createTopic}
                onChange={(e) => setCreateTopic(e.target.value)}
                type="text"
                placeholder="Topic"
                sx={{ width: "100%", height: "50px" }}
              />
            </Grid>
            <Grid
              gap={2}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              p={1}
            >
              <Button
                sx={{
                  textTransform: "none",
                  fontFamily: POPPINS,
                  fontSize: 13,
                }}
                onClick={() => setTopicModal(false)}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                disabled={createTopic.length <= 0}
                sx={{
                  textTransform: "none",
                  fontFamily: POPPINS,
                  fontSize: 13,
                }}
                onClick={handleCreateTopic}
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </LayoutModal>
      </LayoutModal>
      <SuspenseLoader loading={loading.subjectaddload} />
      <SuspenseLoader loading={loading.topicaddload} />
      <SuspenseLoader loading={loading.submit} />
      <SuspenseLoader loading={loading.getsubjectload} />
      <SuspenseLoader loading={loading.gettypeload} />
      {/* <SuspenseLoader loading={loading.topicload} /> */}
    </>
  );
}

export default Table;
