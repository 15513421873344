import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'

import usersServices from '../api';
import { useDispatch } from 'react-redux';

const initialState = {
 userData:[],
 isLoading:false,
 userlistLoading:false,
 courseenrollall:[],
 statuslist:[],
 courselist:[]
}

export const getuserdata = createAsyncThunk("user/getuserdata", (datas) => {
  try {
    const response =  usersServices.usergetbyRole(datas);
    const data =  response
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
});

export const postuserregister = createAsyncThunk("user/postuserregister", async(datas) => {

    try {
      const response =await  usersServices.postuserregisteration(datas);
      const data =  response
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  });

  export const getenrollstatusandcourse = createAsyncThunk("user/getenrollstatusandcourse", async() => {

    try {
      const response =await  usersServices.getAllEnrollStatusAndCourse()
      const data =  response
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setuserdata: (state,action) => {

      state.courselistimgUrl = action.payload;
    },
    setcourselistenrollall:(state,action)=>{
      state.courseenrollall=action.payload
    },
    setuserdataall:(state,action)=>{
      state.userData=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getuserdata.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getuserdata.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData = action.payload.data.data.searchData;
        state.courseenrollall=action.payload.data.data.courseList
      })
      .addCase(getuserdata.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })  .addCase(postuserregister.pending, (state, action) => {
        state.isLoading = true;
      }) .addCase(postuserregister.fulfilled, (state, action) => {
        state.isLoading = false;
      }).addCase(getenrollstatusandcourse.pending,(state)=>{
        state.userlistLoading=true
      }).addCase(getenrollstatusandcourse.fulfilled, (state, action) => {
        state.userlistLoading = false;
        state.courselist=action.payload.data.data.courseList
        state.statuslist=action.payload.data.data.enrollStatus
      })
  },
})

export const {setcourselisturl,setuserdataall } = userSlice.actions

export default userSlice.reducer