import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import CourseDetailService from '../Api';


const initialState = {
  courseDetails:{
    Id: "",
    Title: "",
    InstructorDisplayName: "",
    Instructor: "",
    CourseTagline: "",
    HowToUse: "",
    code: ""
},
  loading: false,
  error: null,
}

export const getCourseDetails = createAsyncThunk("courseDetails/getCourseDetails", (id) => {
    try {
        const res =  CourseDetailService.getCourseDetail(id)
        
        return res;
      } catch (error) {
        throw new Error(error.message);
      }
});

export const postCourseDetails = createAsyncThunk("courseDetails/postCourseDetails", (data, { rejectWithValue }) => {

  try {
      const res =  CourseDetailService.postDetail(data)
      
      return res;
    } catch (error) {
      throw new Error(error.message);
    }
});




export const createCourseDetailSlice = createSlice({
  name: 'createCourseDetail',
  initialState,
  reducers: {
    setInitialState: (state,action)=>{
        state.courseDetails = initialState.courseDetails
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseDetails.pending, (state) => {
        state.loading = true;
      })
      builder
      .addCase(getCourseDetails.fulfilled, (state,{payload}) => {
        state.loading = false;
        const detail = payload?.data?.data?.courseDetails[0]
        console.log(detail)
        state.courseDetails = detail
      })
      builder
      .addCase(getCourseDetails.rejected, (state) => {
        state.loading = false;
      })

      //postCourseDetails
      builder
      .addCase(postCourseDetails.pending, (state) => {
        
        state.loading=true;
      })
      builder
      .addCase(postCourseDetails.fulfilled, (state,{payload}) => {
        state.loading = false;
       
      })
      builder
      .addCase(postCourseDetails.rejected, (state) => {
        state.loading = false;
        
      })
  },
})

export const {setInitialState} = createCourseDetailSlice.actions

export default createCourseDetailSlice.reducer