import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

import Table from "./table";
import Uploadquestion from "./uploadquestion";

function Header({setPushload}) {

  const [questionmodal,setQuestionmodal]=useState(false)
  const [uploadquestionmodal,setUploadquestionmodal]=useState(false)

const handlenewquestion=()=>{
setQuestionmodal(!questionmodal)
}

const uploadqstn=()=>{
  setUploadquestionmodal(true)
}

  return (
    <Grid
      pl={4}
      pr={4}
      pt={2}
      pb={2}
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{borderBottom: '1px solid #ccc'}}
    >
      <Grid item>
        <Typography fontFamily={POPPINS} fontSize={17} fontWeight={500}>
          Question Bank
        </Typography>
        <Typography
          fontFamily={POPPINS}
          fontSize={12}
          sx={{ p: "3px", color: "#000" }}
        >
          Manage learner assessments and questions
        </Typography>
      </Grid>
      <Grid item alignItems={"center"}>
        <Button
        onClick={uploadqstn}
          variant="outlined"
          sx={{borderColor:"#000","&:hover": {
              backgroundColor: "blue",
              color: "white",
              "& .MuiButton-startIcon, & .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiTypography-root": {
                color: "white",
              },
            },}}
          startIcon={
            <CloudDownloadOutlinedIcon
              sx={{
                color: "#000"
              }}
            />
          }
        >
          <Typography fontFamily={POPPINS} fontSize={13} sx={{ color: "#000" }}>
            Upload Questions
          </Typography>
        </Button>
        <Button
        onClick={handlenewquestion}
          variant="outlined"
          sx={{
            ml: 1,
            color: "#000",
            borderColor: "#000",
            "&:hover": {
              backgroundColor: "blue",
              color: "white",
             "& .MuiButton-startIcon, & .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiTypography-root": {
                color: "white",
              },
            }
          }}
          startIcon={<AddIcon sx={{ color: "#000", color: "#000" }} />}
        >
          <Typography fontFamily={POPPINS} fontSize={13} sx={{ color: "#000" }}>
            New Question
          </Typography>
        </Button>
      </Grid>
      <Table setPushload={setPushload} questionmodal={questionmodal} setQuestionmodal={setQuestionmodal}/>
      <Uploadquestion setPushload={setPushload} uploadquestionmodal={uploadquestionmodal} setUploadquestionmodal={setUploadquestionmodal}/>
    </Grid>
  );
}

export default Header;
