import React from 'react'
import { useLocation } from 'react-router-dom'
import ProfilePic from './components/ProfilePic'
import ProfileDetail from './components/ProfileDetail'
import Appbar from '../../components/atoms/header/header'
import { Box } from '@mui/material'
import PasswordUpdate from './components/PasswordUpdate'
import  Button from '../../components/atoms/buttons/Buttons'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TestSvg from './TestSvg'
function Profile() {
const location = useLocation()//used for take state value in route
const state = location.state //state value store in state variable

const testClick =()=>{
  
}
//it decide what content to run
const PageDecision =({state})=>{

    //it will decide according to conditions based on state comes in props
    if(state === "Change Profile Picture"){
        return<ProfilePic/>
    }else if(state === "Update profile details"){
        return<ProfileDetail/>
    }else if(state === "Change password"){
        return<PasswordUpdate/>
    }else{
        return <></>
    }
}
  return (
    <Box>
        <Appbar title ={state}/>
        <Box >
            <PageDecision  state={state}/>
        
        </Box>
    </Box>
  )
}

export default Profile