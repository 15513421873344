import { Button, Grid, Link, OutlinedInput, Typography } from "@mui/material";
import React, { useState } from "react";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import QuestionBankservice from "../hooks/questionbankservice";
import { SuspenseLoader } from "../../../components/loader/susenseloader";
import LayoutModal from "../../../components/atoms/layoutModal/LayoutModal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

function Excelupload({setFileup,fileup,setPushload,setExpanded,setUploadquestionmodal}) {  
  const [load,setLoad]=useState(false)
  const [modalopen,setModalopen]=useState(false)
  const [report,setReport]=useState({})

  const questionresponsedata=[{
    icons:<HelpCenterIcon/>,details:"Total number of questions",count:report?.TotalNumQuestion
  },{icons:<DoneIcon sx={{color:"green"}}/>,details:"Number of questions successfully insert",count:report?.SucessfullyInsert},{
    icons:<CloseIcon sx={{color:"red"}}/>,details:"Number of questions failed to insert",count:report?.RemainNumberInsert
  }]

  const {
   uploadquestion,
  } = QuestionBankservice()

  const filechange=(file)=>{
    setFileup(file?.target?.files[0])
  }

const uploadxlsx=()=>{
  setLoad(true)
  const data={file:fileup}
  uploadquestion(data).then(res=>{
    if(res?.data?.status===200){
      setReport(res?.data?.report)
      // setUploadquestionmodal(false)
      setPushload((prev) => !prev);
      // setExpanded(null)
      setLoad(false)
      setModalopen(true)
    }else{
      setLoad(false)
      setUploadquestionmodal(false)
      setExpanded(null)
    }
  })
}

const handleclosequestionres=()=>{
  setModalopen(false)
  setUploadquestionmodal(false)
  setExpanded(null)
}

  return (
    <Grid>
      <Grid sx={{ border: 1, height: 130, mt: 2 }}>
        <Typography p={2} fontFamily={POPPINS} fontSize={14} fontWeight={600}>
          Upload Instructions
        </Typography>

        <Typography display={"flex"} pl={2} fontFamily={POPPINS} fontSize={14}>
          1. Download{" "}
          <Link href="https://lmsdemo.mwtconsultancy.com/excel_format/Excel_Question_Format.xlsx" underline="hover" sx={{ color: "blue", display: "flex", alignItems: "center" }}>
  <Typography
    fontFamily="POPPINS"
    fontSize={14}
    ml={1}
  >
    sample question file.
  </Typography>
</Link>
        </Typography>
        <Typography pl={2} fontFamily={POPPINS} fontSize={14} mt={1}>
          2. Add the questions in the same format as shown in the sample
          questions.
        </Typography>
      </Grid>
      <Grid>
        <OutlinedInput
          inputProps={{ accept: ".xlsx" }}
          type="file"
          sx={{
            width: "100%",
            mt: 1,
            backgroundColor: "#fff",
            height: "40px",
            fontFamily: POPPINS,
            fontSize: 13,
          }}
          onChange={filechange}
        />
      </Grid>

      <Grid display={"flex"} justifyContent={"flex-end"}>
        <Button disabled={load||!fileup?true:false} onClick={uploadxlsx} sx={{ mt: 2 }} variant="contained">
          Upload
        </Button>
      </Grid>
      <SuspenseLoader loading={load}/>
      <LayoutModal newfil={"reset"} resetall={handleclosequestionres} w={"40%"} handleModal={modalopen} setHandleModal={setModalopen}>
      <Grid
        p={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          fontSize={16}
          fontFamily={POPPINS}
          fontWeight={600}
        >Import Questions Summary</Typography>
        <Grid onClick={handleclosequestionres} sx={{ cursor: "pointer" }} >
          <CancelOutlinedIcon />
        </Grid>
      </Grid>{questionresponsedata?.map((itm,k)=>(
        <Grid pl={2} pr={2}>
      <hr/>

      <Grid mt={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
<Grid display={"flex"} alignItems={"center"}>{itm?.icons}<Typography ml={2}>{itm?.details}</Typography></Grid>
<Grid display={"flex"} alignItems={"center"} mr={2}>{itm?.count}</Grid>
      </Grid>
      </Grid>
      ))
      
}
<Grid display={"flex"} alignItems={"center"} justifyContent={"flex-end"} mt={2} p={2}>
<Button onClick={handleclosequestionres} variant="outlined" sx={{color:"black"}}><Typography fontFamily={POPPINS} >Close</Typography></Button>
</Grid>
      </LayoutModal>
    </Grid>
  );
}

export default Excelupload;
