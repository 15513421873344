import React, { lazy } from 'react'
const CreateCourse = lazy(() => import("../features/createcourse/index"));

function Createcourse() {
  return (
    <div>
    <CreateCourse/>
    </div>
  )
}

export default Createcourse