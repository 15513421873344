export const TableHeaderandRowdetailes = [
    {
        id: 1,
        name: 'Name',
        rowDetailes:"name",
        
    },
    {
        id: 2,
        name: 'Email',
        rowDetailes:"email",

    },
    {
        id: 3,
        name: 'Courses',
        rowDetailes:"enroll_courses", 
    },
    {
        id: 4,
        name: 'Last Login',
        rowDetailes:"last_login",

    },
    {
        id: 5,
        name: 'Joined On',
        rowDetailes:"joinedDate"
    },
    {
        id: 6,
        name: 'Actions',
        rowDetailes:null

    },
];

export const userstatus=[
    {1:"Active"},{2:"Enrolled"},{3:"Completed"}
]

