import React, { useEffect, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import JoditEditor, { Jodit } from 'jodit-react'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import {POPPINS} from '../../../constants/fontConstantsPoppins'
import { useParams } from 'react-router';
import CourseDetailHooks from '../hooks';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from '../schemas';
import {CircularProgress} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import SuccessSnackbar from '../../../components/molecules/customsnackbar/successSnackbar';
import ErrorSnackbar from '../../../components/molecules/customsnackbar/errorSnackbar';

function CoursesDetailForm() {
const {getCourseDetailHook,setInitialStateForm,setPostDetail,loadingDetail}=CourseDetailHooks()
const {courseDetails} = useSelector((state)=>state.createCourseDetailSlice)
const {id} = useParams()
const navigate = useNavigate()
const [courseDetailsState,setCourseDetailState] = useState({ Id: "",
Title: "",
InstructorDisplayName: "",
Instructor: "",
CourseTagline: "",
HowToUse: "",
code: ""})
const {handleSubmit,register,formState: { errors },setValue} = useForm({
  resolver: yupResolver(schema()),
});

const editor = useRef(null);

const configDescription = useMemo(() => {
  return {
    placeholder: courseDetailsState.HowToUse?"":'Description',
    height: '450px',
    toolbarSticky:false,
     pasteHTMLActionList: Jodit.atom([
      { value: Jodit.constants.INSERT_ONLY_TEXT, text: 'Insert only Text' }
    ])
  };
}, [courseDetails]);
const configCourseTagline = useMemo(() => {
  return {
    placeholder: courseDetailsState.CourseTagline?"":'CourseTagline',
    height: '450px',
    toolbarSticky:false,
    pasteHTMLActionList: Jodit.atom([
      { value: Jodit.constants.INSERT_ONLY_TEXT, text: 'Insert only Text' }
    ])
  };
}, [courseDetails]);

useEffect(()=>{
  getCourseDetailHook(id)
  return()=>{
    setInitialStateForm()
  }
},[id])

useEffect(() => {
  
  setCourseDetailState(courseDetails);
  
  return()=>{
      setCourseDetailState({ Id: "",
      Title: "",
      InstructorDisplayName: "",
      Instructor: "",
      CourseTagline: "",
      HowToUse: "",
      code: ""})
  }
}, [courseDetails]);

useEffect(() => {
  // set default values after calling useForm
  setValue('Title', courseDetailsState.Title);
  setValue('InstructorDisplayName', courseDetailsState.InstructorDisplayName);
  // ... set other default values
}, [courseDetailsState]);

const handleChange = (e)=>{
  const {name,value} = e.target
  setCourseDetailState((prev)=>{
    return {...prev,[name]:value}
  })
}
console.log(courseDetailsState,"courseDetailsState");
const onSubmit = async () => {
 const detail ={
  title:courseDetailsState.Title,
		instructor_display_name:courseDetailsState.InstructorDisplayName,
		instructor:courseDetailsState.Instructor,
		course_tag_line:courseDetailsState.CourseTagline||"",
		howtouse:courseDetailsState.HowToUse||"",
		code:courseDetailsState.code||"",
		course_id:courseDetailsState.Id,
    template_id:1
 }
 setPostDetail(detail).then((res)=>{
      if(res?.data?.status === 200){
        enqueueSnackbar('Updated Course', {
          variant: 'error', 
          content: (key, message) => (
            <SuccessSnackbar id={key} message={message} allowDownload={true} />
          ),
        });
        navigate('/courses')
      }else{
        enqueueSnackbar('Something went wrong', {
          variant: 'error', 
          content: (key, message) => (
            <ErrorSnackbar id={key} message={message} allowDownload={true} />
          ),
        });
      }
 })

};




  return (
   <form onSubmit={handleSubmit(onSubmit)}>
         <Box
        sx={{
      width: "100%",
      height:"100%",
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap:'20px',
      padding:"20px 50px 20px 80px  ",
     
    }}
  >
    <Box style={{display:'flex',flexDirection:'column',gap:"10px"}}>
      <Typography   style={{color:errors?.Title?.message?"red":"#000"}} variant='h6' fontFamily={POPPINS} fontWeight={600} fontSize={"13px"}>Title: *</Typography>
       <TextField inputProps={{style:{fontSize:13,fontFamily:POPPINS}}} style={{backgroundColor:"#fff"}}  {...register("Title")} name='Title' onChange={handleChange} value={courseDetailsState.Title} fullWidth placeholder='Title' id="fullWidth" />
    </Box>
    <Box style={{display:'flex',flexDirection:'column',gap:"10px"}}>
      <Typography fontFamily={POPPINS} fontWeight={600} fontSize={"13px"}>Course Code</Typography>
       <TextField inputProps={{style:{fontSize:13,fontFamily:POPPINS}}} style={{backgroundColor:"#fff"}} name='code' onChange={handleChange} value={courseDetailsState.code} fullWidth placeholder='Course Code' id="fullWidth" />
    </Box> 
    <Box style={{display:'flex',flexDirection:'column',gap:"10px"}}>
      <Typography   style={{color:errors?.InstructorDisplayName?.message?"red":"#000"}}  fontFamily={POPPINS} fontWeight={600} fontSize={"13px"}>Instructor Display Name: *</Typography>
       <TextField inputProps={{style:{fontSize:13,fontFamily:POPPINS}}} style={{backgroundColor:"#fff"}} {...register("InstructorDisplayName")} name='InstructorDisplayName' onChange={handleChange}  value={courseDetailsState.InstructorDisplayName} fullWidth placeholder='Instructor Display Name' id="fullWidth" />
    </Box>
     <Box style={{display:'flex',flexDirection:'column',gap:"10px"}}>
      <Typography fontFamily={POPPINS} fontWeight={600} fontSize={"13px"}>Instructor</Typography>
       <TextField inputProps={{style:{fontSize:13,fontFamily:POPPINS}}} style={{backgroundColor:"#fff"}} name='Instructor' onChange={handleChange} value={courseDetailsState.Instructor} fullWidth placeholder='Instructor' id="fullWidth" />
    </Box>
    <Box style={{display:'flex',flexDirection:'column',gap:"10px"}} >
      <Typography fontFamily={POPPINS} fontWeight={600} fontSize={"13px"}>Description: *</Typography>
   
      <JoditEditor
        ref={editor}
        name="HowToUse"
        value={courseDetailsState.HowToUse}
        config={configDescription }
        onChange={(newContent)=>{ 
          setCourseDetailState((prev)=>{
            return {...prev,HowToUse:newContent.replace(/\s+/g, ' ').trim()}
          })
        }}
      />
   
    </Box>
    <Box style={{display:'flex',flexDirection:'column',gap:"10px"}}>
      <Typography fontFamily={POPPINS} fontWeight={600} fontSize={"13px"}>Course Tagline</Typography>
   
      <JoditEditor
        name="CourseTagline"
        ref={editor}
        value={courseDetailsState.CourseTagline}
        config={configCourseTagline}
        
        onChange={(newContent)=>{ 
          console.log("Before trimming:", newContent);
setCourseDetailState((prev) => {
  const trimmedContent = newContent.replace(/\s+/g, ' ').trim();
  console.log("After trimming:", trimmedContent);
  return { ...prev, CourseTagline: trimmedContent };
});
        }}
        
      />
   
    </Box>
    {/* <div style={{display:'flex',flexDirection:'column',gap:"10px"}}>
      <Typography style={{paddingLeft:'10px'}} fontFamily={POPPINS} fontWeight={600} fontSize={"13px"}>Language: *</Typography>
       <TextField fullWidth placeholder='Language' id="fullWidth" />
    </div> */}
   
      <Box style={{display:'flex',justifyContent:"flex-end",alignItems:'flex-end',paddingBottom:"100px"}}>
    
      <Stack direction="row" spacing={2}>
        <Button type='submit' variant="contained">{loadingDetail?<Stack sx={{ color: '#fff' }} spacing={2} direction="row">
          <CircularProgress size={20} color="inherit" />
        </Stack>:"Save"}</Button>
      </Stack>
      
    </Box>

 
  </Box>
   
   </form>
  )
}

export default CoursesDetailForm