import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

function ImgLoading() {
  return (
   
    <Skeleton  variant="rectangular" width="100%">
    <Box sx={{width:"100px", height:"120px"}} style={{ paddingTop: '57%' }} />
  </Skeleton>

  )
}

export default ImgLoading