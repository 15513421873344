import axiosInstance from '../../../services/axiosservice';
 
const baseURL = process.env.REACT_APP_API_BASE_URL

const updateProfileImage = async (data) => {
    try {
      let postImage = await axiosInstance.post(`${baseURL}/api/updateProfilePicture`,data,{headers: {
        'Content-Type': 'multipart/form-data',
      },});
      if (postImage) {
        return {
          data: postImage.data
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const updateName=async (data)=>{

    try {
      let name = await axiosInstance.post(`${baseURL}/api/updateUserName`,data);
      if (name) {
        return name
      } else {
        return 500;
      }
    } catch (err) {
 
      if(err?.response){
        if (err?.response?.status === 500) {
          return { data: 500 ,message:"server error 500"};
        } else {
          console.error('HTTP error:', err.response.status);
          return false;
        }
      }else if(err.request){
  
        return { data: 600 ,message:"network error"};
      }
    }
  }
  const updatePassword = async (data)=>{
  
    try {
      let res = await axiosInstance.post(`${baseURL}/api/changePassword`,data,{headers: {
        'Content-Type': 'multipart/form-data',
      },});
      if (res) {
        return res.data
      } else {
        return false;
      }
    } catch (err) {
      console.log(err, "error");
    }
  }
 const updateUserDetailsApi = {updateProfileImage,updateName,updatePassword}

 export default updateUserDetailsApi