import React, { useState } from 'react'
import {CircularProgress,Grid, IconButton, OutlinedInput, Typography, Button, Box, Modal,} from "@mui/material";
import { POPPINS } from '../../../constants/fontConstantsPoppins';
import CloseCircle from '../../../assets/svgimages/closecircle';


function LayoutModal({handleModal,setHandleModal,children,w,h, newfil,resetall}) {

/*
handleModal = it's variable(boolean) for set modal open close
setHandleModal = it's  function(useStateFunction) for set modal close
children =  it's used to display content
w= width
h= height
*/ 


    //close modal
    const handleModalClose = () => {
        if(setHandleModal){
          if(newfil==="reset"){
            setHandleModal(false);
            resetall()
          }
          setHandleModal(false);

        }else{
          console.log("pass props setHandleModal")
        }
      };


      


  return (
    <Modal
        open={handleModal}
        onClose={handleModalClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            width: w?w:"fit-content",
            height: h?h:"auto",
            outline: "none",
            borderRadius: 4,
            
          }}
        >
                {children}
        </Box>
      </Modal>
  )
}

export default LayoutModal