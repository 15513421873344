import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Typography,
  TextField,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schema } from '../schemas';
import { POPPINS } from '../../../constants/fontConstantsPoppins';
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from 'react-router-dom';
import { getCookie } from '../../../helpers/CookieHelpers';
import UserServices from '../hooks';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from 'notistack';
import SuccessSnackbar from '../../../components/molecules/customsnackbar/successSnackbar';
import ErrorSnackbar from '../../../components/molecules/customsnackbar/errorSnackbar';
import CloseCircle from '../../../assets/svgimages/closecircle';

function Headers() {
  const [open, setOpen] = useState(false);
  const { register, handleSubmit,reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const {isLoading,postuserregisters,getuserdatas}=UserServices()
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const location = useLocation();
  const id = getCookie("user")
  const { enqueueSnackbar } = useSnackbar();

  const { pathname } = location;
  const handleOpen = () => {
    setOpen(true);
    reset()
  };

  const handleClose = () => {
    setOpen(false);
    reset()
  };

  const handleAdd = (data) => {
    // Handle form submission
    const datas={role_id:1,name:data.name,email:data.email,mobile:parseInt(data.mobile),password:data.password,notify_flag:checked?1:0}
    postuserregisters(datas).then(res=>{
        if(res?.payload?.data?.status===200){
          if(res?.payload?.data?.mailSentStatus===0){
            enqueueSnackbar("User Created", {
              variant: "success",
              content: (key, message) => (
                <SuccessSnackbar id={key} message={message} allowDownload={true} />
              ),
            });
          }else{
            enqueueSnackbar("Mail Sended", {
              variant: "success",
              content: (key, message) => (
                <SuccessSnackbar id={key} message={message} allowDownload={true} />
              ),
            });
          }
          reset()
            handleClose()
            getuserdatas({
              course: [],
              date:{from:"",to:""},
              searchname:"",
              status:""
            })
        }else if(res?.payload?.data?.status===506||res?.payload?.data?.status===505){
          reset()
          enqueueSnackbar("updated", {
            variant: "success",
            content: (key, message) => (
              <ErrorSnackbar id={key} message={res?.payload?.data?.message} allowDownload={true} />
            ),
          });
        }
  
    })
  };


const title=()=>{
    switch(pathname){
case "/users/learners":return "Learners"
case "/users/students":return "Students"
    }
}

const titlebtncontainer=()=>{
    switch(pathname){
        case "/users/learners":return (
            <Button
            startIcon={<AddIcon />}
            onClick={handleOpen}
            variant="contained"
            sx={{ textTransform: 'none' }}
          >
            Add
          </Button>
        )
        case "/users/students":return null
            }
   
}
  return (
    <Grid
      xs={12}
      container
      display={'flex'}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: '100%',
        height: '80px',
        borderBottom: '1px solid #ccc',
      }}
    >
    {
      <Typography pl={4} fontSize={21} fontWeight={500} fontFamily={POPPINS}>
      {title()}
      </Typography>
    }
     
      <Grid mr={4}>
      {titlebtncontainer()}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              backgroundColor: '#fff',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
              pl: 4,
              pt: 2,
              pr: 4,
              pb: 2,
              minWidth: '500px',
            }}
          >
            <form onSubmit={handleSubmit(handleAdd)}>
              <Grid container direction="column" spacing={1}>
                <Grid item container justifyContent="space-between" alignItems={"center"}>
                  <Typography fontFamily={POPPINS} fontWeight={500} fontSize={20} variant="h5" gutterBottom id="modal-title">
                    Learners
                  </Typography>
                  <Button onClick={handleClose}><CloseCircle /></Button>
                </Grid>
                <Grid item>
                  <Typography fontFamily={POPPINS} fontSize={14} fontWeight={500} variant="body1" id="modal-description">
                    Name*
                  </Typography>
                  <TextField
                   sx={{mt:0.5}}
                    placeholder="Name"
                    {...register('name')} 
                    error={!!errors.name} 
                    helperText={errors.name?.message} 
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Typography fontFamily={POPPINS} fontSize={14} fontWeight={500} variant="body1" id="modal-description">
                    Email*
                  </Typography>
                  <TextField
                   sx={{mt:0.5}}
                    placeholder="Email"
                    {...register('email')} 
                    error={!!errors.email} 
                    helperText={errors.email?.message} 
                    fullWidth
                    autoComplete='off'
                  />
                </Grid>
                <Grid item>
                  <Typography fontFamily={POPPINS} fontSize={14} fontWeight={500} variant="body1" id="modal-description">
                    Mobile*
                  </Typography>
                  <TextField
                  sx={{mt:0.5}}
                    placeholder="Mobile"
                    {...register('mobile')} 
                    error={!!errors.mobile} 
                    helperText={errors.mobile?.message} 
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Typography fontFamily={POPPINS} fontSize={14} fontWeight={500} variant="body1" id="modal-description">
                    Password*
                  </Typography>
                  <TextField
                   sx={{mt:0.5}}
                    placeholder="Password"
                    {...register('password')} 
                    error={!!errors.password} 
                    helperText={errors.password?.message} 
                    type="password"
                    fullWidth
                    autoComplete='off'
                  />
                </Grid>

               <Grid mt={2} container justifyContent={"space-between"}>
                <Grid item>
                  <Box pl={2}>
                    <Typography fontFamily={POPPINS} fontSize={13} fontWeight={600}>Send email to user</Typography>
                    <Typography fontFamily={POPPINS} fontSize={13}>Notify user about account creation as learner</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Checkbox size='20px'
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Grid>
              </Grid>
                <Grid item container justifyContent="flex-end">
                {isLoading ? (
                  <Button  type="submit" variant="contained" disabled>
                    <CircularProgress  size={25}/>
                  </Button>
                ) : (
                  <Button sx={{textTransform:"none"}} type="submit" variant="contained">
                    Add
                  </Button>
                )}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
}

export default Headers;

 