import { redirect } from 'react-router-dom';
import axiosInstance from '../../../services/axiosservice';
import { removeCookie } from '../../../helpers/CookieHelpers';
 
const baseURL = process.env.REACT_APP_API_BASE_URL

const userDetailApi = async (id)=>{
    try {
        const res = await axiosInstance.get(`${baseURL}/api/getUser/${id}`)
        return res
    } catch (err) {
      return err
    }
}


const userDetail ={userDetailApi}

export default userDetail