import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  TextField,
  Typography,Chip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { POPPINS } from "../../constants/fontConstantsPoppins";
import Headers from "./components/headers";
import Table from "./components/table";
import UserServices from "./hooks";
import Select from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { Skeleton } from '@mui/material';
import {formatDate} from '../../functionalities/datefunc'


function Index() {
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchnameemail, setSearchnameemail] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [storedateto, setStoredateto] = React.useState("");
  const [storedatefrom, setStoredatefrom] = React.useState("");
  const [selectedOption, setSelectedOption] = useState("Between");
const [searchnames,setSearchnames]=useState("")
const [selectOpen, setSelectOpen] = useState(false);

  const location = useLocation();
  const { state } = location;

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setStoredatefrom(null)
      setStoredateto(null)
   
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let formattedRange;
  if (selectedDate?.from && selectedDate?.to) {
      formattedRange = `${selectedDate?.from} - ${selectedDate?.to}`;
  } else {
      formattedRange = null;
  }
  const handlechangejoinedondate = (e, date) => {
    if (selectedOption === "Between") {
      if (date === "to") {
        setStoredateto(e.target.value);
      } else {
        setStoredatefrom(e.target.value);
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const {userData,getstatusandcourse,statuslist,courselist,userlistLoading } = UserServices();
  const handleAutocompleteChange = (event, value) => {
    const updatedData = value?.map(item => {
      if (item) {
        const { Id, ...rest } = item; 
        return { id: Id, ...rest }; 
      } else {
        return null;
      }
    }).filter(Boolean); // Remove any null values from the array
  
    setSelectedValues(updatedData??null);
  };
  const userDataArray = Object.values(userData);
  const sanitizedUserDataArray = userDataArray?.map(user => {
    const { role_id, last_login, UserID,Id,EnrolmentStatusID, ...sanitizedUser } = user;
    return sanitizedUser;
  });
  
  const handleDownloadExcel = () => {
    const fileName = "table_data.xlsx";
    const wsData = sanitizedUserDataArray?.map(user => {
      const { joinedDate, ...rest } = user; // Destructure joinedDate and capture the rest of the properties
      return {
        ...rest, // Spread the rest of the properties
        enroll_courses: (user.enroll_courses || []).map(itm => itm.courseName).join(","),
        Joined_date: formatDate(user.joinedDate)
      };
    });
    
    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  };

  useEffect(() => {
  
        const updatedData = [state]?.map(item => {
          if(item){
            const { Id, ...rest } = item; 
            return { id: Id, ...rest }; 
          }else{
            return null
          }
          }).filter(Boolean);
       setTimeout(()=>{
        setSelectedValues(updatedData);
       },1000)
  }, []);

  useEffect(()=>{
getstatusandcourse()
  },[])

  const [status, setStatus] = React.useState("");

  const handleChangestatus = (event) => {
    setStatus(event.target.value);
  };

 
  const handlesearchnameemail = (event) => {
    setSearchnameemail(event.target.value);
    setTimeout(()=>{
setSearchnames(event.target.value)
    },500)
  }

  const handleapply = () => {
    const dateformat={
          from: storedatefrom,
        to: storedateto
        
    }
    setSelectedDate(dateformat);
    handleClose();
  };

  const resetfilterdisable = () => {
    if (
      selectedValues?.length !== 0 ||
      searchnameemail !== "" ||
      selectedDate !== null ||
      status !==""
    ) {
      return "flex";
    } else {
      return "none";
    }
  };

  

  const handlereset = () => {
    setSelectedDate(null);
    setStoredateto(null);
    setStoredatefrom(null);
    setSelectedValues([])
    handleClose();
  };
  
  const handleresetstatus=()=>{

    setStatus("")
    setSelectOpen(false);
  }

  const resetall = () => {
    setSelectedDate(null);
    setSelectedOption("Between");
    setSelectedValues([]);
    setSearchnameemail("");
    setSearchnames("");
    setStatus("")
  };

  // useEffect(() => {
  //   setAllval(userData);
  // }, [isLoading]);

  // useEffect(() => {
  //   getfilterval();
  // }, [selectedValues, searchnameemail, selectedDate]);

  // const getfilterval = () => {
  //   let filteredData = allval;

  //   if (selectedValues.length > 0) {
  //     filteredData = filteredData?.filter((course) => {
  //       return course?.enroll_courses?.some((courses) => {
  //         return selectedValues?.some((condition) => {
  //           return courses.CourseID === condition.Id;
  //         });
  //       });
  //     });
  //   }

  //   if (searchnameemail.trim() !== "") {
  //     filteredData = filteredData.filter((user) => {
  //       return (
  //         user.name.toLowerCase().includes(searchnameemail.toLowerCase()) ||
  //         user.email.toLowerCase().includes(searchnameemail.toLowerCase())
  //       );
  //     });
  //   }

  //   if (selectedDate) {
  //     filteredData = filteredData.filter((user) => {
  //       if (selectedOption === "On") {
  //         return (
  //           user.join_date && user.join_date.split(" ")[0] === selectedDate
  //         );
  //       } else {
  //         return (
  //           user.last_login && user.last_login.split(" ")[0] === selectedDate
  //         );
  //       }
  //     });
  //   }

  //   dispatch(setuserdataall(filteredData));
  // };

  const datefield = () => {
    if (selectedOption === "Between") {
      return (
        <>
          <label style={{ fontFamily: POPPINS, fontSize: 12, fontWeight: 600,marginRight:1 }}>
            From: &nbsp;
          </label>
          <TextField
            InputProps={{
              style: { fontFamily: POPPINS, fontSize: 12 },
              inputProps: { min: storedateto },
            }}
            sx={{ width: 190,mr:1 }}
            onChange={(e) => {
              handlechangejoinedondate(e, "from");
            }}
            type="date"
          />
          <label style={{ fontFamily: POPPINS, fontSize: 12, fontWeight: 600,marginLeft:1 }}>
            To: &nbsp;
          </label>
          <TextField
            InputProps={{
              style: { fontFamily: POPPINS, fontSize: 12 },
              inputProps: { min: storedatefrom },
            }}
            sx={{ width: 190,mr:1}}
            onChange={(e) => {
              handlechangejoinedondate(e, "to");
            }}
            type="date"
          />
        </>
      );
    }
  };

  return (
    <Grid container>
    <Headers />
    <Grid
      item
      container
      xs={12}
      pl={4}
      pr={4}
      mt={5}
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item xs={12} sm={6} md={2.5}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography width={120} fontFamily={POPPINS} fontWeight={500} fontSize={13}>
            Filter by:{""}
          </Typography>
          {!userlistLoading?<Autocomplete
            multiple
            limitTags={2}
            options={courselist}
            value={selectedValues}
            onChange={handleAutocompleteChange}
            isOptionEqualToValue={(option, val) => option.Id === val.id}
            getOptionLabel={(option) => option.Title}
            renderInput={(params) => (
              <TextField
                {...params}
                id="autocomplete-input"
                placeholder="Courses"
                sx={{
                  fontFamily: POPPINS,
                  borderRadius:2,
                  fontSize: 12,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "1px solid #757575",
                    borderRadius:2,

                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "1px solid #757575",
                    },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "1px solid #757575",
                    },
                    "& input::placeholder": { // Apply styles to the placeholder text
                      fontSize: 12, // Adjust the font size here
                      fontFamily: POPPINS,
                    },
                }}
                InputLabelProps={{
                  style: { fontFamily: POPPINS, fontSize: 12 },
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.Title}
                  {...getTagProps({ index })}
                  sx={{
                    fontFamily: POPPINS,
                    fontSize: 12,
                    maxHeight: "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginRight: "5px", 
                    width:"100px"
                  }}
                />
              ))
            }
            sx={{
              width: "300px",
              borderRadius:2,
              maxHeight: "auto",
              backgroundColor: "#fff",
              ml: 1,
              fontFamily: POPPINS,
            }}
            
          />: <Skeleton variant="rectangular" width={180} height={10} animation="wave" />}
        </Box>
      </Grid>
      <Grid item xs={8} sm={6} md={2}>
      {!userlistLoading?
        <FormControl fullWidth>
          {/* <InputLabel
            sx={{ fontFamily: POPPINS, fontSize: 12 }}
            id="demo-simple-select-label"
          >
            Status
          </InputLabel> */}
          <Select
           
            id="demo-simple-select"
            value={status}
          //  placeholder="Status"
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography style={{opacity:"0.4",fontFamily:POPPINS,fontSize:12}}>Status</Typography>;
            }
            
            if(selected === 1){
              return "enroled";
            }else if(selected === 2){
                return "in-progress"
            }else if(selected === 3){
                return "completed"
            }
            
          }}
           displayEmpty
            getOptionLabel={(option) => option.Status}
            open={selectOpen} // Set the open state of the select
            onOpen={() => setSelectOpen(true)} // Open the select
            onClose={() => setSelectOpen(false)} // Close the select
            onChange={handleChangestatus}
            sx={{
              fontFamily: POPPINS,
              height:"56px",
              borderRadius:2,
              fontSize: 12,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "1px solid #757575",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "1px solid #757575", 
              },
              
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "1px solid #757575", 
              },
              backgroundColor: "#fff",
            }}
          >
            {statuslist?.map(itm=>(
              <MenuItem value={itm?.Id}>{itm?.Status}</MenuItem>

            ))
            }
            {status?  <Grid display={"flex"} justifyContent={"center"}>
            <Button
            onClick={handleresetstatus}
            sx={{ backgroundColor: "white", border: 1,alignSelf:"center" }}
          >
            <Typography textTransform={"none"}>
              Reset Filter
            </Typography>
          </Button>
            </Grid>:""}
          
         
          </Select>
        </FormControl>
          :<Skeleton variant="rectangular" width={180} height={10} animation="wave" />}
      </Grid>
      <Grid item xs={8} sm={6} md={2}>
      <Box>
      <Box  
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ minWidth: "auto" }}
      >{!userlistLoading?
        <TextField
        sx={{
          backgroundColor: "#fff",
          height:"56px",
          border: status ? "transparent" : "none",
          borderRadius:2,
          width: "auto",
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "1px solid #757575",
              borderRadius:2,

            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor:"1px solid #757575",
              borderRadius:2,

            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor:"1px solid #757575",
            borderRadius:2,

          },
          "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "1px solid #757575", 
            borderRadius:2,

          },
            
        }}
        onClick={(e) => {
            e.preventDefault();
            handleClick(e);
         
        }}
        value={formattedRange ? formattedRange : ""}
      placeholder="Date Joined"
        InputLabelProps={{
          style: { fontFamily: POPPINS, fontSize: 12 },
        }}
        InputProps={{
          style: { height: 56,fontFamily: POPPINS, fontSize: 12,              borderRadius:2,
          },
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={
                  handleClick
                
                }>
                <ArrowDropDownIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />:<Skeleton variant="rectangular" width={180} height={10} animation="wave" />
      }
       
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box
            width={"auto"}
            p={2}
            height={"24vh"}
            flexDirection={"column"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
          
            <Box
              mt={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
            >
              {datefield()}
            </Box>
            <Box mt={3} pl={1} pr={1} display={"flex"} gap={2}>
              <Button
                onClick={handlereset}
                sx={{ backgroundColor: "white", border: 1,display:selectedDate?"block":"none" }}
              >
                <Typography textTransform={"none"}>
                  Reset Filter
                </Typography>
              </Button>
              <Button               disabled={storedatefrom&&storedateto?false:true}
              onClick={handleapply} variant="contained">
                <Typography textTransform={"none"}>Apply</Typography>
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
    
      </Grid>
      <Grid item xs={8} sm={6} md={3.5}>
        {!userlistLoading?<TextField
        placeholder={"Search"}
          value={searchnameemail}
          InputLabelProps={{
            style: { fontFamily: POPPINS, fontSize: 12 },
          }}
          InputProps={{
            style: {
              fontSize: 12,
              fontFamily: POPPINS,
              height: 55,
              borderColor: "red",
            },
          }}
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            borderRadius:2,
            height:"55px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "1px solid #757575",

              borderRadius:2,

            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "1px solid #757575",
                borderRadius:2,

              },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "1px solid #757575",
                borderRadius:2,

              },
          }}
          onChange={handlesearchnameemail}
         
        />:<Skeleton variant="rectangular" width={180} height={10} animation="wave" />}
      </Grid>
      <Grid
      item
      xs={12}
      sm={6}
      md={2}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Button onClick={resetall} sx={{ textTransform: "none", mr: 1,display:resetfilterdisable }}>
        <Typography fontFamily={POPPINS} fontWeight={600} fontSize={13}>
          Reset Filter
        </Typography>
      </Button>
      {userDataArray.length>0?<Button sx={{ textTransform: "none", backgroundColor: "#03FF84", height: 43 }} onClick={handleDownloadExcel}>
        <Typography fontSize={12} fontfamily={POPPINS} sx={{ color: "#000" }}>
          Download Excel
        </Typography>
      </Button>:""}
    </Grid>
    </Grid>
    <Table selectedDate={selectedDate} selectedValues={selectedValues} status={status} searchnameemail={searchnames} />
  </Grid>
  );
}

export default Index;
