import axiosInstance from '../../../services/axiosservice';
const baseURL = process.env.REACT_APP_API_BASE_URL

const getReportDetailApi = async ()=>{
    try {
        let res = await axiosInstance.post(`${baseURL}/api/adminDashboardData`,{})
        if(res){
            return res
        }else {
            return {
              data: 500
            };
          }
    } catch (err) {
      console.log(err,"errr");
        if(err?.response){
          if (err?.response?.status === 500) {
            return { data: 500 ,message:"server error 500"};
          } else {
            console.error('HTTP error:', err.response.status);
            return false;
          }
        }else if(err.request){
          return { data: 600 ,message:"network error"};
        }
      
      }
} 



const ReportApi = {getReportDetailApi}

export default ReportApi


