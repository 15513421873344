import React, { useEffect, useState } from 'react'
import MyTable from '../../../components/molecules/tables/usertable'
import { Grid } from '@mui/material';
import UserServices from '../hooks';
import { getCookie } from '../../../helpers/CookieHelpers';
import SkeletonRows from '../../../components/atoms/skeleton/skeletontable';
import { useNavigate } from 'react-router-dom';
import { TableHeaderandRowdetailes } from '../enum';
import { useSnackbar } from 'notistack';
import SuccessSnackbar from '../../../components/molecules/customsnackbar/successSnackbar';
import ErrorSnackbar from '../../../components/molecules/customsnackbar/errorSnackbar';

function Table({selectedDate,selectedValues,status,searchnameemail}) {
      const {getuserdatas,isLoading,userData,deleteuserTable}=UserServices()
const [loader,setLoader]=useState(false)
const [isModalOpen, setIsModalOpen] = useState(false);

      const id = getCookie("user")

const { enqueueSnackbar } = useSnackbar();

const handleDeleteConfirm = (deleteid) => {
  let datas={id:deleteid}
  setLoader(true)
  deleteuserTable(datas).then(res=>{
    if(res?.data?.status===200){
      getuserdatas({
        course: selectedValues??[{}],
        date: selectedDate??{from:"",to:""},
        searchname: searchnameemail??"",
        status: status??""
      })
            setLoader(false)
      enqueueSnackbar("Deleted", {
        variant: "success",
        content: (key, message) => (
          <SuccessSnackbar id={key} message={message} allowDownload={true} />
        ),
      });
      setIsModalOpen(false);
    }
  })
};

      useEffect(()=>{
        getuserdatas({
          course: selectedValues??[{}],
          date: selectedDate??{from:"",to:""},
          searchname: searchnameemail??"",
          status: status??""
        })
      },[selectedDate,selectedValues,searchnameemail,status])

      const navigate=useNavigate()

      const userDataArray = Object.values(userData);

  return (
    <Grid container pl={4} pr={4} mt={5}>
    {isLoading?
    <SkeletonRows count={5}/>:
    <MyTable deleterow={(deleterowid)=>handleDeleteConfirm(deleterowid)} 
    setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} loader={loader} onpress={(id) => navigate(`/userdetails/${id}`)} 
    tableHeadrowval={TableHeaderandRowdetailes} data={
      // userData
      userDataArray
      // [{name:"user"}]
    }/>
    }
    </Grid>
  )
}

export default Table