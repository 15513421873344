import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { POPPINS } from '../../../constants/fontConstantsPoppins';

import formatDuration from '../functionility';
import Skeleton from '@mui/material/Skeleton';

function All({users,time,isLoading,highestEnrollCourse}) {
  let {hours,minutes} = formatDuration(time)

  const reportdata = [
    {
      title: 'Active users',
      users: users? users : "0",
      prescription: 'The current number of active users.',
    },
    {
      title: 'Total watched',
      hour: hours,
      minute: minutes,
      prescription: 'The overall count of hours.',
    },
    {
      title: 'Most used course',
      users:highestEnrollCourse ? highestEnrollCourse : "Nan",
      prescription: 'The currently most used course.',
    },
  ];

  return (
    <>
      <Grid sx={{
         
        display: "flex",
        flexDirection: "row",
        gap:"20px",
        paddingLeft:"20px",
        '@media (max-width: 840px)': {
          paddingLeft:"30px",
          gap:"5px",
      },
        
        
      }} container   >
        {/* xs={12} md={3} */}
        {reportdata.map((report, index) => (
          <Grid item key={index} >
            <Box
              height="220px"
              width={report.title === "Most used course" ? "290px" : "280px"}
              sx={{
                border: "1px solid var(--All-stroke, rgba(0, 0, 0, 0.24))",
                borderRadius: 7,
                '&:hover': {
                  backgroundColor: '#172935',
                  color: 'white',
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '10px',
                flexWrap:"wrap"
              }}
            >
              <Box >
                <Typography fontFamily={POPPINS} mt={2} fontWeight={300}>
                 {isLoading ?"":  report.title}
                </Typography>
                {report.title === "Most used course" ? isLoading?"":<Box height={30}></Box> : ""}
                <Typography
                  fontFamily={POPPINS}
                  fontSize={isLoading?"17px":report.title === "Most used course" ? 28: 60}
                  fontWeight={600}
                  sx={{width:"210px",overflow: 'hidden',     textOverflow: 'ellipsis',     whiteSpace: 'nowrap',marginBottom:report.title === "Most used course"?"14px":0}}
                >
                  {isLoading ? <Skeleton width={200} height={20} animation="wave" /> :(<Tooltip title={report.users || report.hour} enterDelay={500}>{report.users || report.hour}</Tooltip>)}
                  <span style={{ fontFamily: POPPINS, fontSize: 13 }}>{isLoading?<Skeleton width={200} height={20} animation="wave" />:report.hour>=0 ? "Hr" : ""}</span>
                  {isLoading ?  <Skeleton width={200} height={20} animation="wave" /> : (<>{report.minute}</>)}
                  <span style={{ fontFamily: POPPINS, fontSize: 13 }}>{isLoading?<Skeleton width={200} height={20} animation="wave" />:report.minute>=0 ? "Min" : ""}</span>
                </Typography>
               
                <Typography
                  fontFamily={POPPINS}
                  fontSize={12}
                  fontWeight={300}
                  
                >
                  {isLoading?"":report.prescription}
                </Typography>
              </Box>
              {/* <Box p={2} sx={{ marginTop: '10px', alignSelf: 'flex-start' }}>
                <Box
                  border={1}
                  padding={'5px'}
                  paddingLeft={2}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#57FF68', // Change color on hover
                      color: '#000',
                    },
                  }}
                  paddingRight={2}
                  borderRadius={5}
                >
                  <Typography fontFamily={POPPINS} fontSize={13}>
                    See details
                  </Typography>
                </Box>
              </Box> */}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid mt={3} width={"100%"} padding={"0px 65px 0px 35px"} container justifyContent={"space-between"}>
      <Box><Typography fontSize={13} fontFamily={POPPINS} fontWeight={600}>Monthly enrolled users</Typography></Box>
      {/* <Box><Typography fontSize={13} fontFamily={POPPINS}>Updated 23 minutes ago</Typography></Box> */}
      </Grid>
      <Grid container item xs={12} md={8} flexDirection="column" width={"100%"} >
        <Box  width={"100%"} >
          {/* <BarChartComponent /> */}
        </Box>
      </Grid>
    </>
  );
}

export default All;
