import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Tooltip,
  Modal,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Menu,
  Box
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import { isDate } from "./datefunc";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function MyTable({
  data,
  onpress,
  tableHeadrowval,
  deleterow,
  setIsModalOpen,
  isModalOpen,
  loader,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  // Event handlers for row hover
  const handleRowMouseEnter = (rowId) => {
    setHoveredRow(rowId);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleDeleteButtonClick = (rowId) => {
    setDeleteItemId(rowId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleMenuOpen = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setHoveredRow(rowId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setHoveredRow(null);
  };

  return (
    <>
      <TableContainer sx={{boxShadow:"none",borderRadius:2}} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadrowval?.map((itm, k) => (
                <React.Fragment>
                  <TableCell key={k}>
                    <Typography
                      fontFamily={POPPINS}
                      fontSize={14}
                      fontWeight={500}
                    >
                      {itm?.name}
                    </Typography>
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={tableHeadrowval.length}>
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography fontFamily={POPPINS} fontSize={14}>
                      No data are available
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row,index) => (
                <TableRow
                  key={row.UserID}
                  onMouseEnter={() => handleRowMouseEnter(row.UserID)}
                  onMouseLeave={handleRowMouseLeave}
                  sx={{
                    backgroundColor:
                    index % 2 === 0 ? "#f5f5f5" : "transparent",
                    cursor: "pointer",
                  }}
                >
                  {tableHeadrowval?.map((itm) =>
                    itm?.rowDetailes ? (
                      <TableCell>
                      {row[itm?.rowDetailes]?.length>0&&Array.isArray(row[itm?.rowDetailes])?(
                      row[itm?.rowDetailes]?.slice(0, 2).map(itm=>{
                        return(<>
                          <Typography fontFamily={POPPINS} fontSize={12}>{itm.courseName??"--"}</Typography>
                      
                        </>
                        )
                      })
                    
                      ):(
                        <Typography fontFamily={POPPINS} fontSize={12}>
                     
                          {row[itm?.rowDetailes]?.length <= 0 ? 
                            "--" :
                            isDate(row[itm?.rowDetailes]) ? 
                              
                            row[itm?.rowDetailes] ?.split(" ")[0]?.split("-")[2] +  "-"  +
                            row[itm?.rowDetailes] ?.split(" ")[0]?.split("-")[1] +  "-"  +
                            row[itm?.rowDetailes] ?.split(" ")[0]?.split("-")[0]
                              ?? "--" :
                              itm.rowDetailes === "name" ?row[itm?.rowDetailes]??"--":
                              itm.rowDetailes === "email" ?row[itm?.rowDetailes]??"--":
                              row[itm?.rowDetailes] ??itm.rowDetailes === "enroll_courses" ?row?.Title??row[itm?.rowDetailes]??"--":"--"
                          }
                        </Typography>
                      )
                      }
           {itm.rowDetailes === "enroll_courses" &&row[itm?.rowDetailes]?.length>2?
           <Grid  onClick={(e)=>handleMenuOpen(e,row.UserID)}
            display={"flex"} alignItems={"center"} >
           <Typography width={70}                           
             fontFamily={POPPINS} color={"blue"} fontSize={12} sx={{cursor:"pointer"}}
            >
                View More
            </Typography> <Box display={"flex"} alignItems={"center"} mr={5}>
            <KeyboardArrowDownIcon/></Box> </Grid> :""
           }
                    
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => onpress(row?.UserID)}
                            style={{
                              color:
                                hoveredRow === row.UserID
                                  ? "#000"
                                  : "transparent",
                            }}
                          >
                            <Edit sx={{ color:
                              hoveredRow === row.UserID
                                ? "blue"
                                : "transparent"}} />
                          </IconButton>
                        </Tooltip>
                        {row?.enroll_courses&&!row?.enroll_courses?.length>0 ? (
                          <>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() =>
                                  handleDeleteButtonClick(row?.UserID)
                                }
                                style={{
                                  color:
                                    hoveredRow === row.UserID
                                      ? "#000"
                                      : "transparent",
                                }}
                              >
                                <Delete sx={{ color:
                                  hoveredRow === row.UserID
                                    ? "red"
                                    : "transparent",}} />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : (
                          ""
                        )}
                        <Menu
                          id={`long-menu-${row.UserID}`}
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl && hoveredRow === row.UserID)}
                          onClose={handleMenuClose}
                        >
                          <Grid
                            display={"flex"}
                            flexDirection="column"
                            width={"auto"}
                            height={"auto"}
                            onMouseLeave={handleMenuClose} 

                          >
                            {row?.Title ? (
                              row?.enroll_courses?.map((itm, k) => (
                                <Typography
                                  onClick={() =>
                                    navigate(`/userdetails/${row.UserID}`, {
                                      state: { fromHome: true },
                                    })
                                  }
                                  sx={{
                                    pr: 2,
                                    pl: 2,
                                    ":hover": {
                                      backgroundColor: "lightblue", // Change color when hovering
                                    },
                                  }}
                                  key={itm?.CourseID}
                                >
                                  {itm?.courseName}
                                </Typography>
                              ))
                            ) : (
                              <Typography sx={{ p: 1 }}>
                                {row?.Title ?? "No courses enrolled"}
                              </Typography>
                            )}
                          </Grid>
                        </Menu>
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 75]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Paper
          style={{
            position: "absolute",
            width: 400,
            backgroundColor: "white",
            padding: 20,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography fontSize={14} fontFamily={POPPINS} variant="h6">
            Are you sure you want to delete this item?
          </Typography>
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Button
              onClick={handleModalClose}
              style={{ marginRight: 10 }}
              variant="outlined"
              sx={{ color: "#000" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deleterow(deleteItemId)}
              variant="contained"
              color="primary"
            >
              {loader ? <CircularProgress sx={{color:"#fff"}} size={26} /> : "Delete"}
            </Button>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}

export default MyTable;
