import { Box, Typography} from '@mui/material'
import React from 'react'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModalComponent from './Modal'
import { useState } from 'react';

import SaveButton from './SaveButton';
import updateUserDetailsApi from '../api';
import ProfileDetailHook from '../hooks';
import SuccessSnackbar from '../../../components/molecules/customsnackbar/successSnackbar';
import { enqueueSnackbar } from 'notistack';
import SkeletonLoading from './Skeleton';
import ErrorSnackbar from '../../../components/molecules/customsnackbar/errorSnackbar';



function ProfilePic() {
  const {image_url,isLoading,name,updateProfilePicHook} = ProfileDetailHook()
  const [profileModal, setProfileModal] = useState(false);
  const [courseImg, setCourseImg] = useState(false);
  const [loader,setLoader] = useState(false)
  const [error,setError] = useState(false)
  const [imgUrl,setImgUrl] =useState('')
  const [anim,setAnim] =useState(false)
  const imgRender = imgUrl ? imgUrl : image_url
 
  
  const handleSave =async ()=>{
    if(imgUrl && courseImg){
      setLoader(true)
     const res = await updateUserDetailsApi.updateProfileImage({profile_image_url:courseImg})
     if(res?.data?.status === 200){
       updateProfilePicHook(res?.data?.imageUrl)
        setCourseImg(false)
        setImgUrl("")
        setLoader(false)
        enqueueSnackbar('Profile image Uploaded', {
          variant: 'success', 
          content: (key, message) => (
            <SuccessSnackbar id={key} message={message} allowDownload={true} />
          ),
        });
      }else{
        enqueueSnackbar('Network Error', {
          variant: 'error', 
          content: (key, message) => (
            <ErrorSnackbar id={key} message={message} allowDownload={true} />
          ),
        });
      }
    }else{
      setLoader(false)
      setError(true)
    }
  }
 
  
  return (
  <Box>
         <Box sx={{display:"flex",
    justifyContent:"flex-start",
    alignItems:"center",
    gap:"30px",
    borderBottom: '1px solid #ccc',
    padding:"30px",
    '@media (max-width: 425px)': {
      flexDirection:"column",
  }
    
    }}>
       {isLoading ?<SkeletonLoading/>:(<>
        <Box sx={{position:"relative", transition: 'width 0.5s, height 0.5s',
        '&.animate': {
          width: '50px',
          height: '50px',
        },}} className={anim ? 'animate' : ''}>
          <Box sx={{borderRadius:"50%",width:"200px",height:"200px",overflow: "hidden", transition: 'width 0.5s, height 0.5s',
          '&.animate': {
            width: '50px',
            height: '50px',
          }, }} className={anim ? 'animate' : ''}>
            <img style={{objectFit: "cover"}}  width={"100%"} height={"100%"} src={imgRender} alt='Profile Image'/>
          </Box>
          {imgUrl ? <DeleteOutlineOutlinedIcon onClick={()=>setImgUrl("")} sx={{
            position:"absolute",
            bottom:"0",
            right:"0",
            backgroundColor:"#F44336",
            width:"50px",
            height:"50px",
            padding:"10px",
            borderRadius:"50%",
            cursor:"pointer"}}/> : <PhotoCameraOutlinedIcon onClick={()=>setProfileModal(true)}  sx={{
            position:"absolute",
            bottom:"0",
            right:"0",
            backgroundColor:"#03FF84",
            width:"50px",
            height:"50px",
            padding:"10px",
            borderRadius:"50%",
            cursor:"pointer"}}/>}
            
        </Box>
        <Box>
            <Typography fontSize={'25px'} fontWeight={600} variant='h5'>{name}</Typography>
           
        </Box>

          <ModalComponent
           profileModal={profileModal}
           setProfileModal={setProfileModal}
           title="Upload picture"
           courseImg={courseImg}
           setCourseImg={setCourseImg}
           setImgUrl={setImgUrl}
           setError={setError}
           setAnim={setAnim}
           />   
        
       </>)} 
    </Box>     
    
    
    {/* using SaveButton component for recycle */}
    <SaveButton imgUrl={imgUrl}  type='button' handleSave={handleSave} isLoading={loader}/>
   {/* {!error &&( )} */}
  </Box>
  )
}

export default ProfilePic