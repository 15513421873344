import { Box, CircularProgress, Grid, Skeleton } from "@mui/material";
import React from "react";
import { Chart } from "react-google-charts";



export const options = {
  title: "Course progress",
  legend: {
    position: "bottom",
  },
  colors: ["#5786FF", "#E57373", "#5CFDB2"],
};
const Skeletoncircleprogress=()=>{
  return(
    <Grid width={300} height={300} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
    <Skeleton variant="circular" width={200} height={200}/>
    <Grid display={"flex"} flexDirection={"row"}>
    <Skeleton sx={{ml:2}} width={20}/>
    <Skeleton sx={{ml:2}} width={20}/>
    <Skeleton sx={{ml:2}} width={20}/>
    </Grid>
    </Grid>
  )
}

export function Charts({active,enrolled,completed,loadercourse}) {



   const data = [
    ["Task", "Hours per Day"],
    ["In-progress", active],
    ["Completed", completed],
    
  ];
  return (
    <div style={{ borderRadius: "20px", overflow: "hidden", }}>
    {
      loadercourse?<Skeletoncircleprogress/>:""
    }
      <Box display={loadercourse?"none":"block"}>
      <Chart
      loader={<Skeletoncircleprogress/>}
        chartType="PieChart"
        data={data}
        options={options}
        width={"100%"}
        height={"320px"}
        style={{ minHeight: "316px" }}
      />
      </Box>
    </div>
  );
}
