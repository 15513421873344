import CertificateDetailService from "../api"

const UseCertificateHooks = ()=>{

const getcertificate=(data)=>{
    return CertificateDetailService.getCertificate(data)
}

const certificategenerate=(id)=>{
    return CertificateDetailService.certificategenerte(id)
}
   
return{
    getcertificate,
    certificategenerate
}
}

export default UseCertificateHooks