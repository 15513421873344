export default function formatDuration(duration) {
    if (!duration) {
        return { hours: "00", minutes: "00" };
    }
    const parts = duration.split(':').map(part => parseInt(part, 10));
    let hours = 0;
    let minutes = 0;
 
    if (parts.length === 3) {
        hours = parts[0] === 0 ?"00": parts[0] ;
        minutes = parts[1] === 0 ?"00": parts[1];
    } else if (parts.length === 2) {
        minutes = parts[0];
    }
 
    return { hours, minutes };
}