import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userDetail from "../api";
import Avatar from '../../../assets/images/avatar.webp'

const initialState = {
    user:{
    id:"",
    name:"",
    email:"",
    mobile:null,
    image_url:Avatar,
    created_at:"",
    updated_at:""
    },
    isLoading:false,
}



export const userDetails = createAsyncThunk("userDetails/getUserDetails", async (id) => {
    try {
        const res = await userDetail.userDetailApi(id)
       
        return res
      } catch (error) {
        
        throw new Error(error.message);
      }
});


const userDetailSlice = createSlice({
    name:"userDetails",
    initialState,
    reducers:{
        updateImage :(state,{payload})=>{
            state.user.image_url =payload
        },
        updateName:(state,{payload})=>{
            state.user.name = payload
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(userDetails.pending,(state,action)=>{
            state.isLoading = true
        })
        builder.addCase(userDetails.fulfilled,(state,{payload})=>{
           
            if (payload?.data?.data?.userDetails[0]) {
               
                state.user.id = payload?.data?.data?.userDetails[0]?.id;
                state.user.name = payload?.data?.data?.userDetails[0]?.name;
                state.user.email = payload?.data?.data?.userDetails[0]?.email;
                state.user.mobile = payload?.data?.data?.userDetails[0]?.mobile;

                state.user.image_url = payload?.data?.data?.userDetails[0]?.image_url ?
                                         payload?.data?.data?.userDetails[0]?.image_url :
                                                                                Avatar;
                                                                                
                state.user.created_at = payload?.data?.data?.userDetails[0]?.created_at;
                state.user.updated_at = payload?.data?.data?.userDetails[0]?.updated_at;
            }

                state.isLoading = false
            })
        builder.addCase(userDetails.rejected,(state,action)=>{
                state.isLoading = false

        })
    }
})

export const {updateImage,updateName} = userDetailSlice.actions

export default userDetailSlice.reducer