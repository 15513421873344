import {  Grid } from '@mui/material'
import React from 'react'
import Cards from './components/Cards'
import Appbar from '../../components/atoms/header/header'

function index() {
  return (
    <Grid>
    <Appbar title={"Certificate"}/>
    <Cards/>
    </Grid>
  )
}

export default index