import axiosInstance from '../../../services/axiosservice';
 
const baseURL = process.env.REACT_APP_API_BASE_URL


const getdashboarddetail =async ()=>{
  try {
    let getdashboarddetail = await axiosInstance.post(`${baseURL}/api/studentDashboardData`,{});
    if (getdashboarddetail) {
      return {
        data: getdashboarddetail?.data
      };
    } else {
      return false;
    }
  } catch (err) {
    console.log(err, "error");
  }
}



 
const DashboardlearnersService ={getdashboarddetail}
 
export default DashboardlearnersService