import axiosInstance from '../../../services/axiosservice';

const baseURL = process.env.REACT_APP_API_BASE_URL

const getAllEnrollStatusAndCourse = async (id) => {
  try {
    let getAllEnrollStatusAndCourse = await axiosInstance.get(`${baseURL}/api/getAllEnrollStatusAndCourse`,{});
    if (getAllEnrollStatusAndCourse) {
      return {
        data: getAllEnrollStatusAndCourse.data
      };
    } else {
      return {data:500};
    }
  } catch (err) {
    console.log(err,"errr");
    if(err?.response){
    if (err?.response?.status === 500) {
      return { data: 500 ,message:"server error 500"};
    } else {
      console.error('HTTP error:', err.response.status);
      return false;
    }
    }else if(err.request){
    return { data: 600 ,message:"network error"};
    }
  }    
};

const getTableDetails = async (sort)=>{
  try {
    let tableDetails = await axiosInstance.post(`${baseURL}/api/assetLibraryList`,sort)
    return tableDetails
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }
  }
}




const getChaptercontenttype = async () => {
  try {
    let getChaptercontenttype = await axiosInstance.post(`${baseURL}/api/getChapterContentType`,{});
    if (getChaptercontenttype) {
      return {
        data: getChaptercontenttype.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getTextpost = async (data) => {
  try {
    let getTextpost = await axiosInstance.post(`${baseURL}/api/TextContentSave`,data);
    if (getTextpost) {
      return {
        data: getTextpost.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getLinkpost = async (data) => {
  try {
    let getLinkpost = await axiosInstance.post(`${baseURL}/api/LinkContentSave`,data);
    if (getLinkpost) {
      return {
        data: getLinkpost.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getQuizpost = async (data) => {
  try {
    let getQuizpost = await axiosInstance.post(`${baseURL}/api/quizContentSave`,data);
    if (getQuizpost) {
      return {
        data: getQuizpost.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getHeadingpost = async (data) => {
  try {
    let getHeadingpost = await axiosInstance.post(`${baseURL}/api/HeadingContentSave`,data);
    if (getHeadingpost) {
      return {
        data: getHeadingpost.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getFiepost = async (data) => {
  try {
    let getFiepost = await axiosInstance.post(`${baseURL}/api/fileContentSave`,data,{headers: {
      'Content-Type': 'multipart/form-data',
    },});
    if (getFiepost) {
      return {
        data: getFiepost.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getScormpost = async (data) => {
  try {
    let getScormpost = await axiosInstance.post(`${baseURL}/api/scromContentSave`,data,{headers: {
      'Content-Type': 'multipart/form-data',
    }, timeout: 0,});
    if (getScormpost) {
      return {
        data: getScormpost.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getPdfpost = async (data) => {
  try {
    let getPdfpost = await axiosInstance.post(`${baseURL}/api/pdfContentSave`,data,{headers: {
      'Content-Type': 'multipart/form-data',
    },});
    if (getPdfpost) {
      return {
        data: getPdfpost.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const postcourseimage = async (data) => {
  try {
    let postImage = await axiosInstance.post(`${baseURL}/api/updateCoverPhoto`,data,{headers: {
      'Content-Type': 'multipart/form-data',
    },});
    if (postImage) {
      return {
        data: postImage.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getcourseimage = async (id) => {
  try {
    let getImage = await axiosInstance.get(`${baseURL}/api/getCoverPhotoUrl/${id}`,{course_id:id});
    if (getImage) {
      return {
        data: getImage.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const postcoursepublish = async (data) => {
  try {
    let postcoursepublish = await axiosInstance.post(`${baseURL}/api/coursePublishStatus`,data);
    if (postcoursepublish) {
      return {
        data: postcoursepublish.data
      };
    } else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getcoursepublish = async (id) => {
  try {
    let getcoursepublish = await axiosInstance.get(`${baseURL}/api/getPublishStatus/${id}`,{course_id:id});
    if (getcoursepublish) {
      return {
        data: getcoursepublish.data
      };
    } else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const postsavetodraft = async (data) => {
  try {
    let postsavetodraft = await axiosInstance.post(`${baseURL}/api/insertCourseBuilderData`,data);
    if (postsavetodraft) {
      return {
        data: postsavetodraft.data
      };
    } else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getsavecourselist = async (id) => {
  try {
    let getsavecourselist = await axiosInstance.get(`${baseURL}/api/getCourseBuilderData/${id}`,{course_id:id});
    if (getsavecourselist) {
      return {
        data: getsavecourselist.data
      };
    } else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getContentDataLevelOne = async (data) => {
  try {
    let getContentDataLevelOne = await axiosInstance.get(`${baseURL}/api/getContentDataLevelOne/${data.module_id}/${data.content_type}/${data.content_id}`,);
    if (getContentDataLevelOne) {
      return {
        data: getContentDataLevelOne.data
      };
    } else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getContentDataLevelTwo = async (data) => {
  try {
    let getContentDataLevelTwo = await axiosInstance.get(`${baseURL}/api/getContentDataLevelTwo/${data.chapter_content_id}/${data.content_type}`,{},)
    if (getContentDataLevelTwo) {
      return {
        data: getContentDataLevelTwo.data
      };
    } else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const getSavelistpost = async (data) => {
  try {
    let getSavelistpost = await axiosInstance.post(`${baseURL}/api/saveContentData`,data);
    if (getSavelistpost) {
      return {
        data: getSavelistpost.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const postscormdeletelevelone = async (data) => {
  try {
    let postscormdeletelevelone = await axiosInstance.post(`${baseURL}/api/deleteContentLevelOneForCourse`,data);
    if (postscormdeletelevelone) {
      return {
        data: postscormdeletelevelone.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const postscormdeleteleveltwo = async (data) => {
  try {
    let postscormdeleteleveltwo = await axiosInstance.post(`${baseURL}/api/deleteContentLevelTwoForCourse`,data);
    if (postscormdeleteleveltwo) {
      return {
        data: postscormdeleteleveltwo.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};

const copyItemsassetLibrary = async (data) => {
  try {
    let copyItemsassetLibrary = await axiosInstance.post(`${baseURL}/api/importItems
    `,data);
    if (copyItemsassetLibrary) {
      return {
        data: copyItemsassetLibrary.data
      };
    }  else {
      return {
        data: 500
      };
    }
  } catch (err) {
    if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
    }else if(err.request){
      return { data: 600 ,message:"network error"};
    }

  }
};


const CreateCourseService = {
    getChaptercontenttype,
    getFiepost,
    getLinkpost,
    getHeadingpost,
    getTextpost,
    getScormpost,
    getPdfpost,
    getQuizpost,
    postcourseimage,
    getcourseimage,
    postcoursepublish,
    getcoursepublish,
    postsavetodraft,
    getsavecourselist,
    getContentDataLevelOne,
    getContentDataLevelTwo,
    getSavelistpost,
    postscormdeletelevelone,
    postscormdeleteleveltwo,
    getTableDetails,
    getAllEnrollStatusAndCourse,
    copyItemsassetLibrary,
};

export default CreateCourseService; 
