import React, { useEffect, useState } from 'react'
import {CircularProgress, Drawer,Grid, IconButton, OutlinedInput, Typography, Button, Box, Modal,} from "@mui/material";
import {CreateModifiedDiv, StyledImgDiv,StyledPencilDiv} from '../useStyle'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { POPPINS } from '../../../constants/fontConstantsPoppins';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router';
import SidebarList from './SidebarList';
import CloseCircle from '../../../assets/svgimages/closecircle';
import CourseDetailHooks from '../hooks/index';
import CourseDetailService from '../Api';
import AddcourseCover from '../../../assets/svgimages/addcoursecover';
import ImgLoading from './ImgLoading';




function Sidebar() {
  const {postcourseimage,getCourseimgHook,load,courseimgUrl,setcourselistUrl,CourseDetailFormHook} = CourseDetailHooks()
  const [profilemodal, setProfilemodal] = useState(false);
  const [courseimg, setCourseimg] = useState(false);
  const [responseitem, setResponseitem] = useState(null);
  const {id}=useParams()


  const navigate=useNavigate()
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    
  }));
  const contenttype = () => {
    CourseDetailService.getChaptercontenttype().then((res) => {
      if (res?.data?.status === 200) {
        setResponseitem(res?.data?.data?.chapterContentType);
      }
    });
  };
  useEffect(() => {
    getCourseimgHook(parseInt(id))
    contenttype();
    return ()=>{
      setcourselistUrl()
    }
  }, [id]);


  const handleprofileClose = () => {
    setProfilemodal(false);
  };

  
  const courseimgchange=(event)=>{
    setCourseimg(event?.target?.files[0])
  }


  const handleimgUpload=async()=>{
    const formData = new FormData();
          formData.append('file',courseimg);
    const payload={
      course_id:id,ImageUrl:formData.get("file")
    }
   const res=await postcourseimage(payload,setProfilemodal)
   
  }
 

  return (
    <Grid item  >
    <Drawer
      variant="permanent"
      open={true}
      sx={{
        width: "230px",
        '@media (max-width: 765px)': {
          width: "171px",
               
        },
        backgroundColor: "white",
        "& .MuiDrawer-paper": {
        
            
          backgroundColor: "#F4F5F7",
        
        },
      }}
    >
      <Grid>
         <StyledImgDiv>
                
                    <div style={{position:"relative",width:"170px",height:"120px"}} >
                      {load ?<ImgLoading/>:(<>
                        {courseimgUrl?( <div style={{position:"absolute",width:"100%",height:"100%"}}>
                             <img  style={{width:"100%",height:"100%",border:"1px solid #ccc"}} src={courseimgUrl}></img>
                        </div>):(<Box
                alignItems={"center"}
                flexDirection={"column"}
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  border: "0.5px solid var(--All-stroke, rgba(0, 0, 0, 0.24));",
                  width: 220,
                  height: 110,
                  borderRadius: 4,
                  cursor: "pointer",
                  width:"170px",
                  height:"120px"
                }}
              >
                <AddcourseCover />
                <Box>
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    mt={1}
                    fontFamily={POPPINS}
                  >
                    Add course cover
                  </Typography>
                </Box>
              </Box>)}
                      
                      </>)}
                        <StyledPencilDiv onClick={()=>setProfilemodal(true)}> 
                            <CreateOutlinedIcon   color='white'/>
                        </StyledPencilDiv>
                    </div>
                    <div>
                        <Typography style={{fontSize:"18px",fontWeight:"500",fontFamily:POPPINS,textAlign:'center'}}>{id}</Typography>
                        <CreateModifiedDiv>
                                <Typography  style={{fontSize:"13px",fontWeight:"500",fontFamily:POPPINS }}>Created:&nbsp;</Typography>
                                <Typography style={{fontSize:"13px",fontWeight:"600",fontFamily:POPPINS}}>{
                                 CourseDetailFormHook.Created_at ? 
                                CourseDetailFormHook.Created_at?.split(" ")[0]?.split("-")[2] +  "-"  +
                                CourseDetailFormHook.Created_at?.split(" ")[0]?.split("-")[1] +  "-"  +
                                CourseDetailFormHook.Created_at?.split(" ")[0]?.split("-")[0] 
                                : ""
                                }</Typography>
                        </CreateModifiedDiv>
                        <CreateModifiedDiv>
                                <Typography style={{fontSize:"13px",fontWeight:"500",fontFamily:POPPINS }}>Modified:&nbsp;</Typography>
                                <Typography style={{fontSize:"13px",fontWeight:"600",fontFamily:POPPINS,ml:1}}>{
                                  CourseDetailFormHook.Updated_at ?
                                CourseDetailFormHook.Updated_at?.split(" ")[0]?.split("-")[2] +  "-"  +
                                CourseDetailFormHook.Updated_at?.split(" ")[0]?.split("-")[1] +  "-"  +
                                CourseDetailFormHook.Updated_at?.split(" ")[0]?.split("-")[0]
                                : ""
                                } </Typography>
                        </CreateModifiedDiv>
                    </div>
              
        </StyledImgDiv>


      </Grid>

     <SidebarList/>

    </Drawer>
    <Modal
        open={profilemodal}
        onClose={handleprofileClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            width: "55%",
            height: "40%",
            outline: "none",
            borderRadius: 4,
            '@media (max-height: 685px)': {
              height: "50%",
            },
            '@media (max-height: 550px)': {
              height: "60%",
            },
          }}
        >
          <Grid container>
            <Grid
              spacing={2}
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              height={"50px"}
              p={5}
              borderBottom={"1px solid var(--All-stroke, rgba(0, 0, 0, 0.24))"}
            >
              <Typography fontFamily={POPPINS} fontSize={24} fontWeight={400}>
                Upload cover image
              </Typography>
              <IconButton onClick={handleprofileClose}>
              <CloseCircle />
              </IconButton>
            </Grid>
            <Grid xs={12} mt={2} pl={5} item>
              <Typography fontSize={13} fontFamily={POPPINS} fontWeight={600}>
                Choose image
              </Typography>
              <Box mt={2}>
                <OutlinedInput
                onChange={courseimgchange}
                  type="file"
                  accept=".pdf"
                  placeholder="Title"
                  sx={{ width: "95%", height: "50px" }}
                />
              </Box>
            </Grid>
            <Grid
              mt={6}
              pr={5}
              container
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Grid gap={1} display={"flex"} justifyContent={"flex-end"}>
                <Button
                onClick={()=>setProfilemodal(false)}
                  variant="contained"
                  sx={{
                    backgroundColor: "#5786FF",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#5786FF",
                    },
                  }}
                >
                  <Typography
                    fontFamily={POPPINS}
                    fontSize={13}
                    color={"#000"}
                    textTransform={"none"}
                  >
                    Cancel
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={handleimgUpload}
                  sx={{
                    backgroundColor: "#03FF84",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#03FF84",
                    },
                  }}
                >{!load?
                  <Typography
                  fontFamily={POPPINS}
                  fontSize={13}
                  color={"#000"}
                  textTransform={"none"}
                >
                  upload
                </Typography>:<CircularProgress  size={25}/>
                }
                
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
  </Grid>
  )
}

export default Sidebar