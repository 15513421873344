import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
function SkeletonLoading() {
  return (
    <Stack spacing={1}>
       
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center", gap:"20px"}}>
            <Skeleton variant="circular" width={200} height={200} />
            <Skeleton variant="text" sx={{ fontSize: '1rem', width:"400px" }} />
        </Box>
       
  </Stack>
  )
}

export default SkeletonLoading