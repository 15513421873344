import axiosInstance from '../../../services/axiosservice';
 
const baseURL = process.env.REACT_APP_API_BASE_URL
 
 
const getAllEnrollStatusAndCourse = async (id) => {
    try {
      let getAllEnrollStatusAndCourse = await axiosInstance.get(`${baseURL}/api/getAllEnrollStatusAndCourse`,{});
      if (getAllEnrollStatusAndCourse) {
        return {
          data: getAllEnrollStatusAndCourse.data
        };
      } else {
        return {data:500};
      }
    } catch (err) {
      console.log(err,"errr");
      if(err?.response){
      if (err?.response?.status === 500) {
        return { data: 500 ,message:"server error 500"};
      } else {
        console.error('HTTP error:', err.response.status);
        return false;
      }
      }else if(err.request){
      return { data: 600 ,message:"network error"};
      }
    }    
  };


  const getChaptercontenttype = async () => {
    try {
      let getChaptercontenttype = await axiosInstance.post(`${baseURL}/api/getChapterContentType`,{});
      if (getChaptercontenttype) {
        return {
          data: getChaptercontenttype.data
        };
      }  else {
        return {
          data: 500
        };
      }
    } catch (err) {
      if(err?.response){
        if (err?.response?.status === 500) {
          return { data: 500 ,message:"server error 500"};
        } else {
          console.error('HTTP error:', err.response.status);
          return false;
        }
      }else if(err.request){
        return { data: 600 ,message:"network error"};
      }
  
    }
  };

  const getTableDetails = async (datatable)=>{
    try {
      let tableDetails = await axiosInstance.post(`${baseURL}/api/assetLibraryList`,datatable)
      return tableDetails
    } catch (err) {
      if(err?.response){
        if (err?.response?.status === 500) {
          return { data: 500 ,message:"server error 500"};
        } else {
          console.error('HTTP error:', err.response.status);
          return false;
        }
      }else if(err.request){
        return { data: 600 ,message:"network error"};
      }
    }
  }

 
const AssetService ={getAllEnrollStatusAndCourse,getChaptercontenttype,getTableDetails}
 
export default AssetService