import React, { useEffect, useState } from "react";
import LayoutModal from "../../../components/atoms/layoutModal/LayoutModal";
import { Grid, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import Viewpage from "./viewpage";
import Editviewpage from "./editviewpage";
import QuestionBankservice from "../hooks/questionbankservice";

function Detailsview({
  isModaleditOpens,
  setIsModaleditOpens,
  setPushload,
  questionData,
  DeleteItemId,
  Qustnids,
  createdAT,
}) {
  const { getquestiontype, subjectlistdatas, topiclistdatas } =
    QuestionBankservice();
  const [viewcontainer, setViewcontainer] = useState("VIEW");
  const [topic, setTopic] = useState([]);
  const [subject, setSubject] = useState([]);
  const [type, setType] = useState([]);
  const [fetchchange, setFetchchange] = useState(false);

  useEffect(() => {
    getquestiontype().then((res) => {
      if (res?.data?.status === 200) {
        setType(res?.data?.questionTypes);
      }
    });

    subjectlistdatas().then((res) => {
      if (res?.data?.status === 200) {
        setSubject(res?.data?.subjectText);
      }
    });
    topiclistdatas().then((res) => {
      if (res?.data?.status === 200) {
        setTopic(res?.data?.questionTopics);
      }
    });
  }, [fetchchange]);

  const handleeditClose = () => {
    setIsModaleditOpens(false);
    setViewcontainer("VIEW");
  };

  const viewpage = () => {
    if (viewcontainer === "VIEW") {
      return (
        <Viewpage
          createdAT={createdAT}
          DeleteItemId={DeleteItemId}
          Qustnids={Qustnids}
          topic={topic}
          subject={subject}
          type={type}
          questionData={questionData}
          setPushload={setPushload}
          setViewcontainer={setViewcontainer}
          setIsModaleditOpens={setIsModaleditOpens}
        />
      );
    } else if (viewcontainer === "EDIT") {
      return (
        <Editviewpage
          setFetchchange={setFetchchange}
          topic={topic}
          subject={subject}
          type={type}
          questionData={questionData}
          setPushload={setPushload}
          setViewcontainer={setViewcontainer}
          setIsModaleditOpens={setIsModaleditOpens}
        />
      );
    }
  };

  return (
    <LayoutModal
      w={"80%"}
      h={"80%"}
      handleModal={isModaleditOpens}
      setHandleModal={setIsModaleditOpens}
      newfil={"reset"}
      resetall={handleeditClose}
    >
      <Grid
        p={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          fontSize={16}
          fontFamily={POPPINS}
          fontWeight={600}
        ></Typography>
        <Grid sx={{ cursor: "pointer" }} onClick={handleeditClose}>
          <CancelOutlinedIcon />
        </Grid>
      </Grid>
      <Grid p={4} sx={{ maxHeight: "80%", overflowY: "auto" }}>
        {viewpage()}
      </Grid>
    </LayoutModal>
  );
}

export default Detailsview;
