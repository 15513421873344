import { Box, Grid, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { POPPINS } from '../../constants/fontConstantsPoppins';
import Appbar from '../../components/atoms/header/header';
import CustomizedTables from './components/table';
import { Charts } from './components/charts';
import { getCookie } from '../../helpers/CookieHelpers';
import { HttpClient } from '../../services/http.service';
import UseCertificateHooks from '../certificate/hooks';
import DashboardLearnersService from './hooks';
import {useNavigate} from "react-router-dom"
import Skeletoncad from "./components/skeletoncad"
import formatDuration from "./functionalities/index"
import Skeletontable from "./components/skeletontable"

function Index() {

  const [certificatedata,setCertificateData]=useState([])
  const [loadercourse,setLoadercourse]=useState(false)
  const [loadingCertificates,setLoadingcerrtificate]=useState(true)
  const [courseDetails,setCourseDetails]=useState([])
  const [allcourseDetails,setAllCourseDetails]=useState([])

  const { getcertificate } = UseCertificateHooks();
  const {postdashboard}=DashboardLearnersService()
  const navigate=useNavigate()

const certificateCount=certificatedata?.length

const enrollarray=allcourseDetails?.map(itm=>itm.Title)

const percentagesArray = allcourseDetails?.map(course => {
    const percentage = course.Percentage
    return percentage
});

  useEffect(()=>{
    const token=getCookie("token")
    HttpClient.interceptors(token)
        },[])
        const userid = getCookie('userinformation');
    
    const getcertificates=()=>{
      setLoadingcerrtificate(true)
      getcertificate({user_id:userid}).then(res=>{
        if(res?.data?.status===200){
          setLoadingcerrtificate(false)
                    setCertificateData(res?.data?.data?.certificateData)
        }
      })
    }

    
    const getallcourse=()=>{
      setLoadercourse(true)
      postdashboard().then(res=>{
        if(res?.data?.status===200){
          setLoadercourse(false)
          const coursedetails={
            TotalWatched:res?.data?.data?.TotalWatched,
            studentEnrol:res?.data?.data?.studentEnrol,
            studentEnrolActive:res?.data?.data?.studentEnrolActive,
            studentEnrolComplete:res?.data?.data?.studentEnrolComplete
          }
          setCourseDetails(coursedetails)
          setAllCourseDetails(res?.data?.data?.studentEnrolCourses)

        }
      })
    }

  const formattedDuration = formatDuration(courseDetails?.TotalWatched);
    
        useEffect(()=>{
          getcertificates()
        getallcourse()

        },[])


  const CartComponent = ({ number, details, prescription }) => {
    const numberString = number.toString();
    const numberLength = numberString.length;

    
  


    return (
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        width={{ xs: '100%', md: 'auto' }}
        height={{ xs: 'auto', md: '140px' }}
        sx={{
          border: "1px solid var(--All-stroke, rgba(0, 0, 0, 0.24))",
          borderRadius: 7,
          '&:hover': {
            backgroundColor: '#172935',
            color: 'white',
          },
        }}
      >
        <Typography alignItems="baseline">
          <span style={{ fontWeight: 600, fontSize: 60, fontFamily: POPPINS, }}>{number}</span>
          <span style={{ fontWeight: 'bold', fontSize: 13, fontFamily: POPPINS,marginLeft:2 }}>{details}</span>
        </Typography>
        <Typography
          position={{ xs: 'static', md: 'absolute' }}
          marginTop={{ xs: '10px', md: '82px' }}
          fontSize={13}
          marginRight={numberLength > 1 ? "20px" : "-20px"}
          fontFamily={POPPINS}
        >
          {prescription}
        </Typography>
      </Box>
    );
  };

  return (
    <Grid container pb={2}>
      <Appbar title={"Dashboard"} />
      <Grid 
        sx={{
          '@media (max-width: 1230px)': {
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingLeft:"30px"


                  
          },
        }}
      
      mt={4} xs={12} alignItems={"center"}  justifyContent={"center"} container spacing={2}>
        <Grid  xs={12}  md={3} sx={{ borderWidth: 1,
         
         '@media (max-width: 1230px)':{
            width: '100%',
            maxWidth: '100%',
          
        } }} item>
        {loadercourse?<Skeletoncad/>:
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            
            width={{ xs: '100%', md: 'auto' }}
            height={{ xs: 'auto', md: '140px' }}
            sx={{
              border: "1px solid var(--All-stroke, rgba(0, 0, 0, 0.20))",
              borderRadius: 7,
              '&:hover': {
                backgroundColor: '#172935',
                color: 'white',
                
              },
            }}
          >
            <Typography alignItems="baseline">
              <span style={{ fontWeight: 600, fontSize: 60, fontFamily: POPPINS }}>{formattedDuration.hours}</span>
              <span style={{ fontWeight: 'bold', fontSize: 13, fontFamily: POPPINS }}> Hr</span>
              <span style={{ fontWeight: 'bold', fontSize: 60, marginLeft: 6, fontFamily: POPPINS }}>{formattedDuration.minutes}</span>
              <span style={{ fontWeight: 'bold', fontSize: 13, fontFamily: POPPINS }}>Min</span>
            </Typography>
            <Typography
              position={{ xs: 'static', md: 'absolute' }}
              marginTop={{ xs: '10px', md: '82px' }}
              fontFamily={POPPINS}
              fontSize={13}
              marginRight={{ xs: '0', md: '100px' }}
            >
              Total watched
            </Typography>
          </Box>
        }
        </Grid>
        <Grid sx={{
            '@media (max-width: 1230px)':{
              width: '100%',
              maxWidth: '100%'

          }
        }} xs={12} md={2} width={{ xs: '100%', md: 'auto' }} item>
          {loadercourse?<Skeletoncad/>:<CartComponent number={courseDetails?.studentEnrol??"0"} details={"Courses"} prescription={"Enrolled courses"} />}
        </Grid>
        <Grid sx={{
            '@media (max-width: 1230px)':{
              width: '100%',
              maxWidth: '100%'
          }
        }} xs={12} md={2} item>
        {loadercourse?<Skeletoncad/>:<CartComponent number={courseDetails?.studentEnrolActive??"0"} details={"Active"} prescription={"Courses active"} />}
        </Grid>
        <Grid sx={{
            '@media (max-width: 1230px)':{
              width: '100%',
              maxWidth: '100%'
          }
        }} xs={12} md={2} item>
        {loadercourse?<Skeletoncad/>:<CartComponent number={courseDetails?.studentEnrolComplete??"0"} details={"Completed"} prescription={"Courses completed"} />}
        </Grid>
        <Grid sx={{
            '@media (max-width: 1230px)':{
              width: '100%',
              maxWidth: '100%'
          }
        }} xs={12} md={2} item>
        {loadercourse?<Skeletoncad/>:<CartComponent number={certificateCount} details={"Certificate"} prescription={"View certificates"} />}
        </Grid>
      </Grid>
      <Grid spacing={2} ml={4} mt={4} xs={12} container>
        <Grid md={5} xs={12} item>
        {loadercourse?<Skeletontable/>:
          <CustomizedTables enrollarray={enrollarray} percentagesArray={percentagesArray} />
      }
        </Grid>
        <Grid xs={12} md={4} item>
      <Charts loadercourse={loadercourse} active={courseDetails?.studentEnrolActive??0} enrolled={courseDetails?.studentEnrol??0}
         completed={courseDetails?.studentEnrolComplete??0} />
        </Grid>
        {loadingCertificates ? ( 
        <Grid xs={12} md={3} mt={2} display={"flex"} flexDirection={"column"}>
          <Box
            width={"230px"}
            height={"316px"}
            sx={{
              border: "1px solid var(--All-stroke, rgba(0, 0, 0, 0))",
              borderRadius: 5,
              background: "#FFF",
              position: "relative", 
              display: "flex",
              p:2
            }}
          >
          <Grid>
           <Skeleton width={190} />
           <Skeleton width={190} height={50}/>
           <Skeleton width={190} height={50}/>
           <Skeleton width={190} height={50}/>
           <Skeleton width={190} height={50}/>
           <Skeleton width={190}/>
           </Grid>
          </Box>
        </Grid>
      ) : (
        certificatedata?.length > 0 &&
        <Grid xs={12} md={3} item>
          <Box
            width={"230px"}
            height={"316px"}
            sx={{
              border: "1px solid var(--All-stroke, rgba(0, 0, 0, 0))",
              borderRadius: 5,
              background: "#FFF",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box ml={2} mt={2}>
              <Typography fontFamily={POPPINS} fontSize={14} fontWeight={"bold"}>
                Certificates
              </Typography>
            </Box>
            <Box
              sx={{
                maxHeight: "240px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: 0, 
                },
                "&:hover":{"&::-webkit-scrollbar": {
                  width: "6px", 
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888", 
                  borderRadius: "3px", 
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1", 
                  borderRadius: "3px", 
                },
              }
              }}
            >
              {certificatedata?.map((itm) => (
                <Box
                  onClick={() => navigate("/certificate")}
                  sx={{
                    background: "#FAFAFA",
                    padding: "13px",
                    "&:hover": {
                      background: "linear-gradient(90deg, #57FFAE 0%, rgba(142, 252, 199, 0.00) 94.93%)",
                      cursor: "pointer", 
                    },
                  }}
                  mt={2}
                >
                  <Typography fontFamily={POPPINS} fontSize={13}>
                    {itm?.Title}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box
              onClick={() => navigate("/certificate")}
              sx={{
                background: "#FFF",
                paddingLeft: "13px",
                position: "absolute",
                bottom: 15,
                width: "100%", // Make sure "View all certificate" spans full width
                
                "&:hover": {
                  cursor: "pointer",
                  opacity:"0.8",
                
                },
              }}
            >
              <Typography  fontFamily={POPPINS} fontSize={13}>
                View all certificates
              </Typography>
            </Box>
          </Box>
        </Grid>
      )
      }
      </Grid>
    </Grid>
  );
}

export default Index;
