// axiosInstance.js

import axios from 'axios';
import { getCookie, removeCookie } from '../helpers/CookieHelpers';
import { useSnackbar } from 'notistack';
import SuccessSnackbar from "../components/molecules/customsnackbar/successSnackbar"
import ErrorSnackbar from '../components/molecules/customsnackbar/errorSnackbar'; 


let navigateFunction; 
let  enqueueSnackbarFunction;


export const setNavigateFunction = (navigate) => {
  navigateFunction = navigate; 
};
export const setEnqueueSnackbarFunction = (enqueueSnackbar) => {
  enqueueSnackbarFunction = enqueueSnackbar;
};

const instance = axios.create({
  timeout: 20000, // Set your preferred timeout
  baseURL:'https://lmsdemo.mwtconsultancy.com',
  headers: {
    'Content-Type': 'application/json',
  },
});



instance.interceptors.request.use(
  (config) => {
    const authToken = getCookie("token")

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      console.error('Network error:', error);
      if (enqueueSnackbarFunction) {
        enqueueSnackbarFunction("Network error", {
          variant: "error",
          content: (key, message) => (
            <ErrorSnackbar id={key} message={message} allowDownload={true} />
          ),
        });      }
     
    }else if(error.response.status === 500){
      enqueueSnackbarFunction("500 server error", {
        variant: "error",
        content: (key, message) => (
          <ErrorSnackbar id={key} message={message} allowDownload={true} />
        ),
      }); 
    }
    else if (error.response && error.response.status === 401) {
      removeCookie('token');
      removeCookie('user');
      navigateFunction('/signin');
        }
    return Promise.reject(error);
  }
);

export default instance;
