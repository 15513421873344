import { Box, Skeleton } from '@mui/material'
import React from 'react'

function Skeletoncad() {
  return (
    <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        width={{ xs: '100%', md: 'auto' }}
        height={{ xs: 'auto', md: '140px' }}
        sx={{
          border: "1px solid var(--All-stroke, rgba(0, 0, 0, 0.24))",
          borderRadius: 7,
          '&:hover': {
            backgroundColor: '#172935',
            color: 'white',
          },
        }}
      >
       <Skeleton width={100}/>
       <Skeleton width={100}/>
      </Box>
  )
}

export default Skeletoncad