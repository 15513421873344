import React, { useState } from "react";
import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import QuestionBankservice from "../hooks/questionbankservice";
import DisplayHtml from "../../../components/atoms/htmlcontentdisplay/index";

function Viewpage({
  setViewcontainer,
  questionData,
  topic,
  subject,
  type,
  setPushload,
  setIsModaleditOpens,
  Qustnids,
  DeleteItemId,
  createdAT,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(createdAT);
  console.log();
  const { deleteqstnrow } = QuestionBankservice();
  const ansalldata = [
    {
      created: `${createdAT[2]}- ${createdAT[1]}- ${createdAT[0]}`,
      modified: "22-12-2020",
      subject: subject.find(({ Id }) => Id === questionData.subject),
      topic: topic.find(({ Id }) => Id === questionData.topic),
      type: type.find(({ Id }) => Id === questionData.type),
      question: questionData.questionText,
      ans: questionData.option,
      exp: questionData.explanation,
      answer: questionData.answer,
    },
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const deleteqstns = () => {
    const data = {
      question_id: DeleteItemId ?? null,
      question_type_id: Qustnids ?? null,
    };
    deleteqstnrow(data).then((res) => {
      if (res?.data?.status === 200) {
        setPushload((prev) => !prev);
        setIsModalOpen(false);
        setIsModaleditOpens(false);
      } else {
        setIsModalOpen(false);
      }
    });
  };
  console.log(questionData);
  return (
    <Grid container>
      <Grid container spacing={1} display={"flex"}>
        <Typography
          display={"flex"}
          fontFamily={POPPINS}
          fontSize={13}
          sx={{ backgroundColor: "#e5e5e5", p: "3px" }}
        >
          created:
          <Typography fontFamily={POPPINS} fontSize={13} fontWeight={600}>
            {ansalldata[0].created}
          </Typography>
        </Typography>
        <Typography
          ml={2}
          display={"flex"}
          fontFamily={POPPINS}
          fontSize={13}
          sx={{ backgroundColor: "#e5e5e5", p: "3px" }}
        >
          Subject:
          <Typography fontFamily={POPPINS} fontSize={13} fontWeight={600}>
            {ansalldata[0]?.subject?.Subject_Text}
          </Typography>
        </Typography>
      </Grid>
      <Grid container justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            setViewcontainer("EDIT");
          }}
          variant="outlined"
          sx={{ mr: 2 }}
          startIcon={<CreateOutlinedIcon />}
        >
          <Typography fontFamily={POPPINS} fontSize={13}>
            Edit
          </Typography>
        </Button>
        <Button
          onClick={() => setIsModalOpen(true)}
          variant="outlined"
          sx={{ color: "red" }}
          startIcon={<CancelOutlinedIcon />}
        >
          <Typography fontFamily={POPPINS} fontSize={13}>
            Delete
          </Typography>
        </Button>
      </Grid>
      <Grid container justifyContent={"flex-end"} mt={2}>
        <Typography
          display={"flex"}
          fontFamily={POPPINS}
          fontSize={13}
          sx={{ backgroundColor: "#e5e5e5", p: "3px" }}
        >
          Type:
          <Typography
            ml={1}
            fontFamily={POPPINS}
            fontSize={13}
            fontWeight={600}
          >
            {ansalldata[0]?.type?.Type}
          </Typography>
        </Typography>
        <Typography
          ml={2}
          display={"flex"}
          fontFamily={POPPINS}
          fontSize={13}
          sx={{ backgroundColor: "#e5e5e5", p: "3px" }}
        >
          Topics:
          <Typography
            fontFamily={POPPINS}
            fontSize={13}
            ml={1}
            fontWeight={600}
          >
            {ansalldata[0]?.topic?.Topic_Text}
          </Typography>
        </Typography>
      </Grid>
      <Grid mt={4}>
        <Typography width={"100%"} fontFamily={POPPINS} fontSize={17}>
          <DisplayHtml htmlContent={ansalldata[0]?.question ?? ""} />
        </Typography>
      </Grid>
      <Grid container direction="column" mt={2}>
        {ansalldata[0].ans?.map((itm, index) => (
          <Grid
            key={index}
            item
            container
            alignItems={"center"}
            border={1}
            sx={{
              height: "60px",
              backgroundColor: itm.is_correct ? "#32b643" : "",
            }}
            mt={2}
          >
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                ml={1}
                p={1}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontFamily={POPPINS}
                fontSize={30}
                sx={{
                  backgroundColor: "blue",
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  color: "#fff",
                }}
              >
                {itm.orderNumber}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                ml={2}
                fontFamily={POPPINS}
                sx={{ color: itm.check ? "#fff" : "#000" }}
                fontSize={15}
              >
                <DisplayHtml htmlContent={ itm?.optionText ?? ""} />
          
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container direction="column" mt={2}>
        {ansalldata[0].answer && (
          <>
            <Typography fontFamily={POPPINS} fontWeight={600} fontSize={23}>
              Answer::
            </Typography>
            <Grid
              item
              container
              alignItems={"center"}
              border={1}
              sx={{
                height: "60px",
                backgroundColor: "#32b643",
              }}
              mt={2}
            >
              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {/* <Typography
              ml={1}
              p={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              fontFamily={POPPINS}
              fontSize={30}
              sx={{
                backgroundColor: "blue",
                width: 50,
                height: 50,
                borderRadius: "50%",
                color: "#fff",
              }}
            >
              
            </Typography> */}
              </Grid>
              <Grid item>
                <Typography
                  ml={2}
                  fontFamily={POPPINS}
                  sx={{ color: ansalldata[0].answer ? "#fff" : "#000" }}
                  fontSize={15}
                >
                <DisplayHtml htmlContent={ ansalldata[0]?.answer?? ""} />
                 
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container flexDirection={"column"} mt={4}>
        <Typography fontFamily={POPPINS} fontWeight={600} fontSize={23}>
          Explaination::
        </Typography>
        <Typography mt={2} fontFamily={POPPINS} fontSize={15} width={"100%"}>
        <DisplayHtml htmlContent={ ansalldata[0]?.exp?? ""} />
        </Typography>
      </Grid>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Paper
          style={{
            position: "absolute",
            width: 400,
            backgroundColor: "white",
            padding: 20,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography fontSize={14} fontFamily={POPPINS} variant="h6">
            Are you sure you want to delete this item?
          </Typography>
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Button
              onClick={deleteqstns}
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
            >
              Delete
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              sx={{ color: "#000" }}
            >
              Cancel
            </Button>
          </Grid>
        </Paper>
      </Modal>
    </Grid>
  );
}

export default Viewpage;
