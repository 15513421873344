// images.js

const Images = {
    AVATAR: require('../assets/images/avatar.png'),
    CART:require('../assets/images/cart.png'),
    LOGINIMG:require('../assets/images/loginimg.png'),
    TEACHIMG:require('../assets/images/teach.png'),
    EMPTY:require('../assets/images/Empty.jpg'),
    RESETPASSWD:require('../assets/images/resepasswordui.png'),
    FORGOTPASSWD:require('../assets/images/ForgotPasswordThinking.jpg'),
    EXCEL:require('../assets/images/excelimg.jpg')
  };
  
  export default Images;
  