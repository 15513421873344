import * as React from "react"
const CloseCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={43}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.558 39.383c9.895 0 17.917-7.835 17.917-17.5s-8.022-17.5-17.917-17.5c-9.895 0-17.916 7.835-17.916 17.5s8.021 17.5 17.916 17.5ZM26.934 16.633l-10.75 10.5M16.184 16.633l10.75 10.5"
    />
  </svg>
)
export default CloseCircle
