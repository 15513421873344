import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Grid } from '@mui/material';

const BarChartComponent = ({graphData,h}) => {

  const uData = graphData ?  Object.values(graphData):[];
  const xLabels = graphData ?  Object.keys(graphData):[];
  const shortenedLabels = xLabels.map(label => {
    if (label.length <= 3) {
        return label;
    } else {
        return label.slice(0, 3); // Truncate to three letters
    }
});
  const datawithname = uData.map((value, index) => ({
    users: value,
    name: shortenedLabels[index],
  }));
  return (
    <Grid xs={12}  style={{width:"105%",margin:"0px",position:"relative",left:"-30px"}} >
      <ResponsiveContainer  width="105%" height={h?h:180}>
      <BarChart
        width={"105%"}
        data={datawithname}
        
        
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="users" fill={"#5786FF"} />
      </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
}

export default BarChartComponent;
