import * as yup from 'yup';

export const schema=()=>{
     return yup.object().shape({
         name: yup.string().required('Name is required'),
         
      });
}

export const passwordSchema=()=>{
    return yup.object().shape({
        currentPassword: yup.string().required('current Password is required').min(6, 'current Password should be at least 6 characters'),
        newPassword: yup.string().required('New Password is required').min(6, 'New Password should be at least 6 characters'),
        retypeNewPassword: yup.string().required('Retype New Password is required').min(6, 'Retype New Password should be at least 6 characters')
        
     });
}