import React from 'react';
import styled from '@emotion/styled';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid, TableBody } from '@mui/material';
import RoundTick from '../../../assets/svgimages/roundTick';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    color: 'black',
    borderBottom: '1px solid #ccc', 
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: 'none', 
}));

const ColoredTableCell = styled(StyledTableCell)(({ color, columnIndex }) => ({
  backgroundColor: columnIndex === 1 ? color : '#57FFAE', 
  '&:not(:first-child):not(:first-child)': {
    background: columnIndex === 1 ? color : '#57FFAE' 
  },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:first-child th': {
    borderRight: 'transparent', 
  },
  '& td': {
    borderBottom: 'none', 
  },
}));

function createData(name, value, progress, color) {
  return { name, value, progress, color };
}

const CustomPaper = styled(Paper)({
  borderRadius: 4,
  overflow: 'hidden',
  width: "100%",
  boxShadow: "none" // Removing box shadow
});


export default function CustomizedTables({enrollarray,percentagesArray}) {

const rows = enrollarray.map((title, index) => {
  const percentage = percentagesArray[index];
  const color = percentage === 100 ? 'red' : 'green'; 

  return createData(title, percentage, percentage, color); 
});


  return (
    <TableContainer component={CustomPaper} sx={{ borderRadius: 4, overflow: 'hidden',width:"100%", }}>
      <Table sx={{ minWidth: 300 ,width:"100%",minHeight: "320px"}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <ColoredTableCell align='center'>Enrolled Courses</ColoredTableCell>
            <ColoredTableCell align="center" columnIndex={0} >Course Progress</ColoredTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell align='center' component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <ColoredTableCell  columnIndex={0} >
              <Grid container alignItems="center" justifyContent="center">
              {row.progress !== 100  ? (
                <LinearProgress sx={{backgroundColor:"#fff",'& .MuiLinearProgress-bar': {
                  backgroundColor: "#12DD7B", // Set the color based on the 'color' prop in your data
                },height:"15px",borderRadius:10,width:100}} variant="determinate" value={row.progress} />):(<Grid display={"flex"}><RoundTick/><Grid ml={1}> Completed</Grid></Grid>
              )}
              </Grid>
              </ColoredTableCell>
             
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
