import React from 'react'
import CertificateContainer from "../features/certificate/index"

function Certificate() {
  return (
    <div>
    <CertificateContainer/>
    </div>
  )
}

export default Certificate