import { useDispatch, useSelector } from "react-redux";
import {
  fetchcourseimg,
  getcourseimg,
} from "../createcourseslice/createCourseslice";
import { setcourselisturl } from "../createcourseslice/createCourseslice";
import CreateCourseService from "../api";
import { enqueueSnackbar } from "notistack";
import SuccessSnackbar from "../../../components/molecules/customsnackbar/successSnackbar";

const UsecreateCourseservice = () => {
  const dispatch = useDispatch();

  const postcourseimage = async (data, setProfilemodal) => {
    try {
      dispatch(fetchcourseimg(data)).then((res) => {
        if (res?.payload?.data?.status === 200) {
          enqueueSnackbar("Image Uploaded", {
            variant: "success",
            content: (key, message) => (
              <SuccessSnackbar id={key} message={message} allowDownload={true} />
            ),
          });
          setProfilemodal(false);
        }
      });
    } catch (err) {
      console.error("Error dispatching fetchcourseimg:", err);
    }
  };

  const getCourseimg = async (id) => {
    try {
      dispatch(getcourseimg(id)).then((res) => console.log(res, "res"));
    } catch (err) {
      console.error("Error dispatching fetchcourseimg:", err);
    }
  };
  const setcourselistUrl = () => {
    dispatch(setcourselisturl(""));
  };
  const getTableDetails =  (sort)=>{
      return CreateCourseService.getTableDetails(sort)
  }
  const getAllEnrollStatusAndCourse = ()=>{
      return CreateCourseService.getAllEnrollStatusAndCourse()
  }

  const publishCourseApi = (data) => {
    return CreateCourseService.postcoursepublish(data);
  };

  const getpublishApi = (id) => {
    return CreateCourseService.getcoursepublish(id);
  };

  const postSavetoDraft=(data)=>{
    return CreateCourseService.postsavetodraft(data);
  }

  const getsaveDraft=(id)=>{
    return CreateCourseService.getsavecourselist(id);
  }

  const getContentDataLeveltwo=(data)=>{
    return CreateCourseService.getContentDataLevelTwo(data);
  }

  const getContentDataLevelone=(data)=>{
    return CreateCourseService.getContentDataLevelOne(data);
  }

  const getSavepost=(data)=>{
    return CreateCourseService.getSavelistpost(data);
  }

  const postdeletescormone=(data)=>{
    return CreateCourseService.postscormdeletelevelone(data);
  }

  const postdeletescormtwo=(data)=>{
    return CreateCourseService.postscormdeleteleveltwo(data);
  }

  const copyAssetlib=(data)=>{
    return CreateCourseService.copyItemsassetLibrary(data);
  }

  const courseimgUrl = useSelector(
    (state) => state.createCourseReducer.courselistimgUrl
  );

  const loading = useSelector((state) => state.createCourseReducer.loading);

  return {
    postcourseimage: postcourseimage,
    courseimgUrl: courseimgUrl,
    load: loading,
    getCourseimg: getCourseimg,
    setcourselisturl: setcourselistUrl,
    publishCourseapi: publishCourseApi,
    getpublishCourse: getpublishApi,
    postsavetodraft:postSavetoDraft,
    getsavedraft:getsaveDraft,
    getContentDataLevelTwo:getContentDataLeveltwo,
    getContentDataLevelOne:getContentDataLevelone,
    getSavepost:getSavepost,
    postdeletescormone,
    postdeletescormtwo,
    getTableDetails,
    getAllEnrollStatusAndCourse,
    copyAssetlib
  };
};

export default UsecreateCourseservice;
