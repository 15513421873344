import { Box, Grid, Typography, Collapse } from "@mui/material";
import React, { useState } from "react";
import LayoutModal from "../../../components/atoms/layoutModal/LayoutModal";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Images from "../../../constants/imageConstants";
import Excelupload from "./Excelupload";

function Uploadquestion({ setUploadquestionmodal, uploadquestionmodal,setPushload }) {
  const [expanded, setExpanded] = useState(null);
  const [fileup, setFileup] = useState("");

  const handleExpandClick = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  const mapicons = [
    {
      id: 1,
      icon: Images.EXCEL,
      question: "Upload simple questions with textual content.",
      details: "Details for question 1",
    },
    // { id: 2, icon: Images.EXCEL, question: "Upload simple questions with textual content.", details: "Details for question 2" },
    // { id: 3, icon: Images.EXCEL, question: "Upload simple questions with textual content.", details: "Details for question 3" }
  ];

  const resetuploadqstn = () => {
    setFileup("");
    setExpanded(null);
  };

  const subitemcomponent = (id) => {
    if (id === 1) {
      return <Excelupload setUploadquestionmodal={setUploadquestionmodal} setExpanded={setExpanded} setPushload={setPushload} fileup={fileup} setFileup={setFileup} />;
    } else if (id === 2) {
      return <Grid>hello</Grid>;
    } else if (id === 3) {
      return <Grid>hooo</Grid>;
    }
  };

  return (
    <LayoutModal
      newfil={"reset"}
      resetall={resetuploadqstn}
      handleModal={uploadquestionmodal}
      setHandleModal={setUploadquestionmodal}
      w={"60%"}
    >
      <Grid
        pl={4}
        pt={4}
        pr={4}
        item
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Typography fontFamily={POPPINS} fontSize={17}>
            Upload Questions
          </Typography>
        </Box>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setUploadquestionmodal(false);
            resetuploadqstn();
          }}
        >
          <CloseIcon />
        </Box>
      </Grid>

      <Grid mt={5} pl={4} pb={4} pr={4}>
        {mapicons?.map((itm) => (
          <Box key={itm.id}>
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              onClick={() => handleExpandClick(itm.id)}
            >
              <KeyboardArrowRightIcon
                sx={{
                  fontSize: 40,
                  opacity: 0.5,
                  transform:
                    expanded === itm.id ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
              <img src={itm.icon} width={40} alt="icon" />
              <Typography fontFamily={POPPINS} fontSize={14} ml={2}>
                {itm.question}
              </Typography>
            </Box>
            <Collapse in={expanded === itm.id}>
              {subitemcomponent(itm.id)}
            </Collapse>
          </Box>
        ))}
      </Grid>
    </LayoutModal>
  );
}

export default Uploadquestion;
