import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Tooltip,
  Modal,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Checkbox,
  FormControl,
  FormControlLabel,
  Box,
  OutlinedInput,
} from "@mui/material";

import { Delete } from "@mui/icons-material";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import { isDate } from "../../../components/molecules/tables/datefunc";
import RoundTick from "../../../assets/svgimages/roundTick";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import UseCertificateHooks from "../../certificate/hooks";
import { SuspenseLoader } from "../../../components/loader/susenseloader";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ViewHeadlineOutlinedIcon from "@mui/icons-material/ViewHeadlineOutlined";
import { getContentid } from "../../createcourse/functionhelpers/contentId";
import LayoutModal from "../../../components/atoms/layoutModal/LayoutModal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Coursetableview from "./Coursestableview";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import JoditEditor from "jodit-react";
import { Jodit } from "jodit";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import UsecreateCourseservice from "../../createcourse/hooks";
import { useSnackbar } from "notistack";
import SuccessSnackbar from "../../../components/molecules/customsnackbar/successSnackbar";
import { formatDate } from "../../../functionalities/datefunc";
import { useNavigate } from 'react-router-dom';

function AssetLibraryViewTable({
  data,
  deletefunc,
  tableHeadrowval,
  loaderdelete,
  setRefresh,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [certificateload, setCertificateload] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [titletext, setTitletext] = useState("");
  const [isLoading,setIsLoading]=useState({level1:false,level2:false,save:false,})
  const [alavailables, setAlavailable] = useState(false);
  const [selectedrow, setSelectedrow] = useState([]);
  const [listclickdata, setListlickdata] = useState([]);
  const editor = useRef(null);
  const { enqueueSnackbar } = useSnackbar();


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

const navigate=useNavigate()

  const configDescription = useMemo(() => {
    return {
      placeholder: "Description",
      height: "280px",
      toolbarSticky: false,
      pasteHTMLActionList: Jodit.atom([
        { value: Jodit.constants.INSERT_ONLY_TEXT, text: "Insert only Text" },
      ]),
    };
  }, []);
  const { getContentDataLevelOne, getContentDataLevelTwo,getSavepost } =
    UsecreateCourseservice();

  useEffect(() => {
    contentgetlevelfn();
  }, [selectedrow]);

  const contentgetlevelfn = () => {
    if (selectedrow.Level === 1) {
      const data = {
        module_id: selectedrow?.ModuleId,
        content_type: selectedrow?.contentType,
        content_id: selectedrow?.contentid,
      };
      setIsLoading((prev)=>{
        return{...prev,level1:true}
      })
      getContentDataLevelOne(data).then((res) => {
        if (res?.data?.status === 200) {
          setIsLoading((prev)=>{
            return{...prev,level1:false}
          })
          setListlickdata(res?.data?.data?.courseContentData);
          setAlavailable(res?.data?.data?.courseContentData[0]?.IsSharable===1?true:false)
        }else{
          setIsLoading((prev)=>{
            return{...prev,level1:false}
          })
        }
      });
    } else if (selectedrow.Level === 2) {
      const data = {
        content_type: selectedrow?.contentType,
        chapter_content_id: selectedrow?.ChapterContentId,
      };
      setIsLoading((prev)=>{
        return{...prev,level2:true}
      })
      getContentDataLevelTwo(data).then((res) => {
        if (res?.data?.status === 200) {
          setIsLoading((prev)=>{
            return{...prev,level2:false}
          })
          setListlickdata(res?.data?.data?.courseContentData);
          setAlavailable(res?.data?.data?.courseContentData[0]?.IsSharable===1?true:false)
        }else{
          setIsLoading((prev)=>{
            return{...prev,level2:false}
          })
        }
      });
    }
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const alavailable = () => {
    setAlavailable(!alavailables);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowMouseEnter = (rowId) => {
    setHoveredRow(rowId);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleDeleteButtonClick = (rowId) => {
    setDeleteItemId(rowId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // const listclickdata=[{Url:"fdkfjdkfdkfj",TextContent:"dfrgfgrg",Link:"dkfdkfjdl"}]

  const config = useMemo(() => {
    return {
      placeholder: listclickdata[0]?.TextContent ? "" : "Start Typing",
      height: "450px",
      toolbarSticky: false,
      pasteHTMLActionList: Jodit.atom([
        { value: Jodit.constants.INSERT_ONLY_TEXT, text: "Insert only Text" },
      ]),
    };
  }, []);

  const contentdisplay = (item) => {
    switch (getContentid(item.contentType)) {
      case "FILE":
        return (
          <Box
            mt={2}
            pl={4}
            pr={4}
            width={"100%"}
            height={"50%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              pl={1}
              pr={1}
              pt={1}
              pb={1}
              border={"1px solid"}
              alignItems={"center"}
              display={"flex"}
            >
              <DownloadOutlinedIcon />
              <a href={listclickdata[0]?.Url ?? null}>Download</a>
            </Box>
          </Box>
        );
      case "TEXT":
        return (
          <Box sx={{height:"50%"}} pl={4} pr={4} mt={2}>
            <JoditEditor
              ref={editor}
              value={listclickdata[0]?.TextContent??""}
              config={config}
                   onChange={(e) => {
              setListlickdata((prevData) => [
              {
              ...prevData[0],
              TextContent: e,
              },
              ...prevData.slice(1),
              ]);
              }}
            />
          </Box>
        );
      case "PDF":
        return (
          <Box mt={2} pl={4} pr={4} width={"100%"} height="50%">
            {/* <Box mt={2} display={"flex"} justifyContent={"space-between"}>
      <Typography fontFamily={POPPINS}
      display={"flex"}>
        Page <Typography
        fontFamily={POPPINS}
        sx={{backgroundColor:"#fff",width:30,height:25,alignItems:"center",display:"flex",justifyContent:"center"}}>
        {page}</Typography>/ {totalpage}
      </Typography>
      <Button onClick={goToNextPage}>Next</Button>
    </Box> */}
            <Box>
              <iframe
                src={listclickdata[0]?.Url ?? ""}
                width="100%"
                height="529px"
                allow="fullscreen"
                title="Video Title"
                allowFullScreen
              />
              {/* <Document
    file='https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    onLoadSuccess={onDocumentLoadSuccess}
  >
    {Array.from(new Array(1), (el, index) => (
      <Page
        key={`page_${index + 1}`}
        pageNumber={index + 1}
      />
    ))}
  </Document> */}
            </Box>
          </Box>
        );
      case "SCORM":
        return (
          <Box pl={4} pr={4} mt={6} display="flex" justifyContent="center">
            <iframe
              src={listclickdata[0]?.Url ?? ""}
              width="100%"
              height="529px"
              allowFullScreen
              style={{ border: "none" }}
            />
          </Box>
        );
      case "LINK":
        return (
          <Box pl={4} pr={4} mt={2}>
            <Box
              display={"flex"}
              alignItems={"center"}
              mt={6}
              justifyContent={"center"}
            >
              <iframe
                // src={listclickdata[0]?.Link??""}
                width="100%"
                height="529px"
                allow="fullscreen"
                title="Video Title"
                allowFullScreen
              />
            </Box>
          </Box>
        );
      case "HEADING":
        return <Grid>heading</Grid>;
    }
  };

  const TableHeaderandRowdetailes = [
    {
      id: 1,
      name: "Created Date",
      rowDetailes: "cdate",
    },
    {
      id: 2,
      name: "Modified Date",
      rowDetailes: "mdate",
    },
    {
      id: 3,
      name: "Title",
      rowDetailes: "title",
    },

    {
      id: 6,
      name: "Actions",
      rowDetailes: null,
    },
  ];

  const datas = [
    {
      Id: 1,
      cdate: "2023-05-01",
      mdate: "2023-05-20",
      title: "Sample Title 1",
      Type: 1,
    },
    {
      Id: 2,
      cdate: "2023-04-15",
      mdate: "2023-05-10",
      title: "Sample Title 2",
      Type: 2,
    },
  ];

  const handleClose = () => {
    setHandleModal(false);
  };

  const contentsvg = (type) => {
    // if(type===1){
    //     return(<>
    //         <NotesOutlinedIcon/>
    //         <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>Text</Typography>
    //         </>
    //     )
    // }

    switch (getContentid(type)) {
      case "SCORM":
        return (
          <>
            <FolderZipOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Scorm
            </Typography>
          </>
        );
      case "FILE":
        return (
          <>
            <InsertDriveFileOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              File
            </Typography>
          </>
        );
      case "LINK":
        return (
          <>
            <AttachmentOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Link
            </Typography>
          </>
        );
      case "HEADING":
        return (
          <>
            <ViewHeadlineOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Heading
            </Typography>
          </>
        );
      case "TEXT":
        return (
          <>
            <NotesOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Text
            </Typography>
          </>
        );
      case "PDF":
        return (
          <>
            <PictureAsPdfOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Pdf
            </Typography>
          </>
        );
      default:
        return null;
    }
  };

  const savecontent=()=>{
    if(selectedrow?.Level===1){
      const data={
        module_id:selectedrow?.ModuleId,
        content_type:selectedrow?.contentType,
        content_id:selectedrow?.contentid,
        course_id:parseInt(selectedrow?.CourseId),
        level:1,
        title:listclickdata[0]?.Title??null,
        text:listclickdata[0]?.Title??null,
        tags:listclickdata[0]?.Tags??null,
        description:listclickdata[0]?.TextContent??null,
        is_shareable:alavailables??null
      }
      getSavepost(data).then(res=>{
        setIsLoading((prev)=>{
          return{...prev,save:true}
        })
        if(res?.data?.status===200){
          setIsLoading((prev)=>{
            return{...prev,save:false}
          })
          setDetailModal(false)
          setRefresh(prev=>!prev)
          enqueueSnackbar("updated", {
            variant: "success",
            content: (key, message) => (
              <SuccessSnackbar id={key} message={message} allowDownload={true} />
            ),
          });
         
        }else{
          setIsLoading((prev)=>{
            return{...prev,save:false}
          })
        }
      })
    }else if(selectedrow?.Level===2){

        const data={
          content_type:selectedrow?.contentType,
          chapter_content_id:selectedrow?.ChapterContentId,
          course_id:selectedrow?.CourseId,
          level:2,
          title:listclickdata[0]?.Title??null,
          text:listclickdata[0]?.Title??null,
          tags:listclickdata[0]?.Tags??null,
          description:listclickdata[0]?.TextContent??null,
          is_shareable:alavailables??null
        }

        setIsLoading((prev)=>{
          return{...prev,save:true}
        })

        getSavepost(data).then(res=>{
            if(res?.data?.status===200){
              setIsLoading((prev)=>{
                return{...prev,save:false}
              })
              setDetailModal(false)
              setRefresh(prev=>!prev)
              enqueueSnackbar("updated", {
                variant: "success",
                content: (key, message) => (
                  <SuccessSnackbar id={key} message={message} allowDownload={true} />
                ),
              });
        }

        })
    }
  }



  return (
    <>
      <SuspenseLoader loading={certificateload} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadrowval?.map((itm, k) => (
                <React.Fragment>
                  <TableCell
                    key={k}
                    sx={{ fontFamily: POPPINS, fontWeight: 500 }}
                  >
                    {itm?.name ?? "--"}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? ( // Check if data array is empty
              <TableRow>
                <TableCell colSpan={tableHeadrowval.length}>
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography fontFamily={POPPINS} fontSize={14}>
                      No data are available
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.id}
                  onMouseEnter={() => handleRowMouseEnter(row.id)}
                  onMouseLeave={handleRowMouseLeave}
                  sx={{
                    backgroundColor:
                      hoveredRow === row.id ? "#f5f5f5" : "transparent",
                    cursor: "pointer",
                  }}
                >
                  <TableCell>
                    <Grid display={"flex"} alignItems={"center"}>
                      <Typography fontSize={14} fontFamily={POPPINS}>
                        {formatDate(row?.Created_at) ?? "--"}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14} fontFamily={POPPINS}>
                      {formatDate(row?.Updated_at) ?? "--"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Grid>
                      <Grid>
                        <Typography fontSize={14} fontFamily={POPPINS}>
                          {row?.Title ?? "--"}
                        </Typography>
                        {row?.Title ? (
                          <Grid display={"flex"}>
                            <Grid
                              p={"3px"}
                              sx={{
                                backgroundColor: "#e5e5e5;",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              {contentsvg(row?.contentType)}
                            </Grid>
                            <Grid></Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid   onClick={()=>                          navigate(`/userdetails/${row?.UserId}`)
                  } gap={"4px"} display={"flex"} alignItems={"center"}>
                      <Typography 
                    
                        display={"flex"}
                        fontSize={12}
                        alignItems={"center"}
                        justifyContent={"center"}
                        width={20}
                        height={22}
                        sx={{
                          backgroundColor: "#5755d9;",
                          borderRadius: 1800,
                          color: "#fff",
                        }}
                        p={1}
                      >
                        {row?.Username?.charAt(0) ?? ""}
                      </Typography>
                      <Typography
                        sx={{
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                        fontSize={14}
                        fontFamily={POPPINS}
                      >
                        {row?.Username ?? "--"}
                      </Typography>
                    </Grid>
                  </TableCell>

                  <TableCell>
                    <Tooltip title="Detail">
                      <IconButton
                        onClick={() => {
                          setDetailModal(true);
                          setSelectedrow(row);
                        }}
                        style={{
                          color: hoveredRow === row.id ? "blue" : "transparent",
                        }}
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <LayoutModal
        w={"50%"}
        handleModal={detailModal}
        setHandleModal={setDetailModal}
      >
        <Box p={4}>
          <Grid Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={() => setDetailModal(false)}>
              <CancelOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              onClick={savecontent}
              variant="contained"
              style={{ textTransform: "none" }}
            >
              Save
            </Button>
          </Grid>
          <Grid>
            <Box mt={4}>
              <Typography fontSize={13} fontWeight={600} fontFamily={POPPINS}>
                Title
              </Typography>
              <OutlinedInput
                sx={{
                  width: "100%",
                  mt: 1,
                  backgroundColor: "#fff",
                  height: "40px",
                  fontFamily: POPPINS,
                  fontSize: 13,
                }}
                placeholder={listclickdata[0]?.Title ?? "Title"}
                value={listclickdata[0]?.Title ?? ""}
                onChange={(e) => {
                  // Update the state with the new value when the input changes
                  setListlickdata((prevData) => [
                    {
                      ...prevData[0],
                      Title: e.target.value,
                    },
                    ...prevData.slice(1),
                  ]);
                }}
              />
            </Box>
            <Box mt={2}>
          
              <Box display={"flex"}>
                <Box>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={alavailables}
                          onChange={alavailable}
                          color="primary"
                          style={{
                            transform: "scale(0.8)", // Scale down the checkbox size
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: POPPINS, fontSize: 12 }}
                        >
                          Enable sharing
                        </Typography>
                      }
                    />
                  </FormControl>
                </Box>
                <Box></Box>
              </Box>
            </Box>
                      <Box sx={{ overflowY: 'auto',
                      maxHeight: '400px', }}>
                      {contentdisplay(selectedrow)}

                      </Box>
          </Grid>
        </Box>
      </LayoutModal>

      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Paper
          style={{
            position: "absolute",
            width: 400,
            backgroundColor: "white",
            padding: 20,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography fontSize={14} fontFamily={POPPINS} variant="h6">
            Are you sure you want to delete this item?
          </Typography>
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Button
              onClick={() => deletefunc(deleteItemId)}
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
            >
              {loaderdelete ? (
                <CircularProgress sx={{ color: "#fff" }} size={25} />
              ) : (
                "Delete"
              )}
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              sx={{ color: "#000" }}
            >
              Cancel
            </Button>
          </Grid>
        </Paper>
      </Modal>
      <LayoutModal
        w={"80%"}
        h={"60%"}
        handleModal={handlemodal}
        setHandleModal={setHandleModal}
      >
        <Grid
          p={2}
          borderBottom={"1px solid"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography fontSize={16} fontFamily={POPPINS} fontWeight={600}>
            Courses
          </Typography>
          <Grid onClick={handleClose}>
            <CancelOutlinedIcon />
          </Grid>
        </Grid>
        <Grid
          sx={{
            maxHeight: "300px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          mt={4}
          p={4}
        >
          <Coursetableview
            data={datas}
            tableHeadrowval={TableHeaderandRowdetailes}
          />
        </Grid>
      </LayoutModal>
      <SuspenseLoader loading={isLoading.save}/>
      <SuspenseLoader loading={isLoading.level1}/>
      <SuspenseLoader loading={isLoading.level2}/>
    </>
  );
}

export default AssetLibraryViewTable;

// <TableCell>
// <Grid display={"flex"}>
//   <Typography
//   onClick={()=>setHandleModal(true)}
//     fontSize={12}
//     alignItems={"center"}
//     justifyContent={"center"}
//     sx={{ '&:hover': {
//         textDecoration: 'underline',
//         color:"blue"
//       },}}
//   >
//     {row?.uin}
//   </Typography>
// </Grid>
// </TableCell>

//   <Box mt={2} >
//       <Typography  fontWeight={600}
//       fontFamily={POPPINS} fontSize={13}
//       >TAGS comma separated for multiple tags</Typography>
//       <OutlinedInput
//       disabled={true}
//         sx={{
//           width: "100%",
//           mt: 1,
//           backgroundColor: "#fff",
//           height: "40px",
//           fontFamily: POPPINS,
//           fontSize: 13,
//         }}
//         placeholder="Tags"

//       />
// </Box>
