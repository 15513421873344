import React from 'react';
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

function SkeletonTable() {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {/* Skeletons for rows */}
          <TableRowSkeleton />
          <TableRowSkeleton />
          <TableRowSkeleton />
          <TableRowSkeleton />
          <TableRowSkeleton />
          <TableRowSkeleton />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TableRowSkeleton() {
  return (
    <TableRow>
      <TableCellSkeleton />
      <TableCellSkeleton />
      <TableCellSkeleton />
    </TableRow>
  );
}

function TableCellSkeleton() {
  return (
    <TableCell>
      <Skeleton />
    </TableCell>
  );
}

export default SkeletonTable;
