import React, { useEffect } from 'react'
import Reports from "../features/report/index"
import { useNavigate } from 'react-router-dom';
import { setNavigateFunction } from '../services/axiosservice';

function Report() {
  const navigate = useNavigate();
  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);
  return (
    <div>
    <Reports/>
    </div>
  )
}

export default Report