import './App.css';
import { Suspense, useEffect } from "react";
import { store } from './store/store'
import { Provider } from 'react-redux'
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import AppLoader from './components/atoms/apploader/appLoader';
import RoutesComponent from './routes/routeComponent';
import CustomSnackbarProvider from './components/molecules/customsnackbar/customSnackbarprovider';
import { lightTheme,darkTheme } from './utils/theme';
import { getCookie } from './helpers/CookieHelpers';
import { HttpClient } from './services/http.service';

function App() {
  // useEffect(()=>{
  //   const token=getCookie("token")
  //   console.log("tokkkkkk",token);
  //   HttpClient.interceptors(token)
  //       },[])

  return (
    <ThemeProvider theme={""}>
    <StyledEngineProvider injectFirst>
    <Suspense fallback={<AppLoader />}>
    <Provider store={store}>
    <CustomSnackbarProvider>
    <RoutesComponent/>
    </CustomSnackbarProvider>
    </Provider>
    </Suspense>
    </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
