import { Box, CircularProgress } from "@mui/material";

export const SuspenseLoader = ({loading}) => (
   loading?(
    <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      zIndex: 9999, 
    }}
  >
    <CircularProgress />
  </Box>
   ) :""
 
  );