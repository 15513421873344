import { styled } from '@mui/system';

export const StyledImgDiv = styled('div')({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    width:"100%",
    height:'100%',
    gap:"20px",
    padding:"10px 45px",
    '@media (max-width: 765px)': {
      padding: "15px"
           
    },
   
  });

  export const StyledPencilDiv = styled('div')({position:"absolute",
  right:'-10px',
  bottom:'-15px',
   background:"#454d5d",
   color:'white',
   padding:"10px",
   borderRadius:"50%",
   cursor:"pointer"})
  export const CreateModifiedDiv = styled('div')(
    {
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',
        marginRight:2
    }
  )

