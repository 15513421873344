import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Tooltip,
  Modal,
  Button,
  Typography,
  Grid,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import { isDate } from "../../../components/molecules/tables/datefunc";
import RoundTick from "../../../assets/svgimages/roundTick";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import UseCertificateHooks from "../../certificate/hooks";
import { SuspenseLoader } from "../../../components/loader/susenseloader";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ViewHeadlineOutlinedIcon from "@mui/icons-material/ViewHeadlineOutlined";
import { getContentid } from "../../createcourse/functionhelpers/contentId";
import LayoutModal from "../../../components/atoms/layoutModal/LayoutModal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";


function Coursetableview({
  data,
  deletefunc,
  tableHeadrowval,
  loaderdelete,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [certificateload, setCertificateload] = useState(false);
  const [handlemodal,setHandleModal]=useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { certificategenerate } = UseCertificateHooks();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowMouseEnter = (rowId) => {
    setHoveredRow(rowId);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleDeleteButtonClick = (rowId) => {
    setDeleteItemId(rowId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleClickcertificate = (id, title) => {
    setCertificateload(true);
    certificategenerate(id).then((res) => {
      if (res?.data?.status === 200) {
        setCertificateload(false);
        const downloadLink = document.createElement("a");
        downloadLink.href = res?.data?.certificateUrl;
        downloadLink.target = "_blank";
        downloadLink.download = `${title}.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    });
  };

  const handleClose=()=>{
    setHandleModal(false)
  }

  const contentsvg = (type) => {
    // if(type===1){
    //     return(<>
    //         <NotesOutlinedIcon/>
    //         <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>Text</Typography>
    //         </>
    //     )
    // }

    switch (getContentid(type)) {
      case "SCORM":
        return (
          <>
            <FolderZipOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Scorm
            </Typography>
          </>
        );
      case "FILE":
        return (
          <>
            <InsertDriveFileOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              File
            </Typography>
          </>
        );
      case "LINK":
        return (
          <>
            <AttachmentOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Link
            </Typography>
          </>
        );
      case "HEADING":
        return (
          <>
            <ViewHeadlineOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Heading
            </Typography>
          </>
        );
      case "TEXT":
        return (
          <>
            <NotesOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Text
            </Typography>
          </>
        );
      case "PDF":
        return (
          <>
            <PictureAsPdfOutlinedIcon />
            <Typography fontFamily={POPPINS} fontSize={12} p={"2px"}>
              Pdf
            </Typography>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <SuspenseLoader loading={certificateload} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadrowval?.map((itm, k) => (
                <React.Fragment>
                  <TableCell
                    key={k}
                    sx={{ fontFamily: POPPINS, fontWeight: 500 }}
                  >
                    {itm?.name}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? ( // Check if data array is empty
              <TableRow>
                <TableCell colSpan={tableHeadrowval.length}>
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography fontFamily={POPPINS} fontSize={14}>
                      No data are available
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.Id}
                  onMouseEnter={() => handleRowMouseEnter(row.Id)}
                  onMouseLeave={handleRowMouseLeave}
                  sx={{
                    backgroundColor:
                      hoveredRow === row.Id ? "#f5f5f5" : "transparent",
                    cursor: "pointer",
                  }}
                >
                  <TableCell>
                    <Grid display={"flex"} alignItems={"center"}>
                    <img alt={"hoiii"}/>
                      <Typography fontSize={14} fontFamily={POPPINS}>
                        {row?.cdate}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14} fontFamily={POPPINS}>
                      {row?.mdate}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Grid>
                      <Grid>
                        <Typography fontSize={14} fontFamily={POPPINS}>
                          {row?.title}
                        </Typography>
                        <Grid display={"flex"}>
                          <Grid
                            p={"3px"}
                            sx={{
                              backgroundColor: "#e5e5e5;",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {contentsvg(row?.Type)}
                          </Grid>
                          <Grid></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
             
                  <TableCell>
                    <Tooltip title="delete">
                      <IconButton
                        onClick={() => handleDeleteButtonClick(row?.Id)}
                        style={{
                          color: hoveredRow === row.Id ? "#000" : "transparent",
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Paper
          style={{
            position: "absolute",
            width: 400,
            backgroundColor: "white",
            padding: 20,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography fontSize={14} fontFamily={POPPINS} variant="h6">
            Are you sure you want to delete this item?
          </Typography>
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Button
              onClick={() => deletefunc(deleteItemId)}
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
            >
              {loaderdelete ? (
                <CircularProgress sx={{ color: "#fff" }} size={25} />
              ) : (
                "Delete"
              )}
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              sx={{ color: "#000" }}
            >
              Cancel
            </Button>
          </Grid>
        </Paper>
      </Modal>
      <LayoutModal w={"80%"} h={"80%"} handleModal={handlemodal} setHandleModal={setHandleModal}>
      <Grid
            p={2}
            borderBottom={"1px solid"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={16} fontFamily={POPPINS} fontWeight={600}>
              Courses
            </Typography>
            <Grid onClick={handleClose}>
              <CancelOutlinedIcon />
            </Grid>
          </Grid>
      </LayoutModal>
    </>
  );
}

export default Coursetableview;
