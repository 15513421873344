import { Grid, Typography } from "@mui/material";
import React from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import Edittable from "./edittable";
function Editviewpage({
  setViewcontainer,
  questionData,
  topic,
  subject,
  type,
  setIsModaleditOpens,
  setPushload,
  setFetchchange,
}) {
  return (
    <Grid container>
      <Grid container alignItems={"center"} sx={{ backgroundColor: "#e5e5e5" }}>
        <Grid
          sx={{ cursor: "pointer" }}
          display={"flex"}
          alignItems={"center"}
          onClick={() => {
            setViewcontainer("VIEW");
          }}
        >
          <ArrowBackOutlinedIcon />
        </Grid>
        <Typography ml={1} fontFamily={POPPINS} fontSize={23} fontWeight={500}>
          Edit question
        </Typography>
      </Grid>
      <Grid width={"100%"} mt={2}>
        <Edittable
          setFetchchange={setFetchchange}
          topic={topic}
          setViewcontainer={setViewcontainer}
          subject={subject}
          type={type}
          questionData={questionData}
          setPushload={setPushload}
          setIsModaleditOpens={setIsModaleditOpens}
        />
      </Grid>
    </Grid>
  );
}

export default Editviewpage;
