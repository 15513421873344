import { Box, TextField, Typography } from '@mui/material'
import React from 'react'
import { POPPINS } from '../../../constants/fontConstantsPoppins'


function InputField({name,placeHolder,title,value,handleChange,register,disabled}) {
 

  return (
    
     <Box style={{display:'flex',flexDirection:'column',gap:"10px"}}>
        <Typography    variant='h6' fontFamily={POPPINS} fontWeight={600} fontSize={"13px"}>{title}</Typography>
        <TextField  {...(register && { ...register })}
        
        sx={{backgroundColor:"#fff"}} 
        InputProps={{style:{
          fontSize:"13px"
        }
        }} 
        style={{ fontSize: "13px",fontFamily:POPPINS }}
        name={name}  
         fullWidth 
         placeholder={placeHolder} 
         id={name}
         value={value}
         onChange={handleChange}
         disabled={disabled?true:false}
         
          />
  </Box>


  )
}

export default InputField