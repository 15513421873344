import { Box, CircularProgress, Grid, Skeleton } from "@mui/material";
import React from "react";
import { Chart } from "react-google-charts";



export const options = {
  title: "Course progress",
  
  legend: {
    position: "bottom",
  },
//   #FDED5C
  colors: ["#5786FF", "#E57373", "#5CFDB2","#4FC3F7","#AED581","#F8BBD0","#4DB6AC","#81C784","#FFD54F","#FFB74D","#90A4AE","#FFF176","#8D6E63","#BA68C8"],
  pieSliceTextStyle: {
    color: "#fff", // Set the percentage label text color
    // fontSize: 14, 
    fontWeight: "bold",
    boxShadow: "10px",
  },
};
const Skeletoncircleprogress=()=>{
 
  return(
    <Grid width={300} height={300} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
    <Skeleton variant="circular" width={200} height={200}/>
    <Grid display={"flex"} flexDirection={"row"}>
    <Skeleton sx={{ml:2}} width={20}/>
    <Skeleton sx={{ml:2}} width={20}/>
    <Skeleton sx={{ml:2}} width={20}/>
    </Grid>
    </Grid>
  )
}

export function Charts({loadercourse,detailData,title}) {
  const options = {
    title: title,
    
    legend: {
      position: "bottom",
    },
  //   #FDED5C
    colors: ["#5786FF", "#E57373", "#5CFDB2","#4FC3F7","#AED581","#F8BBD0","#4DB6AC","#81C784","#FFD54F","#FFB74D","#90A4AE","#FFF176","#8D6E63","#BA68C8"],
    pieSliceTextStyle: {
      color: "#fff", // Set the percentage label text color
      // fontSize: 14, 
      fontWeight: "bold",
      boxShadow: "10px",
    },
  };



  const converter = detailData?detailData.map(({CourseTitle,EnrollCount})=>[CourseTitle,EnrollCount]):[];
  converter.unshift(["Task","Hours per Day"])
  console.log(converter)
  const data = converter;

  return (
    <div style={{ borderRadius: "20px", overflow: "hidden", }}>
    {
      loadercourse?<Skeletoncircleprogress/>:""
    }
      <Box display={loadercourse?"none":"block"}>
      <Chart
      loader={<Skeletoncircleprogress/>}
        chartType="PieChart"
        data={data}
        options={options}
        width={"100%"}
        height={"220px"}
        
      
      />
      </Box>
    </div>
  );
}
