import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Tooltip,
  Modal,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { POPPINS } from "../../../constants/fontConstantsPoppins";
import { SuspenseLoader } from "../../../components/loader/susenseloader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Detailsview from "./detailsview";
import QuestionBankservice from "../hooks/questionbankservice";
import DisplayHtmlContent from "../../../components/atoms/htmlcontentdisplay/index"
import { formatDate } from "../../../functionalities/datefunc";

function Questiontable({ data, tableHeadrowval, loaderdelete,setPushload }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModaleditOpen, setIsModaleditOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [certificateload, setCertificateload] = useState(false); 
  const [questionData,setQuestionData] = useState({})
  const [createdAT,setCreatedAT] = useState("");
  const {getEditDataHook, } = QuestionBankservice();
  const [detailload, setDetailload] = useState(false); 

  const [rowids,setRowids]=useState(null)
  const [qustnids,setQustnids]=useState(null)


  const handleChangePage = (event, newPage) => {
    console.log(event);
    setPage(newPage);
  };

  const { deleteqstnrow } = QuestionBankservice()

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowMouseEnter = (rowId) => {
    setHoveredRow(rowId);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleDeleteButtonClick = (rowId,qustnid) => {
    setDeleteItemId(rowId);
    setQustnids(qustnid)
    setIsModalOpen(true);
  };

const handleViewButtonClick=async (rowId,qustnid)=>{
  setDetailload(true)
setDeleteItemId(rowId);
setQustnids(qustnid)
  const res = await getEditDataHook(rowId)
  if(res?.data?.data?.questionStructure){
    setCreatedAT(res?.data?.data?.Created_at)
    setQuestionData(JSON.parse(res?.data?.data?.questionStructure))
    setDetailload(false)
  }else{
    setQuestionData({})
    setDetailload(false)
  }
  setIsModaleditOpen(true)
}

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleeditClose = () => {
    setIsModaleditOpen(false);
  };

  const deleteqstns=()=>{
    setCertificateload(true)
    const data={
      question_id:deleteItemId,
      question_type_id:qustnids
    }
    deleteqstnrow(data).then(res=>{
  if(res?.data?.status===200){
setCertificateload(false)
setPushload(prev=>!prev)
  }else{
    setCertificateload(false)
  }
})
  }

  const gettype = (id) => {
    return (
      <Typography
        p={"3px"}
        fontFamily={POPPINS}
        fontSize={12}
        sx={{ backgroundColor: "#e5e5e5" }}
      >
        {getNameById(id)}
      </Typography>
    );
  };

  const type = [
    { id: 1, name: "Single Correct Option" },
    { id: 2, name: "Multiple Correct Option" },
    {
      id: 3,
      name: "Numerical/Fill in the blank",
    },
    { id: 4, name: "Subjective" },
  ];

  function getNameById(id) {
    const typeObject = type.find((item) => item.id === id);
    return typeObject ? typeObject.name : null;
  }

  return (
    <>
      <SuspenseLoader loading={certificateload} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadrowval?.map((itm, k) => (
                <React.Fragment>
                  <TableCell
                    key={k}
                    sx={{ fontFamily: POPPINS, fontWeight: 500 }}
                  >
                    {itm?.name}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? ( // Check if data array is empty
              <TableRow>
                <TableCell colSpan={tableHeadrowval.length}>
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography fontFamily={POPPINS} fontSize={14}>
                      No data are available
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.id}
                  onMouseEnter={() => handleRowMouseEnter(row.id)}
                  onMouseLeave={handleRowMouseLeave}
                  sx={{
                    backgroundColor:
                      hoveredRow === row.id ? "#f5f5f5" : "transparent",
                    cursor: "pointer",
                  }}
                >
                  <TableCell>
                    <Grid display={"flex"} alignItems={"center"}>
                      <Typography fontSize={12} fontFamily={POPPINS}>
                        {formatDate(row?.created_at)}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <grid>
                      <Typography fontSize={12} width={"200px"} fontFamily={POPPINS}>
                      <DisplayHtmlContent htmlContent={row?.questiontext ?? ""} />
                        {/* {row?.questiontext} */}
                      </Typography>
                      <Grid display={"flex"}>
                        {gettype(row?.questiontypeid)}
                        <Typography
                          fontFamily={POPPINS}
                          fontSize={12}
                          sx={{ backgroundColor: "#e5e5e5", ml: 1 }}
                          p={"3px"}
                        >
                          +{row?.Mark}, -{row?.Penalty}
                        </Typography>
                      </Grid>
                    </grid>
                  </TableCell>
                  <TableCell>
                    <Grid>
                      <Grid>
                        <Typography fontSize={12} fontFamily={POPPINS}>
                          {row?.subject_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={12} fontFamily={POPPINS}>
                      {row?.topic_text}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="detail">
                      <IconButton
                        onClick={() => handleViewButtonClick(row?.id,row?.questiontypeid)}
                        style={{
                          color: hoveredRow === row.id ? "blue" : "transparent",
                        }}
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="delete">
                      <IconButton
                        onClick={() => handleDeleteButtonClick(row?.id,row?.questiontypeid)}
                        style={{
                          color: hoveredRow === row.id ? "red" : "transparent",
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Paper
          style={{
            position: "absolute",
            width: 400,
            backgroundColor: "white",
            padding: 20,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography fontSize={14} fontFamily={POPPINS} variant="h6">
            Are you sure you want to delete this item?
          </Typography>
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Button
              onClick={
            deleteqstns
          }
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
            >
              {loaderdelete ? (
                <CircularProgress sx={{ color: "#fff" }} size={25} />
              ) : (
                "Delete"
              )}
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              sx={{ color: "#000" }}
            >
              Cancel
            </Button>
          </Grid>
        </Paper>
      </Modal>
     
      <Detailsview
      createdAT = {createdAT?.split(" ")[0].split("-")}
      DeleteItemId={deleteItemId}
      Qustnids={qustnids}
       questionData={questionData}
      setPushload={setPushload}
      rowids={rowids}
        isModaleditOpens={isModaleditOpen}
        setIsModaleditOpens={setIsModaleditOpen}
      />
      <SuspenseLoader loading={detailload}/>
    </>
  );
}

export default Questiontable;
