import React from 'react'
import User from "../features/users/index"

function Users() {
  return (
    <div>
    <User/>
    </div>
  )
}

export default Users