import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from "@mui/material/Typography";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import CoursesBuilderIcon from './CoursesBuilderIcon';
import { POPPINS } from '../../../constants/fontConstantsPoppins';
import { useNavigate, useParams } from 'react-router-dom';
 function SidebarList() {
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const navigate = useNavigate()
  const {id} = useParams()
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Box sx={{ width: '100%',   backgroundColor: "#F4F5F7",  }}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton
          style={{  display:'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection:'row',
          gap:"10px",
          padding:"14px 8px ",
          
        
        
        }}
          onClick={(event) =>{
            handleListItemClick(event, 0)
            navigate('/courses')
          }}
          sx={{ backgroundColor: selectedIndex === 0 ? 'white' : 'transparent',
          px: 2.5,
          fontFamily:POPPINS,
          color: selectedIndex === 0 ? "#5786FF" : "#000000",
          borderRight: selectedIndex === 0 ? 3 : 0,
          borderColor:selectedIndex === 0  ? "#57FF68" : "transparent",
          '&:hover': {
            backgroundColor: 'white',
            color: '#5786FF',
            '& .MuiSvgIcon-root': {
              color: '#5786FF', // Change color of the ArrowBackIosIcon on hover
            },
          },
        
        
        
        }}
        >
          <ListItemIcon sx={{minWidth:'10px',}} >
            <ArrowBackIosIcon style={{fontSize:"1rem"}}  />
          </ListItemIcon>
          <Typography style={{fontWeight:"400",fontSize:13,fontFamily:POPPINS,letterSpacing:"0.00938em"}} >Back to course</Typography>
        </ListItemButton>
        <ListItemButton
           style={{  display:'flex',
           justifyContent: 'center',
           alignItems: 'center',
           flexDirection:'row',
           gap:"15px",
           padding:"14px 8px "
         
         
         
         }}
          onClick={(event) =>{
            handleListItemClick(event, 1)
            navigate(`/createcourse/${id}`)
          }}
          sx={{ backgroundColor: selectedIndex === 1 ? 'white' : 'transparent',
          color: selectedIndex === 1 ? "#5786FF" : "#000000",
          borderRight: selectedIndex === 1 ? 3 : 0,
          borderColor:
          selectedIndex === 1  ? "#57FF68" : "transparent",
          '&:hover': {
            backgroundColor: 'white',
            color:'#5786FF',
            "& svg path": {
              fill: "#5786FF",
            },
           
          },
          display:'flex',
          justifyContent: 'center',
          alignItems: 'center',

        
        }}
        >
           <ListItemIcon sx={{minWidth:'10px',}} >
            
               <FormatIndentDecreaseIcon style={{color:selectedIndex === 1?'rgb(87, 134, 255)':'grey',fontSize:"1rem"}} />
          </ListItemIcon>
        
            <Typography style={{fontWeight:"400",fontSize:13,fontFamily:POPPINS,lineHeight:"1.5",letterSpacing:"0.00938em"}} >Course builder</Typography>
        </ListItemButton>
      </List>
      
    </Box>
  );
}

export default SidebarList