// import { TextField } from '@mui/material'
// import React from 'react'
// import { POPPINS } from '../../../constants/fontConstantsPoppins'

// function SearchBar({searchValue,handleChangeSearchValue,label}) {
//   return (
//     <TextField
//     placeholder={label?label:"Search"}
//       value={searchValue?searchValue:''}
//       onChange={handleChangeSearchValue?handleChangeSearchValue:()=>{}}
//       InputLabelProps={{
//         style: { fontFamily: POPPINS, fontSize: 12 },
//       }}
//       InputProps={{
//         style: {
//           fontSize: 12,
//           fontFamily: POPPINS,
//           height: 55,
//           borderColor: "red",
//         },
//       }}
//       sx={{
//         backgroundColor: "#fff",
//         width: "100%",
//         borderRadius:2,
//         height:"55px",
//         "& .MuiOutlinedInput-notchedOutline": {
//           borderColor: "1px solid #757575",

//           borderRadius:2,

//         },
//         "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
//           {
//             borderColor: "1px solid #757575",
//             borderRadius:2,

//           },
//         "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
//           {
//             borderColor: "1px solid #757575",
//             borderRadius:2,

//           },
//       }}
    
    
//     />
//   )
// }

// export default SearchBar

import React, { useEffect, useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import { POPPINS } from '../../../constants/fontConstantsPoppins';

function SearchInput({ onSearch, label,reset }) {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useMemo(() => debounce(onSearch, 1000), [onSearch]);

  const handleInputChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  useEffect(()=>{
if(reset===""){
  setSearchTerm("")
}
  },[reset])

  return (
    <TextField
      type="text"
      placeholder={label ? label : "Search"}
      value={searchTerm}
      onChange={handleInputChange}
      InputLabelProps={{
        style: { fontFamily: POPPINS, fontSize: 12 },
      }}
      InputProps={{
        style: {
          fontSize: 12,
          fontFamily: POPPINS,
          height: 55,
        },
      }}
      sx={{
        backgroundColor: "#fff",
        width: "100%",
        borderRadius: 2,
        height: "55px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "1px solid #757575",
          borderRadius: 2,
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "1px solid #757575",
          borderRadius: 2,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "1px solid #757575",
          borderRadius: 2,
        },
      }}
      fullWidth
      margin="normal"
    />
  );
}

export default SearchInput;
