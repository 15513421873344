import React, { Suspense, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { StyledGrid } from "../useStyle";
import UseCertificateHooks from "../hooks";
import { getCookie } from "../../../helpers/CookieHelpers";
import SkeletonLoader from "../../../components/atoms/skeleton/skeletoncertificate";
import Empty from "./Empty";
const CardDetails = React.lazy(() => import("./CardDetails"));

function Cards() {
  const [certificatelist, setCertificatelist] = useState([]);
  const [certificateloader, setCertificateloader] = useState(false);

  const { getcertificate } = UseCertificateHooks();

  const userid = getCookie("userinformation");

  const certificatefn = () => {
    setCertificateloader(true);
    const data = { user_id: userid };
    getcertificate(data).then((res) => {
      if (res?.data?.status === 200) {
        setCertificateloader(false);
        setCertificatelist(res?.data?.data?.certificateData);
      }
    });
  };

  useEffect(() => {
    certificatefn();
  }, []);

  if (certificateloader) {
    return (
      <Grid container mt={4}>
        {" "}
        <SkeletonLoader count={12} />
      </Grid>
    );
  } else {
    return (
      <StyledGrid>
        <Grid container mt={5} ml={3} display={"flex"} gap={"20px"}>
          {certificatelist.length <= 0 ? (
            <Empty />
          ) : (
            certificatelist?.map((itm) => (
              <Grid
                width={"300px"}
                height={"380px"}
                spacing={2}
                item
                key={itm?.Id}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <CardDetails certificatelists={itm} />
                </Suspense>
              </Grid>
            ))
          )}
        </Grid>
      </StyledGrid>
    );
  }
}

export default Cards;
