import axiosInstance from '../../../services/axiosservice';
 
const baseURL = process.env.REACT_APP_API_BASE_URL
 
 
const getCertificate = async (data)=>{
        try {
            let getCertificate = await axiosInstance.post(`${baseURL}/api/getCertificateData`,data)
             
            return getCertificate
 
        } catch (err) {
            console.log(err)
        }
}


const certificategenerte = async (id)=>{
    try {
        let certificategenerte = await axiosInstance.get(`${baseURL}/api/generateCeritficateForStudent/${id}`,{})
         
        return certificategenerte

    } catch (err) {
        console.log(err)
    }
}

 
const CertificateDetailService ={getCertificate,certificategenerte}
 
export default CertificateDetailService