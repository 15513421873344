import Dashboard from "../pages/dashboardContainer";
import Certificate from "../pages/certificate";
// import Inbox from "../pages/inbox";
// import Settings from "../pages/settings";
import Users from "../pages/users";
import Report from "../pages/report";
import { lazy } from "react";
import Profile from "../features/profile";
import Assetlibrary from "../features/asset"
import Questionbank from "../pages/questionbankroot"
import { getCookie } from "../helpers/CookieHelpers";
import { UserLevel } from "../enum/userLevelenum";


const Layout = lazy(() => import("../pages/Dashboard"));
const Courses = lazy(() => import("../pages/courses"));



const userRoutes = [
  {
    id: "dashboard",
    path: "/dashboard/*",
    component: <Layout />,
  },
  {
    id: "dashboards",
    path: "/dashboards/*",
    component: <Dashboard />,
  },
  {
    id: "certificate",
    path: "/certificate/*",
    component: <Certificate />,
  },
  {
    id: "courses",
    path: "/courses/*",
    component: <Courses />,
  },
  // {
  //   id: "inbox",
  //   path: "/inbox/*",
  //   component: <Inbox />,
  // },
  // {
  //   id: "settings",
  //   path: "/settings/*",
  //   component: <Settings />,
  // },
  // {
  //   id: "users",
  //   path: "/users/*",
  //   component: <Users />,
  // },
  // {
  //   id: "report",
  //   path: "/report/*",
  //   component: <Report />,
  // },
  {
    id: "profile",
    path: "/profile",
    component: <Profile />,
  },
  // {
  //   id: "assetlibrary",
  //   path: "/assetlibrary",
  //   component: <Assetlibrary />,
  // },
  // {
  //   id: "questionbank",
  //   path: "/questionbank",
  //   component: <Questionbank />,
  // },
];

const AdminRoutes = [
  {
    id: "dashboard",
    path: "/dashboard/*",
    component: <Layout />,
  },
  // {
  //   id: "dashboards",
  //   path: "/dashboards/*",
  //   component: <Dashboard />,
  // },
  // {
  //   id: "certificate",
  //   path: "/certificate/*",
  //   component: <Certificate />,
  // },
  {
    id: "courses",
    path: "/courses/*",
    component: <Courses />,
  },
  // {
  //   id: "inbox",
  //   path: "/inbox/*",
  //   component: <Inbox />,
  // },
  // {
  //   id: "settings",
  //   path: "/settings/*",
  //   component: <Settings />,
  // },
  {
    id: "users",
    path: "/users/*",
    component: <Users />,
  },
  {
    id: "report",
    path: "/report/*",
    component: <Report />,
  },
  {
    id: "profile",
    path: "/profile",
    component: <Profile />,
  },
  {
    id: "assetlibrary",
    path: "/assetlibrary",
    component: <Assetlibrary />,
  },
  {
    id: "questionbank",
    path: "/questionbank",
    component: <Questionbank />,
  },
];

export const getRoutes = () => {
  const userId = getCookie("user");
const user = UserLevel.find((item) => item.id === parseInt(userId, 10));
  return user && user.user === "Admin" ? AdminRoutes : userRoutes;
};