import { combineReducers } from "@reduxjs/toolkit";
import signinReducer from '../features/signin/reduxSlice/signSlice'
import createCourseReducer from "../features/createcourse/createcourseslice/createCourseslice"
import createCourseDetailSlice from "../features/coursedetails/createCourseDetailSlice/createCourseDetailSlice"
import userDetailSlice from "../features/dashboards/userDetailSlice/userDetailSlice";
import userSlice from "../features/users/userslice/userSlice";
import assetslice from "../features/asset/assetslice/index";

const reducer = combineReducers({
    signinReducer,
    createCourseReducer,
    createCourseDetailSlice, 
    userDetailSlice,
    userSlice,
    assetslice,
    
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export default rootReducer;
