import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import InputField from './InputField'
import SaveButton from './SaveButton'
import { passwordSchema } from '../schemas'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import updateUserDetailsApi from '../api'
import { enqueueSnackbar } from 'notistack'
import ErrorSnackbar from '../../../components/molecules/customsnackbar/errorSnackbar';
import SuccessSnackbar from '../../../components/molecules/customsnackbar/successSnackbar'
function PasswordUpdate() {
const [passwordDetail,setPasswordDetail] = useState({currentPassword:"",newPassword:"",retypeNewPassword:""})
const [passwordMatchError, setPasswordMatchError] = useState("");
const [currentPasswordError, setCurrentPasswordError] = useState("")
const [isLoading,setIsLoading] = useState(false)
const {
  handleSubmit,
  register,
  formState: { errors },
} = useForm({
  resolver: yupResolver(passwordSchema()),
});
const handleChange =(e)=>{
  const {name,value} = e.target
  setPasswordDetail((prev)=>{
    return{...prev,[name]:value}  
  })
  if(passwordMatchError && (name === 'retypeNewPassword' || name === 'newPassword') ){
    setPasswordMatchError('')
  }

}
const handleOnSubmit =async (data)=>{

    if(data?.newPassword === data?.retypeNewPassword){
        setIsLoading(true)
        const sendingDataFormate = {
          current_password: data?.currentPassword,
          new_password: data?.retypeNewPassword
        }
      const res = await updateUserDetailsApi.updatePassword(sendingDataFormate)
      if(res?.status === 200){
        setCurrentPasswordError("")
        setPasswordDetail({currentPassword:"",newPassword:"",retypeNewPassword:""})
        setIsLoading(false)
        enqueueSnackbar(res?.message, {
          variant: 'error', 
          content: (key, message) => (
            <SuccessSnackbar id={key} message={message} allowDownload={true} />
          ),
        });
      }  else{
        setIsLoading(false)
        enqueueSnackbar(res?.message, {
          variant: 'error', 
          content: (key, message) => (
            <ErrorSnackbar id={key} message={message} allowDownload={true} />
          ),
        }); 
      }
    }else{
    
      setPasswordMatchError("Passwords do not match");
    }
}


  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Box padding={"40px"} sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:"20px"}}>
            { /* InputFiled component used for recycle the input field */}
          <Box>
              <InputField 
               register={register('currentPassword')}
              name="currentPassword"
              title="Current Password"
              placeHolder="Current Password"
              value={passwordDetail.currentPassword}
              handleChange={handleChange}
              />
              <Typography color={"red"} fontSize={"10px"}>
              {errors?.currentPassword?.message}
            </Typography>
             <Typography color={"red"} fontSize={"10px"}>
              {currentPasswordError}
            </Typography>
          </Box>
          <Box>
              <InputField
               register={register('newPassword')}
              name="newPassword" 
              title="New Password" 
              placeHolder="New Password" 
              value={passwordDetail.newPassword}
              handleChange={handleChange}
              />
              <Typography color={"red"} fontSize={"10px"}>
              {errors?.newPassword?.message}
            </Typography>
          </Box>
          <Box>
              <InputField
                register={register('retypeNewPassword')}
                name="retypeNewPassword"
                title="Retype new password" 
                placeHolder="Retype new password" 
                value={passwordDetail.retypeNewPassword}
                handleChange={handleChange}
                />
               {errors?.retypeNewPassword?.message&&(  <Typography color={"red"} fontSize={"10px"}>
                    {errors?.retypeNewPassword?.message}
                 </Typography>)}
                 {/* This error set my manually not from react-hook-form */}
                {passwordMatchError&&(<Typography color={"red"} fontSize={"10px"}>
                    {passwordMatchError}
                </Typography>)}
           
          </Box>
      </Box>
      {/* SaveButton component used for recycle */}
      
      <SaveButton imgUrl={"sjdjs"} type='submit' isLoading={isLoading}/>
    </form>
  )
}

export default PasswordUpdate