import React from 'react';
import { TableBody, TableRow, TableCell, Skeleton } from '@mui/material';

function SkeletonRows({ count }) {
    const skeletonRows = [];

    for (let i = 0; i < count; i++) {
        skeletonRows.push(
            <TableRow key={i}>
                <TableCell height={80}>
                    <Skeleton width={220} variant="text" animation="wave" />
                </TableCell>
                <TableCell>
                    <Skeleton width={220} variant="text" animation="wave" />
                </TableCell>
                <TableCell>
                    <Skeleton width={220} variant="text" animation="wave" />
                </TableCell>
                <TableCell>
                    <Skeleton width={220} variant="text" animation="wave" />
                </TableCell>
                <TableCell>
                    <Skeleton width={220} variant="text" animation="wave" />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <TableBody style={{ height: '500px' }} >
            {skeletonRows}
        </TableBody>
    );
}

export default SkeletonRows;
