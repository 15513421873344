import { styled } from "@mui/material";
import { Grid } from '@mui/material'
import { POPPINS } from '../../constants/fontConstantsPoppins';


export const StyledGrid = styled(Grid)({

    padding:"0px 30px 30px 30px",
    overflow:"hidden",
    width:"100%",
  });

  export const StyleTypography={
    color:" #000",
    fontFamily: POPPINS,
    fontSize:" 30px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "107.5%",
    letterSpacing: "-0.8px",
  }
  export const StyleTypographySubtitle={
    fontFamily: POPPINS,
    fontSize:" 15px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "107.5%",
    letterSpacing: "-0.4px",
  }
  