import React from 'react'
import empty from '../../../assets/images/Empty.jpg'
import { Box } from '@mui/material'
function Empty() {
  return (
    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center", width:"100%",height:"50vh"}}>
      
        <Box width={"150px"} height={"150px"}>
             <img style={{borderRadius:"50%"}} width={"100%"} height={"100%"} src={empty} alt='EMPTY'/>
        </Box>
  
    </Box>
  )
}

export default Empty