import { Grid, Typography } from "@mui/material";
import React from "react";
import { POPPINS } from "../../../constants/fontConstantsPoppins";

const Appbar = ({title}) => {
    return (
        <Grid container alignItems="center" justifyContent="center" sx={{ height: '80px', width:"100%",
        borderBottom: '1px solid #ccc' }}>
            <Grid item xs={12}>
                <Typography variant="h6" fontFamily={POPPINS} component="h6" paddingLeft="30px">
                    {title}
                </Typography>
            </Grid>
        </Grid>
    );
};
export default Appbar