import { useDispatch,useSelector } from "react-redux";
import AssetService from "../api"
import { getChaptercontenttype, getTabledatas, getenrollstatusandcourse } from "../assetslice";

const AssetViewHooks = ()=>{
  const dispatch = useDispatch();


const getcourseandstatus=async()=>{
    try {
      return dispatch(getenrollstatusandcourse()).then((res) => {return res});
     } catch (err) {
       console.error("Error dispatching getstatusandcourse:", err);
     }
  }

  const getchaptercontenttype=async()=>{
    try {
      return dispatch(getChaptercontenttype()).then((res) => {return res});
     } catch (err) {
       console.error("Error dispatching getstatusandcourse:", err);
     }
  }

  const getTabledata=async(data)=>{
    try {
      return dispatch(getTabledatas(data)).then((res) => {return res});
     } catch (err) {
       console.error("Error dispatching getstatusandcourse:", err);
     }
  }



  
const {courseloading,tableloading,courselist,contenttype,tabledata}=useSelector((state)=>state.assetslice)

// const certificategenerate=(id)=>{
//     return CertificateDetailService.certificategenerte(id)
// }
   
return{
    getcourseandstatus,
    getchaptercontenttype,
    getTabledata,
    courseloading,
    courselist,
    contenttype,
    tabledata,
    tableloading,
    // certificategenerate
}
}

export default AssetViewHooks