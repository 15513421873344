import { useDispatch, useSelector } from "react-redux";
import { getuserdata,postuserregister,getenrollstatusandcourse } from "../userslice/userSlice";
import usersServices from "../api";
import { setuserdataall } from "../userslice/userSlice";

const UserServices = () => {
  const dispatch = useDispatch();


  const getuserdatas = async (data) => {
    try {
      return dispatch(getuserdata(data)).then((res) => {return res});
    } catch (err) {
      console.error("Error dispatching fetchcourseimg:", err);
    }
  };

  

  const postuserregisters= async(data)=>{
    try {
     return dispatch(postuserregister(data)).then((res) => {return res});
    } catch (err) {
      console.error("Error dispatching fetchcourseimg:", err);
    }
  }

  const getstatusandcourse=async()=>{
    try {
      return dispatch(getenrollstatusandcourse()).then((res) => {return res});
     } catch (err) {
       console.error("Error dispatching getstatusandcourse:", err);
     }
  }

  const deleteuserTable=(data)=>{
   return usersServices.deleteuserTable(data)
  }

  const {isLoading,userData,courseenrollall,statuslist,courselist,userlistLoading} = useSelector(
    (state) => state.userSlice    
  );

  const setuserdataallval=async(val)=>{
    dispatch(setuserdataall(val))
  }

  return {
    getuserdatas,
    postuserregisters,
    isLoading,
    userData,
    deleteuserTable,
    courseenrollall,
    setuserdataallval,
    getstatusandcourse,
    statuslist,
    courselist,
    userlistLoading
  };
};

export default UserServices;
