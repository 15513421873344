import React from 'react'
import DashboardContainer from "../features/dashboardcotainer/index"
function dashboardContainer() {
  return (
    <div>
    <DashboardContainer/>
    </div>
  )
}

export default dashboardContainer