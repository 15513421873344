import React, { useEffect, useState } from 'react'
import { Box, Typography} from '@mui/material'
import InputField from './InputField';
import SaveButton from './SaveButton';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { schema } from '../schemas';
import ProfileDetailHook from '../hooks'
import updateUserDetailsApi from '../api';
import SuccessSnackbar from '../../../components/molecules/customsnackbar/successSnackbar';
import { enqueueSnackbar } from 'notistack';
import ErrorSnackbar from '../../../components/molecules/customsnackbar/errorSnackbar';





function ProfileDetail() {
const {name,email,updateNameHook} = ProfileDetailHook()

const [details,setDetails] = useState({name:name,email:email})
const [isLoading,setIsLoading] = useState(false)
const {
  handleSubmit,
  register,
  formState: { errors },
  setValue
} = useForm({
  resolver: yupResolver(schema()),
});

useEffect(() => {
  // set default values after calling useForm
  setValue('name', details.name);
  // ... set other default values
}, [details]);

//HandleChange Function to track input fields type
const handleChange =(e)=>{
const {name,value} = e.target
setDetails((prev)=>{
  return{...prev,[name]:value}
})
}

//handleSubmit Function 
const handleOnSubmit = async (data)=>{
  if(data){
    setIsLoading(true)
    const res = await updateUserDetailsApi.updateName({name:data.name,mobile:""})
      if(res?.data?.status === 200){
        enqueueSnackbar('Updated profile Successfully', {
          variant: 'success', 
          content: (key, message) => (
            <SuccessSnackbar id={key} message={message} allowDownload={true} />
            ),
          });
          updateNameHook(data.name)  
          setIsLoading(false)

      }else{
       
        setIsLoading(false)
        enqueueSnackbar('Network Error', {
          variant: 'error', 
          content: (key, message) => (
            <ErrorSnackbar id={key} message={message} allowDownload={true} />
          ),
        });
      }
  }
}

//if the user refresh the page set the correct data with out empty string
useEffect(()=>{
  setDetails((prev)=>{
    return{...prev,name:name,email:email}
  })
},[name])

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Box padding={"40px"} sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:"20px"}}>
            { /* InputFiled component used for recycle the input field */}
         <Box>
            <InputField 
              name="name"
              title="Name"
              placeHolder="Enter name" 
              value={details?.name}
              handleChange={handleChange}
              register={register('name')}
              />
             <Typography color={"red"} fontSize={"10px"}>
              {errors?.name?.message}
            </Typography>
         </Box>
          <Box>
            <InputField
            name="email" 
            title="Email"
              placeHolder="Enter email"
              value={email}
              handleChange={handleChange} 
              disabled={true}
              />
            <Typography color={"red"} fontSize={"10px"}>
              {errors?.email?.message}
            </Typography>
          </Box>
      </Box>
    
      {/* SaveButton component used for recycle */}
      <SaveButton type="submit" isLoading={isLoading} imgUrl={"jdjh"} />
    </form>
  )
}

export default ProfileDetail