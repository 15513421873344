import React, { useState } from 'react'
import {CircularProgress,Grid, IconButton, OutlinedInput, Typography, Button, Box, Modal,} from "@mui/material";
import { POPPINS } from '../../../constants/fontConstantsPoppins';
import CloseCircle from '../../../assets/svgimages/closecircle';


function ModalComponent({profileModal,setProfileModal,title,courseImg,setCourseImg,setImgUrl,setError,setAnim,}) {

    //close modal
    const handleProfileClose = () => {
        setProfileModal(false);
      };

      //setting profile image 
      const courseImgChange=(event)=>{
        setCourseImg(event?.target?.files[0])
        
      }
      
      //handle Profile image when click button saved
      const handleImgUpload=async()=>{
        const formData = new FormData();
              formData.append('file',courseImg);
        const payload={ImageUrl:formData.get("file")}
        if(payload.ImageUrl !== "false" ){
          handleProfileClose()
          const imageUrl = URL.createObjectURL(courseImg);
         
          setError(false)
          setAnim(true)
          setTimeout(() => {
            setImgUrl(imageUrl)
            setCourseImg(courseImg)
            setAnim(false)
          }, 500);
        }else{
          // No image to upload
        }
       
      }

  return (
    <Modal
        open={profileModal}
        onClose={handleProfileClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            width: "55%",
            height: "40%",
            outline: "none",
            borderRadius: 4,
            '@media (max-height: 685px)': {
              height: "50%",
            },
            '@media (max-height: 550px)': {
              height: "60%",
            },
          }}
        >
          <Grid container>
            <Grid
              spacing={2}
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              height={"50px"}
              p={5}
              borderBottom={"1px solid var(--All-stroke, rgba(0, 0, 0, 0.24))"}
            >
              <Typography fontFamily={POPPINS} fontSize={24} fontWeight={400}>
                {title}
              </Typography>
              <IconButton onClick={handleProfileClose}>
              <CloseCircle />
              </IconButton>
            </Grid>
            <Grid xs={12} mt={2} pl={5} item>
              <Typography fontSize={13} fontFamily={POPPINS} fontWeight={600}>
                Choose image
              </Typography>
                
           
              <Box mt={2}>
                <OutlinedInput
                onChange={courseImgChange}
                  type="file"
                  accept="image/*"
                  placeholder="Title"
                  sx={{ width: "95%", height: "50px" }}
                />
              </Box>
            </Grid>
            <Grid
              mt={6}
              pr={5}
              container
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Grid gap={1} display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  onClick={handleImgUpload}
                  sx={{
                    backgroundColor: "#03FF84",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#03FF84",
                    },
                  }}
                >{true?
                  <Typography
                  fontFamily={POPPINS}
                  fontSize={13}
                  fontWeight={600}
                  color={"#000"}
                  textTransform={"none"}
                >
                  Upload
                </Typography>:<CircularProgress  size={25}/>
                }
                
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
  )
}

export default ModalComponent