import { Box, Grid, IconButton, InputBase, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Appbar from '../../components/atoms/header/header'
import { POPPINS } from '../../constants/fontConstantsPoppins'
import Mobile from './components/mobile';
import Web from './components/web';
import All from './components/all';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BarChartComponent from './components/barchart';
import { BarChart } from 'recharts';
import ReportApi from './Api';
import { Charts } from './components/charts';
import { useSnackbar } from "notistack";
import ErrorSnackbar from "../../components/molecules/customsnackbar/errorSnackbar"


function Index() {
  const [activeTab, setActiveTab] = useState('All'); 
  const [reportDetail,setReportDetail] = useState({})
console.log(reportDetail["pi-chart-enrol-vs-course"])
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading,setIsLoading] = useState(true)
  const handleTabChange = (tab) => {
      setActiveTab(tab);
  };

useEffect(()=>{
  setIsLoading(true)
  ReportApi.getReportDetailApi().then((res)=>{
    if(res?.data?.status===200){
      setReportDetail(res?.data?.data)
      setIsLoading(false)
    }
  })
},[])


const reportHeader=()=>{
  return (
    <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
    <Grid item display="flex" alignItems="center">
    <Typography
    variant="h6"
    fontFamily={POPPINS}
    component="h6"
    paddingLeft="30px"
    fontSize="12px"
    onClick={() => handleTabChange('All')}
    style={{ cursor: 'pointer',fontWeight:"bold",color:"#000000",opacity:activeTab==="All"?1:0.5 }}
>
All
</Typography>
<Typography
    variant="h6"
    component="h6"
    fontFamily={POPPINS}
    paddingLeft="30px"
    fontSize="12px"
    onClick={() => handleTabChange('Web')}
    style={{ cursor: 'pointer',fontWeight:"bold",color:"#000000",opacity:activeTab==="Web"?1:0.5 }}
>
Web
</Typography>
<Typography
    variant="h6"
    component="h6"
    fontFamily={POPPINS}
    paddingLeft="30px"
    fontSize="12px"
    onClick={() => handleTabChange('Mobile')}
    style={{ cursor: 'pointer',fontWeight:"bold",color:"#000000",opacity:activeTab==="Mobile"?1:0.5 }}
>
   Mobile
</Typography>
    </Grid>
    <Grid item sx={{ display: 'flex',mr:6}}>
        <Box gap={1} display={"flex"}>
        <Box gap={0.2} display={"flex"} width={"150px"} borderRadius={1} height={"30px"} sx={{backgroundColor:"#5786FF",alignItems:"center",justifyContent:"center"}}>
        <FileDownloadOutlinedIcon style={{color:"#fff",fontSize:"22px"}}/>
        <Typography color={"#fff"} fontWeight={600} fontSize={"13px"}>Download report</Typography>
        </Box>
        <Box display={"flex"} width={"110px"} borderRadius={1} height={"30px"} sx={{backgroundColor:"#03FF84",alignItems:"center",justifyContent:"center"}}>
        <AddIcon style={{color:"#000",fontSize:"22px"}}/>
        <Typography color={"#000"} fontWeight={"bold"} fontSize={"13px"}>New user</Typography>
        </Box>
        <Box display={"flex"} width={"140px"} borderRadius={1} height={"30px"} sx={{backgroundColor:"transparent",alignItems:"center",justifyContent:"center"}}>
        <Typography color={"#000"} fontWeight={"bold"} fontSize={"13px"}>Last 9 months</Typography>
        <KeyboardArrowDownIcon style={{color:"#000",fontSize:"22px"}}/>
        </Box>
        </Box>
    </Grid>
</Grid>
  )
}

  const renderCourses = () => {
    if (activeTab === 'All') {
        return <All isLoading={isLoading} users={reportDetail?.users} time={reportDetail?.sessionTime} highestEnrollCourse={reportDetail?.highestEnrollCourse} />;
    } else if (activeTab === 'Web') {
        return <Web/>;
    } else if (activeTab === 'Mobile') {
        return <Mobile/>
    }
};
  return (
    <Grid overflow={"hidden"} container>
    <Appbar title={"Dashboard"}/>
    {/* <Grid container alignItems="center" justifyContent="center" sx={{  height: '40px', borderBottom: '1px solid #ccc' }}>
          {reportHeader()}
    </Grid> */}
<Grid width={"100%"}  p={"32px 0 0 0"}>
{renderCourses()}
<Grid container xs={12}     sx={{display:"flex",justifyContent:"center"}}>
    <Grid item xs={8}  >
      <BarChartComponent  h={230} graphData={reportDetail?.enrolgraphData}/>
    </Grid>
    
    <Grid ml={2.1} item xs={3}    >
      <Charts  title="Course enrollment"  detailData={reportDetail["pi-chart-enrol-vs-course"]}/>
    </Grid>
    <Grid item xs={0.8}/>
</Grid>
{/*  */}
{/* <BarChartComponent h={180} graphData={reportDetail?.enrolgraphData}/> */}
    <Grid mt={3} width={"100%"} padding={"0px 65px 0px 35px"} container justifyContent={"space-between"}>
      <Box><Typography fontSize={13} fontFamily={POPPINS} fontWeight={600}>Monthly completed users</Typography></Box>
      {/* <Box><Typography fontSize={13} fontFamily={POPPINS}>Updated 23 minutes ago</Typography></Box> */}
      </Grid>
</Grid>
<Grid container xs={12}     sx={{display:"flex",justifyContent:"center"}}>
    <Grid item xs={8}  >
      <BarChartComponent  h={230} graphData={reportDetail?.enrolCompleteGraphData}/>
    </Grid>
    
    <Grid ml={2.1} item xs={3}    >
      <Charts detailData={[
      // {CourseTitle:"Enrolled",EnrollCount:reportDetail?.usersActive ?reportDetail.usersActive : 0},
      {CourseTitle:"In-progress",EnrollCount:reportDetail?.usersActive ?reportDetail.usersActive : 0},
      {CourseTitle:"Completed",EnrollCount:reportDetail?.userCompleteStatus ?reportDetail.userCompleteStatus : 0},
    ]}
    title="Course progress"
    />
    </Grid>
    <Grid item xs={0.8}/>
</Grid>
    </Grid>
  )
}

export default Index