import axiosInstance from '../../../services/axiosservice';

const baseURL = process.env.REACT_APP_API_BASE_URL

const usergetbyRole = async (data) => {
  try {
    let usergetbyRole = await axiosInstance.post(`${baseURL}/api/getUserByRoleModifiedByFilter`,data);
    if (usergetbyRole) {
      return {
        data: usergetbyRole.data
      };
    } else {
      return {data:500};
    }
  } catch (err) {
    console.log(err,"errr");
    if(err?.response){
    if (err?.response?.status === 500) {
      return { data: 500 ,message:"server error 500"};
    } else {
      console.error('HTTP error:', err.response.status);
      return false;
    }
    }else if(err.request){
    return { data: 600 ,message:"network error"};
    }
  }    
};

const postuserregisteration = async (data) => {
  try {
    let postuserregisteration = await axiosInstance.post(`${baseURL}/api/register`,data);
    if (postuserregisteration) {
      return {
        data: postuserregisteration.data
      };
    } else {
      return {data:500};
    }
  } catch (err) {
    console.log(err,"errr");
    if(err?.response){
    if (err?.response?.status === 500) {
      return { data: 500 ,message:"server error 500"};
    } else {
      console.error('HTTP error:', err.response.status);
      return false;
    }
    }else if(err.request){
    return { data: 600 ,message:"network error"};
    }
  }    
};

const deleteuserTable = async (data) => {
  try {
    let deleteuserTable = await axiosInstance.post(`${baseURL}/api/deleteUserById`,data);
    if (deleteuserTable) {
      return {
        data: deleteuserTable.data
      };
    } else {
      return {data: 500};
    }
  } catch (err) {
    console.log(err,"errr");
    if(err?.response){
    if (err?.response?.status === 500) {
      return { data: 500 ,message:"server error 500"};
    } else {
      console.error('HTTP error:', err.response.status);
      return false;
    }
    }else if(err.request){
    return { data: 600 ,message:"network error"};
    }
  }    
};

const getAllEnrollStatusAndCourse = async (id) => {
  try {
    let getAllEnrollStatusAndCourse = await axiosInstance.get(`${baseURL}/api/getAllEnrollStatusAndCourse`,{});
    if (getAllEnrollStatusAndCourse) {
      return {
        data: getAllEnrollStatusAndCourse.data
      };
    } else {
      return {data:500};
    }
  } catch (err) {
    console.log(err,"errr");
    if(err?.response){
    if (err?.response?.status === 500) {
      return { data: 500 ,message:"server error 500"};
    } else {
      console.error('HTTP error:', err.response.status);
      return false;
    }
    }else if(err.request){
    return { data: 600 ,message:"network error"};
    }
  }    
};

const usersServices = {
    usergetbyRole, 
    postuserregisteration,
    deleteuserTable,
    getAllEnrollStatusAndCourse
};

export default usersServices; 
