import { Grid, Typography } from '@mui/material'
import React from 'react'
import { POPPINS } from '../../../constants/fontConstantsPoppins'

function Appbar() {
  return (
    <Grid container alignItems="center" sx={{ height: '82px', 
    width:"100%",
    borderBottom: '1px solid #ccc' }}>
        <Grid item xs={12}>
            <Typography variant="h6" fontFamily={POPPINS} component="h6" ml={3} paddingLeft="30px">
                Details
            </Typography>
        </Grid>
    </Grid>
  )
}

export default Appbar