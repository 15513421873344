import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'

import AssetService from '../api';

const initialState = {
 courselist:[],
 courseloading:false,
 tableloading:false,
 contenttype:[],
 tabledata:[]
}


  export const getenrollstatusandcourse = createAsyncThunk("asset/getenrollstatusandcourse", async() => {
    try {
      const response =await AssetService.getAllEnrollStatusAndCourse()
      const data =  response
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  });

  export const getChaptercontenttype = createAsyncThunk("asset/getChaptercontenttype", async() => {
    try {
      const response =await AssetService.getChaptercontenttype()
      const data =  response
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  });

  export const getTabledatas = createAsyncThunk("asset/getTabledatas", async(datas) => {
    console.log("levvel4");

    try {
      const response =await AssetService.getTableDetails(datas)
      const data =  response
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(getenrollstatusandcourse.pending,(state)=>{
        state.courseloading=true
      })
      .addCase(getenrollstatusandcourse.fulfilled, (state, action) => {
        state.courseloading = false;
        state.courselist=action.payload.data.data.courseList
        // state.statuslist=action.payload.data.data.enrollStatus
      })
      .addCase(getChaptercontenttype.pending, (state, action) => {
        state.courseloading = true;
        // state.statuslist=action.payload.data.data.enrollStatus
      })
      .addCase(getChaptercontenttype.fulfilled, (state, action) => {
        state.courseloading = false;
        state.contenttype=action.payload.data.data.chapterContentType
      })
      .addCase(getTabledatas.pending,(state,action)=>{
         state.tableloading = true
      })
      .addCase(getTabledatas.fulfilled,(state,action)=>{
        state.tableloading = false
        state.tabledata=action?.payload?.data?.data?.searchData
        
      })
  },
})

export const { } = assetSlice.actions

export default assetSlice.reducer